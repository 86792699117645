import styled from "styled-components";

const ColumnHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-bottom: 1px solid #e3eced;
  padding: 10px;
  gap: 10px;

  .make-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 30px;
    align-items: center;

    .ant-switch {
      min-width: 26px !important;
      /* height: 22px; */
      /* height: 16px;   */
    }
    .ant-switch.ant-switch-checked {
      background-color: #4cbbcb;
    }

    .btn-title {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      .ant-space-item {
        color: var(--Gray-60, rgba(85, 99, 115, 0.6));
        text-align: right;
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */

        span {
          svg {
            /* width: 16px;
          height: 16px; */
          }
        }
      }
    }
    .make-row1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      .circle {
        height: 5px;
        width: 5px;
        border-radius: 5px;
        background: red;
      }
      .status {
        color: #2b3746;
        text-align: right;
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

  .search {
    display: flex;
    gap: 14px;
    min-width: ${(props) => (props.isSearchVisible ? `100%` : "")};
    .btns-div {
      display: flex;
      gap: 8px;
    }
    .search-input {
      display: flex;
      width: 100%;
      padding: 8px 10px;
      align-items: center;
      gap: 8px;
      border-radius: 20px;
      background: var(--Soft-Blue-100, #eff5f6);
      height: 32px;
      .input {
        background: var(--Soft-Blue-100, #eff5f6);
        overflow: hidden;
        border: none;
        outline: none;
        width: auto;
        max-width: 130px;
      }
      .input::placeholder {
        color: var(--Gray-60, rgba(85, 99, 115, 0.6));
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.42px;
      }
      .icon {
        width: 16px;
        height: 16px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    .search-icon {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      cursor: pointer;
      /* width: 24px;
      height: 24px; */
      .fa-search {
        display: flex;
        padding: 6px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        box-shadow: 0px 2px 8px 0px rgba(208, 222, 225, 0.6);
      }
      .search-title {
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }
  }
`;

export { ColumnHeaderContainer };
