import React, { useState } from "react";
import { Tabs } from "antd";
import { MainWrapper } from "./styles";

function GeneralSettingsTabs({ activeTab, setActiveTab, setSettingType }) {
  const handleChange = (key) => {
    setActiveTab(key);
    if (key == 1) {
      setSettingType("Chargify");
    }
    if (key == 2) {
      setSettingType("Hubspot");
    }
    if (key == 3) {
      setSettingType("Easypost");
    }
    if (key == 4) {
      setSettingType("Stripe");
    }
  };
  const { TabPane } = Tabs;
  return (
    <MainWrapper>
      <Tabs
        activeKey={activeTab}
        onChange={handleChange}
        tabBarStyle={{ borderBottom: "none" }}
        animated={false}
      >
        <TabPane
          tab={<span className="custom-tab-label">Chargify</span>}
          key="1"
        ></TabPane>
        <TabPane
          tab={<span className="custom-tab-label">Hubspot</span>}
          key="2"
        ></TabPane>
        <TabPane
          tab={<span className="custom-tab-label">Easypost</span>}
          key="3"
        ></TabPane>
        <TabPane
          tab={<span className="custom-tab-label">Stripe</span>}
          key="4"
        ></TabPane>
      </Tabs>
    </MainWrapper>
  );
}

export default GeneralSettingsTabs;
