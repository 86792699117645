import React, { useState } from 'react'
import { Collapse, Skeleton } from 'antd'
import {
  MailboxCards,
  Container,
  ContentContainer,
  Buttons,
  CompanyCards,
  TitleConatiner,
} from './styles'
import { useNavigate } from 'react-router-dom'
import useGetData from '../../../../../hooks/useGetData'
import FormatDate from '../../../../../common/FormatDate'
import DataNotFound from '../../../../../common/DataNotFound'
import GetLogo from '../../../../getlogo/getlogo'
import { useDispatch } from 'react-redux'
import { handSelectCompanyFlowTab } from '../../../../../redux/app/appSlice'
import { Progress } from 'antd'
import ProductCard from './productCard/ProductCard'
import './styles'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import LiveAnsweringCard from './liveAnsweringCard/LiveAnsweringCard'
import PhoneNumberCard from './PhoneNumberCard/PhoneNumberCard'
import CompanyRegistration from './companyRegistration/CompanyRegistration'
import MeetingRoomAccess from './meetingRoomAccess/MeetingRoomAccess'
import StatusLabelButton from '../../../../statusLabelButton/StatusLabelButton'
const { Panel } = Collapse

const PlansAndProducts = ({ userID, companyData }) => {
  const [collopasedKey, setCollopasedKey] = useState(false)
  const [panel1Collapsed, setPanel1Collapsed] = useState(false)

  const handlePanel1ButtonClick = () => {
    setPanel1Collapsed(!panel1Collapsed)
  }

  const {
    data: productsData,
    loading: productsDataLoading,
    error: productsDataDataErro,
  } = useGetData(`company-overview/plans_products?company_id=${userID}`)

  console.log('productsData :', productsData)

  https: return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="Plans & Products" key="1" className="account-panel">
          <ContentContainer>
            {productsDataLoading ? (
              <Skeleton active></Skeleton>
            ) : productsData?.data?.subscription ? (
              <DataNotFound label={'No Product Found'} />
            ) : (
              <>
                {productsData?.data?.subscriptions?.map((subscription) => (
                  <>
                    <TitleConatiner>
                      <div
                        className="title-wrapper"
                        onClick={handlePanel1ButtonClick}
                      >
                        <div className="make-row">
                          <div className="title">{subscription?.plan}</div>
                          <span className="forward">
                            {panel1Collapsed ? (
                              <MdKeyboardArrowUp />
                            ) : (
                              <MdKeyboardArrowDown />
                            )}
                          </span>
                        </div>
                        <div className="make-row1">
                          <StatusLabelButton
                            Data={companyData?.data}
                          ></StatusLabelButton>
                          <span className="used">
                            ${subscription?.amount} /{' '}
                            {subscription?.recurring_type}
                          </span>
                        </div>
                      </div>
                    </TitleConatiner>
                    {subscription?.products?.Virtual_Mailbox &&
                      !panel1Collapsed && (
                        <ProductCard
                          productsData={subscription?.products?.Virtual_Mailbox}
                          companyData={companyData}
                          // setHandleMailActionId={setHandleMailActionId}
                          // setMailBoxProfileNumber={setMailBoxProfileNumber}
                        ></ProductCard>
                      )}

                    {subscription?.products?.Live_Answering &&
                      !panel1Collapsed && (
                        <LiveAnsweringCard
                          productsData={subscription?.products?.Live_Answering}
                          companyData={companyData}
                        ></LiveAnsweringCard>
                      )}
                    {subscription?.products?.Phone_Number &&
                      !panel1Collapsed && (
                        <PhoneNumberCard
                          productsData={subscription?.products?.Phone_Number}
                          companyData={companyData}
                        ></PhoneNumberCard>
                      )}

                    {subscription?.products?.Company_Registration &&
                      !panel1Collapsed && (
                        <CompanyRegistration
                          productsData={
                            subscription?.products?.Company_Registration
                          }
                          companyData={companyData}
                        ></CompanyRegistration>
                      )}

                    {subscription?.products?.Meeting_Room_Access &&
                      !panel1Collapsed && (
                        <MeetingRoomAccess
                          productsData={
                            subscription?.products?.Meeting_Room_Access
                          }
                          companyData={companyData}
                        ></MeetingRoomAccess>
                      )}
                  </>
                ))}
              </>
            )}
          </ContentContainer>
        </Panel>
      </Collapse>
    </Container>
  )
}

export default PlansAndProducts
