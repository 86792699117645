import styled from "styled-components";

const AssociateContactContainer = styled.div`
  ${"" /* padding: 16px; */}
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  :where(.css-dev-only-do-not-override-1gwfwyx).ant-input-outlined:focus,
  :where(
      .css-dev-only-do-not-override-1gwfwyx
    ).ant-input-outlined:focus-within {
    border-color: rgb(76, 187, 203);
    box-shadow: none;
    outline: 0;
    background-color: #ffffff;
  }
  :where(.css-dev-only-do-not-override-1gwfwyx).ant-input-outlined:hover,
  :where(
      .css-dev-only-do-not-override-1gwfwyx
    ).ant-input-outlined:focus-within {
    border-color: rgb(76, 187, 203);
    box-shadow: none;
    outline: 0;
    background-color: #ffffff;
  }
  input {
    display: block;
    padding: 8px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 5px;

    .icon {
      width: 16px;
      height: 16px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .contact-list {
    height: 100%;
    margin: 15px 0;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    box-sizing: border-box;

    ${
      "" /* &::-webkit-scrollbar {
      display: none;
    } */
    }

    .filters {
      margin-top: 25px;
      .total {
        color: #2b3746;
        text-align: right;
        font-family: Outfit;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      margin: 20px;
      width: 100%;

      .list-item {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 7px;
        margin-bottom: 10px;
        font-family: Outfit;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: var(--Gray-100, #556373);
        background: geen;
        .radio-button {
          margin: 0;
          padding: 0;
          cursor: pointer;
        }

        .label {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .form-container {
    display: flex;
    width: 100%;
    padding-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;

    .form-main {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      .form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        width: 100%;
        gap: 16px;

        .form-items {
          margin-bottom: 0;
          height: 64px;
          width: 100%;

          .input {
            display: flex;
            padding: 10px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            width: 472px;
            height: 41px;
            border-radius: 4px;
            box-shadow: none;
            border-color: unset;
            border: 1px solid #d0dee1 !important;
            background: #fff;
          }

          .input::placeholder {
            color: var(--Gray-70, rgba(85, 99, 115, 0.7));
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
          .input:focus::placeholder {
            color: transparent;
          }
          .input:focus {
            box-shadow: none;
          }
          .ant-input-status-error:not(.ant-input-disabled):not(
              .ant-input-borderless
            ).ant-input {
            border-color: unset;
            border: 1px solid #d0dee1;
            box-shadow: none;
          }
          .ant-form-item-explain-error {
            font-size: 10px;
            font-weight: 400;
            font-family: Outfit;
          }
          .ant-form-item .ant-form-item-label > label::after {
            content: "";
          }
          .ant-form-item-row {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            width: 100%;
            align-self: stretch;

            label {
              height: unset;
              color: var(--Gray-70, rgba(85, 99, 115, 0.7));
              font-family: Outfit;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 14.4px */
              align-self: stretch;
              padding-left: 5px;
              margin-bottom: 0;
              margin-top: 2px;
            }
          }
        }
        .location {
          align-items: center;
          min-width: 472px;
          height: 41px;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          height: auto;
          min-height: 42px;
          padding-top: 4px;
          padding-right: 15px !important;
          margin-left: 3px;

          .ant-select-selector {
            border: none;
            outline: none;
            box-shadow: none;
            .ant-select-selection.ant-select-selection--single {
              background: green;
            }
          }
        }
        .location::placeholder {
          color: var(--Gray-40, rgba(85, 99, 115, 0.4));
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }
        .location:focus::placeholder {
          color: transparent;
        }
        .ant-select-selection-item {
          ${
            "" /* color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; */
          }
        }
      }
    }
  }
`;

const PaginationContainer = styled.div`
  .ant-pagination-prev {
    min-width: 16px !important;
    width: 16px !important;
  }
  .ant-pagination-next {
    min-width: 16px !important;
    width: 16px !important;
  }
  .ant-pagination-item {
    min-width: 24px !important;
    height: 16px !important;
    width: 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 3px;
  }
  .pagination-div {
    .ant-pagination-options {
      display: none;
    }
    .ant-pagination-prev {
      width: 16px !important;
      height: 16px !important;
      width: 16px;
      height: 16px;
      gap: 8px;
      border-radius: 3px;
      background: #f5f5f6;
      svg {
        margin-bottom: 9px;
      }
    }

    .ant-pagination-next {
      width: 16px !important;
      height: 16px !important;
      gap: 8px;
      border-radius: 3px;
      background: #f5f5f6;
      svg {
        margin-bottom: 9px;
      }
    }

    :where(.css-dev-only-do-not-override-2rgkd4)
      .ant-pagination
      .ant-pagination-prev {
      min-width: 16px !important;
      width: 16px !important;
    }
    .ant-pagination-item {
      width: 24px;
      height: 16px;
      gap: 8px;
      border-radius: 3px;
      background: #f5f5f6;
      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
      a {
        color: var(--Gray-100, #556373);
        font-family: Outfit;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 160% */
        text-transform: uppercase;
      }
    }
    .ant-pagination-item-active {
      width: 24px;
      height: 16px;
      gap: 8px;
      border-radius: 3px;
      background: rgba(76, 187, 203, 0.15);
      /* border: 1px solid #4cbbcb; */
      border: none;
      color: var(--Blue-100, #4cbbcb);
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 160% */
      text-transform: uppercase;
      a {
        color: var(--Blue-100, #4cbbcb);
        font-family: Outfit;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 160% */
        text-transform: uppercase;
      }
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 16px;
  justify-content: start;
  gap: 15px;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
  justify-content: flex-end;
  .cancel {
    padding: 12px;
    gap: 10px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: none;
    text-align: center;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width: 72px;
    width: 100%;
    background: transparent;
    border: 1px solid #e3eced;
  }

  .save {
    padding: 12px;
    gap: 10px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: none;
    text-align: center;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width: 72px;
    width: 100%;
    background: rgb(76, 187, 203);
    color: white;
  }
`;

export { AssociateContactContainer, PaginationContainer, Footer };
