import axios from "axios";
import { store } from "./../store/store";

const axiosFileupload = axios.create({
  baseURL: process.env.REACT_APP_CALL_CENTER_API_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

axiosFileupload.interceptors.request.use(
  (config) => {
    const currentState = store.getState();
    const token = currentState?.callCenter?.authToken?.token;
    if (token != null) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// axiosFileupload.interceptors.response.use(
//   function (response) {
//     return response.data;
//   },
//   function (error) {
//     if (error.response.status === 401) {
//       localStorage.removeItem("token");
//       window.location.reload();
//     } else if (error.response.data.status === 401) {
//       localStorage.removeItem("token");
//       window.location.reload();
//     }
//     return Promise.reject(error);
//   }
// );

export default axiosFileupload;
