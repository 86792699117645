import React, { useState } from "react";
import "./styles";
import { Conatiner } from "./styles";
import Item from "antd/es/list/Item";

const PlansSidebar = ({ scrollToSection, active, activeSection }) => {
  const siderLabels = [
    {
      id: 1,
      name: "General",
    },
    {
      id: 2,
      name: "Forwarding Option",
    },
    {
      id: 3,
      name: "Live Answering Plan",
    },
    {
      id: 4,
      name: "Add-ons",
    },
    {
      id: 5,
      name: "Voicemail",
    },
  ];

  return (
    <Conatiner>
      {siderLabels?.map((label) => (
        <div
          className={`title-main ${activeSection == label?.id && "active"}`}
          key={label?.id}
          onClick={() => scrollToSection(label?.id)}
        >
          <p className="title">{label?.name}</p>
        </div>
      ))}
    </Conatiner>
  );
};

export default PlansSidebar;
