import styled from "styled-components";

const DrawerContainer = styled.div`
  display: flex;
  padding: 26px 24px 40px 24px;
  flex-direction: column;
  ${'' /* height: calc(100vh - 42px); */}
  overflow-y: auto;
  max-width: 387px;
  width: 100%;
  align-items: flex-start;
  background-color: white;
  flex-shrink: 0;
  transition: left 0.3s ease-in-out;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  .save-btn {
    bottom: 0;
    max-width: 339px;
    width: 100%;
    display: flex;
    width: 339px;
    padding: 16px;
    justify-content: start;
    gap: 15px;
    align-items: center;
    flex-direction: row-reverse;
    border-radius: 8px;
    background-color: var(--Blue-100, #fbfafb);
    border: 1px solid #ececec;

    .btn {
      padding: 12px;
      gap: 10px;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: var(--Blue-20, rgba(76, 187, 203, 0.2));
      border: none;
      text-align: center;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      max-width: 72px;
      width: 100%;
    }
    .cancel-btn {
      background-color: transparent;
      border: 1px solid #bbc0c7;
    }
    .save-btn {
      background-color: #4bbbcb;
    }
    .save-btn-disabled {
      background-color: transparent;
      border: 1px solid #bbc0c7;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

const ContentContainer = styled.div`
  display: flex;
  max-width: 339px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  :where(.css-dev-only-do-not-override-1gwfwyx).ant-drawer .ant-drawer-body {
    padding: 0px;
  }
  .ant-drawer-close {
    width: 50px !important; /* Increase the close button container size */
    height: 50px !important; /* Increase height for better click area */
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    svg {
      font-size: 40px !important; /* Ensure the icon size is large */
      width: 40px !important; /* Increase the icon width */
      height: 40px !important; /* Increase the icon height */
    }
  }

  .drawerContainer {
    background: red;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .ant-drawer-header {
    padding: 0px !important;
    background: red;
  }
`;

export { DrawerContainer, Container, ContentContainer };
