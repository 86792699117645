import React, { useState, useEffect } from 'react'
import { Container, MainConatiner } from './styles'
import { Modal } from 'antd'
import './styles'
import { Form, Input, Radio, Space } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useSelector } from 'react-redux'
import { InstanceWithAuth } from '../../../../App'
import axios from 'axios'
import ReactTostify from '../../../../common/ReactTostify'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

const InitiateCancellation = ({
  isModalOpen,
  handleCancel,
  handleOk,
  reasonCodes,
  cancellationDate,
  refetchCompnayData,
}) => {
  const [form] = Form.useForm()
  const [selectedReason, setSelectedReason] = useState(null)
  const [cancellationReason, setCancellationReason] = useState('')
  const { app, auth } = useSelector((state) => state)
  const [loading, setLoading] = useState(false)
  const { companyID } = useParams()

  useEffect(() => {
    if (reasonCodes.length > 0) {
      setSelectedReason(reasonCodes[0].reason_code.code)
    }
  }, [reasonCodes])

  const onChange = (e) => {
    console.log('radio checked', e.target.value)
    setSelectedReason(e.target.value)
  }

  const handleCancelImmediately = async (value) => {
    setLoading(true)

    try {
      const formData = new FormData()
      formData.append('reason_code', selectedReason)
      if (cancellationReason) {
        formData.append('cancellation_message', cancellationReason)
      }
      if (value === 'Cancel at End of Period') {
        formData.append('cancel_at_the_end_of_period', 1)
      } else {
        formData.append('cancel_at_the_end_of_period', 0)
      }

      const response = await axios.post(
        `https://crm-stage.starthubcenters.com/api/companies/${companyID}/cancel-subscription`,
        formData,
        {
          headers: {
            // Authorization: `Bearer heZrNRDcDgL4etPkq9qd2YOOX93FjRGCukDYH3qI8f223625`,
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.status === 200) {
        refetchCompnayData()
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response?.data?.message,
          customClass: {
            confirmButton: 'custom-swal-button',
          },
        })
        handleCancel()
        setCancellationReason('')
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: 'custom-swal-button',
        },
      })
      handleCancel()
    } finally {
      setLoading(false)
    }
  }

  function formatDate(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate)

    // Extract date components
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    // Extract time components
    const hours = String(date.getHours() % 12 || 12).padStart(2, '0') // Convert to 12-hour format
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const amPm = date.getHours() >= 12 ? 'PM' : 'AM'

    // Form the formatted date string
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}${amPm}`

    return formattedDate
  }

  return (
    <Modal
      closeIcon={true}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      open={isModalOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      mask={true}
      width={630}
      className="custom-model"
    >
      <MainConatiner>
        <Container>
          <div className="title-main">
            <p className="title">Cancel Subscription</p>
          </div>
        </Container>
        <div className="form-container">
          <div className="form-main">
            <div className="form">
              <Form form={form} requiredMark={false}>
                <p className="title-label">
                  What is the reason for cancellation?
                </p>
                <Radio.Group onChange={onChange} value={selectedReason}>
                  <Space direction="vertical">
                    {reasonCodes.map((item) => (
                      <Radio
                        key={item.reason_code.id}
                        value={item.reason_code.code}
                      >
                        {item.reason_code.description}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
                <p className="label">
                  You may also enter an optional cancellation reason/message,
                  then press a Cancellation button below.
                </p>
                <Form.Item name="cancellationReason" className="form-items">
                  <TextArea
                    placeholder="Type your message here..."
                    maxLength={300}
                    className="input"
                    value={cancellationReason}
                    onChange={(e) => setCancellationReason(e.target.value)}
                  />
                </Form.Item>
                <p className="title-label-2">What's going to happen?</p>
                <ul>
                  <li>
                    The customer's credit card will no longer be charged after
                    cancellation takes effect.
                  </li>
                  <li>
                    Cancellation takes effect immediately if you press "Cancel
                    Immediately"
                  </li>
                  <li>
                    {`Cancellation takes effect around
                    ${formatDate(
                      cancellationDate?.subscription?.current_period_ends_at
                    )}
                     if you press "Cancel at End of
                    Period"`}
                  </li>
                  <li>No refunds or prorations will be made.</li>
                  <li>
                    If you have cancellation emails configured, an email will be
                    sent to your customer.
                  </li>
                  <li>
                    Canceled subscriptions can be reactivated at a later date.
                  </li>
                </ul>
              </Form>
            </div>
          </div>
        </div>
        <div className="button-div">
          <button className="cancel-btn" type="button" onClick={handleCancel}>
            Go Back
          </button>
          <button
            type=""
            className="calcel-later-btn"
            onClick={() => handleCancelImmediately('Cancel at End of Period')}
          >
            Cancel at End of Period
          </button>
          <button
            disabled={loading}
            type="button"
            onClick={() => handleCancelImmediately('Cancel Immediately')}
          >
            {loading ? 'Cancelling' : 'Cancel Immediately'}
          </button>
        </div>
      </MainConatiner>
    </Modal>
  )
}

export default InitiateCancellation
