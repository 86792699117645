import styled from "styled-components";

const Container = styled.div`
  height: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  .title-main {
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: 528px;
    width: 100%;
    align-self: stretch;

    .title {
      margin: 0;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.63px;
    }
  }
`;

const MainConatiner = styled.div`
  .button-div {
    display: flex;
    justify-content: end;
    margin-right: 15px;
    ${'' /* margin-top: 19px; */}
    gap: 15px;
    .cancel-btn {
      display: flex;
      width: 77px;
      height: 32px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      outline: none;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;
      cursor: pointer;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
    }
    .calcel-later-btn {
      background-color: #dbf1f5;
      border-radius: 8px;
      border-radius: 8px;
      border: 1px solid var(--Blue-100, #4cbbcb);
      background: var(--Blue-20, rgba(76, 187, 203, 0.2));
      box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
      color: var(--Blue-100, #4cbbcb);
    }

    button {
      display: flex;
      height: 32px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      gap: 8px;
      border-radius: 3px;
      background: var(--Blue-100, #4cbbcb);
      cursor: pointer;
      color: var(--White-100, #fff);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }

  .form-container {
    display: flex;
    max-width: 548px;
    width: 100%;
    padding-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    height: auto;

    .form-main {
      display: flex;
      flex-direction: column;
      height: auto;
      max-width: 548px;
      width: 100%;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      .form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        height: auto;
        max-width: 548px;
        width: 100%;
        .title-label {
          height: unset;
          color: black;
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 14.4px */
          align-self: stretch;
        }
        .title-label-2 {
          height: unset;
          color: black;
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 14.4px */
          align-self: stretch;
          margin-top: 70px;
        }
        .label {
          height: unset;
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
          align-self: stretch;
        }
        :where(.css-dev-only-do-not-override-11xg00t).ant-radio-wrapper
          .ant-radio-checked
          .ant-radio-inner {
          border-color: var(--Blue-100, #4cbbcb);
          background-color: var(--Blue-100, #4cbbcb);
        }
        :where(.css-dev-only-do-not-override-11xg00t) [class^="ant-radio"],
        :where(.css-dev-only-do-not-override-11xg00t) [class*=" ant-radio"] {
          border-color: var(--Blue-100, #4cbbcb);
        }
        li {
          height: unset;
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
          align-self: stretch;
        }

        .form-items {
          margin-bottom: 10px;
          height: 64px;
          max-width: 548px;
        }

        .input {
          display: flex;
          height: 115px;
          padding: 12px;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid #d0dee1;
          width: 548px;
          background: #fff;
          resize: none;
        }

        .input::placeholder {
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }
        .input:focus::placeholder {
          color: transparent;
        }
        .input:focus {
          box-shadow: none;
        }
        .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
          ).ant-input {
          border-color: unset;
          border: 1px solid #d0dee1;
          box-shadow: none;
        }
        .ant-form-item-explain-error {
          font-size: 10px;
          font-weight: 400;
          font-family: Outfit;
        }
        .ant-form-item .ant-form-item-label > label::after {
          content: "";
        }
        label {
          height: unset;
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
          align-self: stretch;
        }
      }
      .location {
        align-items: center;
        min-width: 548px;
        height: 41px;
        border-radius: 4px;
        border: 1px solid #d0dee1;

        .ant-select-selector {
          border: none;
          outline: none;
          box-shadow: none;
          .ant-select-selection.ant-select-selection--single {
            background: green;
          }
        }
      }
      .location::placeholder {
        color: var(--Gray-40, rgba(85, 99, 115, 0.4));
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }
      .location:focus::placeholder {
        color: transparent;
      }
      .ant-select-selection-item {
        ${
          "" /* color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; */
        }
      }
    }
  }
`;

export { Container, MainConatiner };
