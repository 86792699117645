import React from "react";
import {
  AllPaymentMethodsContainer,
  Card,
  Container,
  ContentContainer,
  FormContainer,
  InnerCard,
  NotAssigineContainer,
} from "./styles";
import { Form, Input, DatePicker, Select, Skeleton, Popconfirm } from "antd";
import llc from "../../../../../assets/llc.png";
import companyAvatar from "../../../../../assets/company.png";
import { Collapse } from "antd";
import { IoAddOutline } from "react-icons/io5";
import GetLogo from "../../../../getlogo/getlogo";
import { useNavigate } from "react-router-dom";
import "./styles";
import useGetData from "../../../../../hooks/useGetData";
import ReactTostify from "../../../../../common/ReactTostify";
import { InstanceWithAuth } from "../../../../../App";
import { useSelector } from "react-redux";
import Spinner from "../../../../../common/Spinner";
import DataNotFound from "../../../../../common/DataNotFound";
import moment from "moment";
import { useState } from "react";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const { Panel } = Collapse;

const Payment = ({ userId }) => {
  const [collopasedKey, setCollopasedKey] = useState(false);
  const [curentActiveCard, setCurentActiveCard] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const {
    data: paymetData,
    loading: DataLoading,
    error: paymentDataError,
    refetchData: refetchpaymentData,
  } = useGetData(`chargify/payment_profiles/${userId}/contact`);
  const {
    data: compnayData,
    loading: compnayDataLoading,
    error: compnayDataError,
  } = useGetData(`companies?contact_id=${userId}`);

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCompaniesEdited, setSelectedCompaniesEdited] = useState([]);
  const handleClick = (selectedValues) => {
    setSelectedCompanies(selectedValues);
  };
  const handleAddCompnaies = (selectedValues) => {
    setSelectedCompaniesEdited(selectedValues);
  };
  const handleAddPayment = async (apiData) => {
    try {
      setPaymentLoading(true);
      const response = await InstanceWithAuth.post(
        "chargify/payment_profiles",
        apiData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        refetchpaymentData();
        setPaymentLoading(false);

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      }
    } catch (error) {
      setPaymentLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    }
  };
  const handleSubmitEditForm = async (apiData, id) => {
    try {
      setPaymentLoading(true);
      const response = await InstanceWithAuth.put(
        `chargify/payment_profiles/${id}`,
        apiData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        refetchpaymentData();
        setPaymentLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      }
    } catch (error) {
      setPaymentLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    }
  };
  const handleDeletePayment = async (id) => {
    try {
      // setPaymentLoading(true)
      const response = await InstanceWithAuth.delete(
        `chargify/payment_profiles/${id}`,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        refetchpaymentData();
        // setPaymentLoading(false)
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      }
    } catch (error) {
      // setPaymentLoading(false)
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    }
  };
  const handleDateChange = (date, dateString) => {
    setSelectedDate(date);
  };
  const handleExternalSubmit = async () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values, "valuesss");
        const selectedMoment = moment(selectedDate);
        let formData = new FormData();
        formData.append("contact_id", userId);
        selectedCompanies.forEach((companyId, index) => {
          formData.append(`company_ids[${index}]`, companyId);
        });
        formData.append("payment_profile[first_name]", values?.firstName);
        formData.append("payment_profile[full_number]", values?.cardNumber);
        formData.append("payment_profile[cvv]", values?.cardCsv);
        formData.append(
          "payment_profile[expiration_month]",
          values?.experation?.month() + 1
        );
        formData.append(
          "payment_profile[expiration_year]",
          values?.experation?.year()
        );
        formData.append("payment_profile[billing_address]", values?.Adress);
        // formData.append('payment_profile[current_vault]', 'bogus')
        formData.append("payment_profile[vault_token]", 1);

        handleAddPayment(formData);
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };
  const handleExternalSubmitEdit = async (id) => {
    formEdit
      .validateFields()
      .then((values) => {
        /*  const selectedMoment = moment(selectedDate)

        console.log(values, 'values?.experation')
        let formData = new FormData()
        formData.append('contact_id', userId)
        selectedCompaniesEdited.forEach((companyId, index) => {
          formData.append(`company_ids[${index}]`, companyId)
        })
        formData.append('payment_profile[first_name]', values?.firstNameEdit)
        formData.append(
          'payment_profile[full_number]',
          values?.cardNumberEdit ? values?.cardNumberEdit : ''
        )
        formData.append(
          'payment_profile[cvv]',
          values?.cardCsvEdit ? values?.cardCsvEdit : ''
        )
        formData.append(
          'payment_profile[expiration_month]',
          values?.experationEdit?.month() + 1
        )
        formData.append(
          'payment_profile[expiration_year]',
          values?.experationEdit?.year()
        )
        formData.append('payment_profile[billing_address]', values?.AdressEdit)
        formData.append('payment_profile[current_vault]', 'bogus')
        formData.append('payment_profile[vault_token]', 1) */

        let ApiData = {
          contact_id: userId,
          "payment_profile[first_name]": values?.firstNameEdit,

          "payment_profile[full_number]": values?.cardNumberEdit
            ? values?.cardNumberEdit
            : "",
          "payment_profile[cvv]": values?.cardCsvEdit
            ? values?.cardCsvEdit
            : "",

          "payment_profile[expiration_month]":
            values?.experationEdit?.month() + 1,

          "payment_profile[expiration_year]": values?.experationEdit?.year(),
          "payment_profile[billing_address]": values?.AdressEdit,
          // 'payment_profile[current_vault]': 'bogus',
          "payment_profile[vault_token]": 1,
        };
        selectedCompaniesEdited?.forEach((companyId, index) => {
          ApiData = {
            ...ApiData,
            [`company_ids[${index}]`]: companyId,
          };
        });
        const encodedData = new URLSearchParams(ApiData).toString();

        handleSubmitEditForm(encodedData, id);
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };
  return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="Payments" key="1" className="account-panel">
          <Collapse className="form-collapased">
            <Panel header="Add Payment Method" key="1-1" className="panel">
              <div className="form-wrapper">
                <Form
                  className="add-user-form"
                  requiredMark={false}
                  autoSave={false}
                  form={form}
                >
                  <Form.Item
                    label="Name"
                    name="firstName"
                    className="input-label"
                  >
                    <Input className="username-input" placeholder="Gonzalez" />
                  </Form.Item>
                  <Form.Item
                    label="Card Number"
                    name="cardNumber"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a card number",
                      },
                      {
                        pattern: /^[0-9]{12,19}$/,
                        message: "Please enter a valid card number",
                      },
                    ]}
                  >
                    <Input className="username-input" placeholder="Araque" />
                  </Form.Item>
                  <div className="card-details">
                    <Form.Item
                      label="Expiration"
                      name="experation"
                      className="input-label"
                    >
                      <DatePicker
                        placeholder="03/2024"
                        format="MMM YYYY"
                        onChange={handleDateChange}
                        suffixIcon={null}
                        picker="month"
                      />
                    </Form.Item>
                    <Form.Item
                      label="CVV"
                      name="cardCsv"
                      className="input-label"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the CSV",
                        },
                        {
                          pattern: /^[0-9]{3,4}$/,
                          message: "Please enter a valid CSV (3 or 4 digits)",
                        },
                      ]}
                    >
                      <Input
                        className="card-username-input"
                        placeholder="****"
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    label="Company Address"
                    name="Adress"
                    className="input-label"
                  >
                    <Input className="username-input" placeholder="Address" />
                  </Form.Item>
                  <Form.Item
                    label="Company"
                    name="company"
                    className="input-label"
                  >
                    {/* <Select onSelect={handleClick} className="location">
                      {compnayData?.data?.map((option) => (
                        <Select.Option
                          key={option?.id}
                          value={option?.id}
                          label={option?.name}
                        >
                          {option?.name}
                        </Select.Option>
                      ))}
                    </Select> */}

                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select options"
                      // value={selectedItems}
                      onChange={handleClick}
                      className="multi-select"
                    >
                      {compnayData?.data?.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* <Form.Item label="VAT" name="VAT" className="input-label">
                    <Input className="username-input" placeholder="VAT" />
                  </Form.Item> */}
                </Form>
                <div className="form-tittle">
                  {/* <div className="title">Add Payment Method</div> */}
                  <button onClick={handleExternalSubmit} className="save-btn">
                    {" "}
                    {paymentLoading ? <Spinner></Spinner> : "Save"}
                  </button>
                </div>
              </div>
            </Panel>
            <Panel header="All Payment Methods" key="1-2" className="panel">
              <AllPaymentMethodsContainer>
                <ContentContainer>
                  {DataLoading ? (
                    <Skeleton active></Skeleton>
                  ) : paymetData?.data?.length == 0 ? (
                    <DataNotFound label={"No Payment Method Found"} />
                  ) : (
                    paymetData?.data?.map((paymentDetail) => (
                      <Card isActive={curentActiveCard == paymentDetail?.id}>
                        <div className="company">
                          <div className="content">
                            <div className="company-data">
                              <div className="avatar">
                                <img src={llc} alt="llc" className="img" />
                              </div>
                              <div className="dates">
                                <p className="ending-year">
                                  Ending{" "}
                                  {paymentDetail?.masked_card_number?.slice(-4)}
                                </p>
                                <span className="ending-data">
                                  Exp. on {paymentDetail?.expiration_month}/
                                  {paymentDetail?.expiration_year}
                                </span>
                              </div>
                            </div>
                            <div className="icons">
                              <Popconfirm
                                title="Delete Payment Method"
                                description="Are you sure to delete?"
                                onConfirm={() =>
                                  handleDeletePayment(paymentDetail?.id)
                                }
                                // onCancel={c}
                                okText="Yes"
                                cancelText="No"
                              >
                                <span
                                  className="icon"
                                  // onClick={() =>
                                  //   handleDeletePayment(paymentDetail?.id)
                                  // }
                                >
                                  {GetLogo("trash")}{" "}
                                </span>
                              </Popconfirm>
                              <span
                                className="icon edit-icon"
                                onClick={
                                  curentActiveCard == paymentDetail?.id
                                    ? () => setCurentActiveCard()
                                    : () =>
                                        setCurentActiveCard(paymentDetail?.id)
                                }
                              >
                                {GetLogo("edit")}
                              </span>
                            </div>
                          </div>
                        </div>
                        {curentActiveCard == paymentDetail?.id && (
                          <div
                            className="form-wrapper"
                            style={{
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            <Form
                              className="add-user-form"
                              requiredMark={false}
                              autoSave={false}
                              form={formEdit}
                              style={{
                                marginTop: "10px",
                                paddingBottom: "10px",
                              }}
                              // initialValues={{
                              //   firstName: paymentDetail?.first_name,
                              //   cardNumber: paymentDetail?.masked_card_number,
                              //   // experation: `${paymentDetail?.expiration_month}${paymentDetail?.expiration_year}`,
                              //   Adress: paymentDetail?.billing_address,
                              //   cardCsv: paymentDetail?.csv,
                              //   VAT: paymentDetail?.vat,
                              // }}
                            >
                              <Form.Item
                                label="Name"
                                name="firstNameEdit"
                                className="input-label"
                                initialValue={paymentDetail?.first_name}
                              >
                                <Input
                                  className="username-input"
                                  placeholder="Gonzalez"
                                />
                              </Form.Item>
                              <Form.Item
                                label="Card Number"
                                name="cardNumberEdit"
                                className="input-label"
                                // initialValue={paymentDetail?.masked_card_number}
                                rules={[
                                  // {
                                  //   required: true,
                                  //   message: 'Please enter a card number',
                                  // },
                                  {
                                    pattern: /^[0-9]{12,19}$/,
                                    message: "Please enter a valid card number",
                                  },
                                ]}
                              >
                                <Input
                                  className="username-input"
                                  placeholder={
                                    paymentDetail?.masked_card_number
                                  }
                                />
                              </Form.Item>
                              <div className="card-details">
                                <Form.Item
                                  label="Expiration"
                                  name="experationEdit"
                                  className="input-label"
                                  initialValue={dayjs(
                                    moment({
                                      year: paymentDetail?.expiration_year,
                                      month:
                                        paymentDetail?.expiration_month - 1,
                                    })
                                  )}
                                >
                                  <DatePicker
                                    format="MMM YYYY"
                                    placeholder="03/2024"
                                    suffixIcon={null}
                                    picker="month"
                                    // defaultValue={dayjs(
                                    //   moment({
                                    //     year: paymentDetail?.expiration_year,
                                    //     month:
                                    //       paymentDetail?.expiration_month - 1,
                                    //   })
                                    // )}
                                    // value={moment({
                                    //   year: paymentDetail?.expiration_year,
                                    //   month:
                                    //     paymentDetail?.expiration_month - 1,
                                    // })}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="CVV"
                                  name="cardCsvEdit"
                                  className="input-label"
                                  rules={[
                                    // {
                                    //   required: true,
                                    //   message: 'Please enter the CSV',
                                    // },

                                    {
                                      pattern: /^[0-9]{3,4}$/,
                                      message:
                                        "Please enter a valid CSV (3 or 4 digits)",
                                    },
                                  ]}
                                  initialValue={paymentDetail?.csv}
                                >
                                  <Input
                                    className="card-username-input"
                                    placeholder="****"
                                  />
                                </Form.Item>
                              </div>
                              <Form.Item
                                label="Company Address"
                                name="AdressEdit"
                                className="input-label"
                                initialValue={paymentDetail?.billing_address}
                              >
                                <Input
                                  className="username-input"
                                  placeholder="Address"
                                />
                              </Form.Item>
                              <Form.Item
                                label="Company"
                                name="company"
                                className="input-label"
                              >
                                <Select
                                  mode="multiple"
                                  style={{ width: "100%" }}
                                  placeholder="Select options"
                                  // value={selectedItems}
                                  defaultValue={paymentDetail?.assigned_companies?.map(
                                    (item) => item?.company_id
                                  )}
                                  onChange={handleAddCompnaies}
                                  className="multi-select"
                                >
                                  {compnayData?.data?.map((option) => (
                                    <Select.Option
                                      key={option.id}
                                      value={option.id}
                                    >
                                      {option.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                label="VAT"
                                name="VAT-edit"
                                className="input-label"
                              >
                                <Input
                                  className="username-input"
                                  placeholder="VAT"
                                />
                              </Form.Item>
                            </Form>
                            <div
                              className="form-tittle"
                              style={{ paddingBottom: "10px" }}
                            >
                              <button
                                onClick={() =>
                                  handleExternalSubmitEdit(paymentDetail?.id)
                                }
                                className="save-btn"
                              >
                                {" "}
                                {paymentLoading ? <Spinner></Spinner> : "Save"}
                              </button>
                            </div>
                          </div>
                        )}
                        {/* <InnerCard>
                        <div
                          className="add-data"
                          onClick={() =>
                            navigate(`/user/user-payments/${userId}`)
                          }
                        >
                          <span className="add-icon">
                            <IoAddOutline />
                          </span>
                          <p className="add">Add</p>
                        </div>
                      </InnerCard> */}
                      </Card>
                    ))
                  )}
                  {/* <NotAssigineContainer>
                    <p className="title">Not Assigned</p>

                    <div className="content">
                      <div className="company-detail">
                        <div className="avatar">
                          <img
                            src={companyAvatar}
                            alt="avatar"
                            className="img"
                          />
                        </div>
                        <p className="comp-name">Gonzalez LLC</p>
                      </div>
                      <span
                        className="icon"
                        onClick={() =>
                          navigate(`/user/user-payments/${userId}`)
                        }
                      >
                        <IoAddOutline />
                      </span>
                    </div>
                  </NotAssigineContainer> */}
                </ContentContainer>
              </AllPaymentMethodsContainer>
            </Panel>
          </Collapse>
        </Panel>
      </Collapse>
    </Container>
  );
};

export default Payment;
