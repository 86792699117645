import React, { useEffect, useRef, useState } from "react";
import ChatContainer from "./styles";
import ChatMessage from "../chatMessage/ChatMessage";
import moment from "moment";
import { Skeleton } from "antd";
import useGetData from "../../../../hooks/useGetData";
import Comment from "../comment/Comment";

function Commets({
  email,
  currentEmployeeImage,
  selectedEmailId,
  notes,
  refetchNotes,
  onEdit,
}) {
  const chatContainerRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(chatContainer.scrollTimeout);
      chatContainer.scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    };

    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [email]);

  if (!notes) {
    return (
      <Skeleton
        rows={10}
        paragraph={{ rows: 7 }}
        active
        style={{ margin: "10px", width: "auto" }}
      ></Skeleton>
    );
  }

  const formatTime = (time) => {
    return moment(time).format("ddd, MMM D, YYYY");
  };

  const formatTime1 = (time) => {
    return moment(time).format(" h:mm A");
  };

  return (
    <ChatContainer
      ref={chatContainerRef}
      className={isScrolling ? "scrolling" : ""}
    >
      {notes?.data?.map((note) => (
        <div key={note.id}>
          <Comment
            note={note}
            selectedEmailId={selectedEmailId}
            refetchNotes={refetchNotes}
            onEdit={onEdit}
          />
        </div>
      ))}
    </ChatContainer>
  );
}

export default Commets;
