import React, { useState, useRef } from "react";
import "./styles";
import { IoAddOutline } from "react-icons/io5";
import { FaPlay, FaStop, FaMusic } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { Container, ProductContainer } from "./styles";
import VoiceMailSettingsModel from "../../modals/voiceMailSettingsModel/VoiceMailSettingsModel";

const VoiceMailSettings = ({ id, selectedFiles, setSelectedFiles }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPlayingFile, setCurrentPlayingFile] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const TruncateText = ({ text }) => {
    const maxLength = 45;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  // Play or Stop the audio
  const toggleAudioPlayback = (file) => {
    const audioElement = audioRef.current;

    if (currentPlayingFile !== file) {
      // Set the new file for playback
      setCurrentPlayingFile(file);
      if (audioElement) {
        // Create URL only if `file.file` exists
        const fileURL = URL.createObjectURL(file.file);
        audioElement.src = fileURL;
        audioElement.play();
        setIsPlaying(true);
      }
    } else {
      // Toggle play/pause for the same file
      if (isPlaying) {
        audioElement.pause();
        setIsPlaying(false);
      } else {
        audioElement.play();
        setIsPlaying(true);
      }
    }
  };

  // Delete an audio file from the list
  const handleDeleteAudio = (file) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    if (currentPlayingFile === file) {
      // Stop the currently playing file if it is being deleted
      setIsPlaying(false);
      setCurrentPlayingFile(null);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset playback
      }
    }
  };

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  // When saving a file from the modal
  const handleSaveFile = (fileObj) => {
    setSelectedFiles((prevFiles) => [...prevFiles, fileObj]); // Append new file object to list
    setIsModalOpen(false); // Close the modal after saving
  };

  return (
    <Container id={id} className="scroll-section">
      <div className="title-main">
        <p className="title">Voicemail Settings</p>
      </div>
      <ProductContainer>
        <div className="add-products">
          <div className="product-title">
            <p className="title">All Voicemail Files</p>
          </div>
          <button className="add-prduct-btn" onClick={showModal}>
            <span className="btn-icon">
              <IoAddOutline />
            </span>
            <span className="btn-text">Add</span>
          </button>
        </div>

        <div className="product-items">
          {selectedFiles?.map((file, idx) => (
            <div key={idx} className="item-main">
              <div className="top-row">
                <p className="item-title" style={{ margin: "0px" }}>
                  <TruncateText
                    text={file.title || file.file.name}
                  ></TruncateText>
                </p>
                <span className="icon">
                  <AiOutlineDelete
                    color="#4cbbcb"
                    size={16}
                    onClick={() => handleDeleteAudio(file)}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </div>
              <div className="audio-file-display">
                <div className="audio-icon">
                  <FaMusic color="#4cbbcb" size={15} />
                </div>
                <div className="audio-details">
                  <span className="audio-filename">
                    <TruncateText text={file.file.name}></TruncateText>
                  </span>
                </div>
                <div className="audio-controls">
                  {isPlaying && currentPlayingFile === file ? (
                    <FaStop
                      color="#55637366"
                      size={16}
                      onClick={() => toggleAudioPlayback(file)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <FaPlay
                      color="#55637366"
                      size={16}
                      onClick={() => toggleAudioPlayback(file)}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  <AiOutlineDelete
                    color="#55637366"
                    size={20}
                    onClick={() => handleDeleteAudio(file)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </ProductContainer>
      <audio ref={audioRef} /> {/* Audio element for playback */}
      <VoiceMailSettingsModel
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleSaveFile={handleSaveFile}
      />
    </Container>
  );
};

export default VoiceMailSettings;
