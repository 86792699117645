import React, { useState } from 'react'
import { Container, MainConatiner } from './styles'
import { Modal } from 'antd'
import './styles'
import { Form, DatePicker, Radio, Space } from 'antd'
import { useSelector } from 'react-redux'
import axios from 'axios'
import ReactTostify from '../../../../common/ReactTostify'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

const PutOnHold = ({
  isModalOpenHold,
  handleCancelHold,
  handleOkHold,
  cancellationDate,
  refetchCompnayData,
}) => {
  const { app, auth } = useSelector((state) => state)
  const [form] = Form.useForm()
  const [value, setValue] = useState(1)
  const [selectedDate, setSelectedDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const { companyID } = useParams()

  const onChange = (e) => {
    console.log('radio checked', e.target.value)
    setValue(e.target.value)
  }

  const handleDateChange = (value, dateString) => {
    setSelectedDate(dateString)
  }

  const handlePutOnHold = async () => {
    setLoading(true)
    try {
      const formData = new FormData()
      if (value === 2 && selectedDate) {
        formData.append('automatically_resume_at', selectedDate)
      }

      const response = await axios.post(
        `https://crm-stage.starthubcenters.com/api/companies/${companyID}/hold-subscription`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
            // Authorization: `Bearer heZrNRDcDgL4etPkq9qd2YOOX93FjRGCukDYH3qI8f223625`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.status === 200) {
        refetchCompnayData()
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response?.data?.messag,
          customClass: {
            confirmButton: 'custom-swal-button',
          },
        })
        handleCancelHold()
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: 'custom-swal-button',
        },
      })
      handleCancelHold()
    } finally {
      setLoading(false)
    }
  }

  function formatDate(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate)

    // Extract date components
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    // Extract time components
    const hours = String(date.getHours() % 12 || 12).padStart(2, '0') // Convert to 12-hour format
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const amPm = date.getHours() >= 12 ? 'PM' : 'AM'

    // Form the formatted date string
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}${amPm}`

    return formattedDate
  }

  return (
    <Modal
      closeIcon={true}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      open={isModalOpenHold}
      onCancel={handleCancelHold}
      onOk={handleOkHold}
      mask={true}
      width={630}
      className="custom-model"
    >
      <MainConatiner>
        <Container>
          <div className="title-main">
            <p className="title">Put Subscription On Hold</p>
          </div>
        </Container>
        <div className="form-container">
          <div className="form-main">
            <div className="form">
              <Form form={form} requiredMark={false}>
                <p className="title-label-3">What's going to happen?</p>
                <ul>
                  <li>The subscription will be marked as On Hold</li>
                  <li>
                    While on hold, the subscription will not be retried at the
                    next billing date
                  </li>
                </ul>

                <p className="title-label-2">
                  How long will the subscription be on hold?
                </p>
                <Radio.Group onChange={onChange} value={value}>
                  <Space direction="vertical">
                    <Radio value={1}>
                      Keep subscription on hold until it's manually resumed.
                    </Radio>
                    <Radio value={2}>
                      Automatically resume on{' '}
                      <DatePicker
                        placeholder="Date and time"
                        className="dateAndTimePicker"
                        showTime
                        onChange={handleDateChange}
                        disabled={value !== 2}
                      />
                      Eastern Time (US & Canada)
                    </Radio>
                  </Space>
                </Radio.Group>

                <p className="title-label-2">
                  What's going to happen when/if subscription is resumed?
                </p>
                <ul>
                  <li>
                    If the subscription is resumed prior to the next billing
                    date{' '}
                    <span className="date">{`(
                    ${formatDate(
                      cancellationDate?.subscription?.current_period_ends_at
                    )}
                     )`}</span>
                    <ul>
                      <li>
                        the subscription will return to{' '}
                        <span className="active">active</span> status
                      </li>
                      <li>
                        {`the subscription will renew on (
                    ${formatDate(
                      cancellationDate?.subscription?.current_period_ends_at
                    )}
                     )`}
                      </li>
                    </ul>
                  </li>
                  <li>
                    If the subscription is resumed after the next billing date{' '}
                    <span className="date">{`(
                    ${formatDate(
                      cancellationDate?.subscription?.current_period_ends_at
                    )}
                     )`}</span>
                    <ul>
                      <li>
                        the subscription will return to{' '}
                        <span className="active">active</span> status
                      </li>
                      <li>
                        the customer will be billed for the next payment period
                      </li>
                    </ul>
                  </li>
                </ul>
              </Form>
            </div>
          </div>
        </div>
        <div className="button-div">
          <button className="cancel-btn" type="" onClick={handleCancelHold}>
            Back
          </button>

          <button type="" onClick={handlePutOnHold} disabled={loading}>
            {loading ? 'Putting on Hold' : 'Put Subscription on Hold'}
          </button>
        </div>
      </MainConatiner>
    </Modal>
  )
}

export default PutOnHold
