import styled from 'styled-components'

const MainContainer = styled.div`
  position: fixed;
  top: 42px;
  display: flex;
  padding: 16px 40px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
  width: -webkit-fill-available;
  height: 58px;
  z-index: 2;
  /* background-color: var(--White-30, rgba(255, 255, 255, 0.3)); */
  background-color: white;
  .page-title {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.7px;
  }
  .btns {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    .add-mail-btn {
      display: flex;
      height: 26px;
      padding: 0px 20px;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 3px;
      background: var(--Blue-100, #4cbbcb);
      border: none;
      color: var(--White-100, #fff);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
    }
    .add-bulk-btn {
      display: flex;
      height: 26px;
      padding: 0px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 3px;
      background: var(--Blue-100, #4cbbcb);
      border: none;
      color: var(--White-100, #fff);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
    }
  }
`
export { MainContainer }
