import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  ${'' /* overflow: hidden; */}
  .first-column {
    height: 100%;
    flex: 2;
    padding-top: 40px;
  }
  .second-column {
    height: 100%;
    flex: 2.5;
    padding-top: 40px;
    box-sizing: border-box;
  }
  .third-column {
    height: 100%;
    flex: 6;
    padding-top: 40px;
    max-width: 610px;
  }
  .fourth-column {
    height: 100%;
    flex: 3.8;
    padding-top: 99px;
    min-width: 375px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 0 40px;
`;

export const TabButton = styled.button`
  color: var(--Dark-Gray-100, #2b3746);
  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  opacity: unset;
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d0dee1;
  cursor: pointer;
  height: 41px;

  padding: 10px 20px;
  cursor: pointer;
  background-color: ${({ isActive }) =>
    isActive ? "var(--Blue-20, rgba(76, 187, 203, 0.2))" : "white"};
  color: ${({ isActive }) =>
    isActive ? "var(--Blue-100, #4cbbcb)" : "var(--Gray-100, #556373)"};
  border: none;
  border: ${({ isActive }) =>
    isActive ? "1px solid var(--Blue-100, #4cbbcb)" : "1px solid #e3eced;"};
  outline: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? "#f0f0f0" : "#f0f0f0")};
  }
`;

export const TabContent = styled.div`
  padding: 0px 40px;
  background-color: #fff;
  border-radius: 4px;
  height: calc(100% - 60px);
`;

export { MainContainer };
