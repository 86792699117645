import React from "react";
import { MainContainer } from "./styles";
import { useNavigate } from "react-router-dom";

function ProductSettingsSubHeader({title}) {
  const navigate = useNavigate();

  return (
    <MainContainer>
      <div className="page-title">{title}</div>
    </MainContainer>
  );
}

export default ProductSettingsSubHeader;
