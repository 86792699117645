import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  .main-div {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .chat-input {
      width: 100%;
      min-height: 80px;
      height: auto;
      margin-top: 10px;
      justify-content: center;
      display: flex;
      padding: 10px;
      padding-bottom: 40px;
      ${'' /* box-shadow: -2px -1px 20px -6px rgba(0,0,0,0.75); */}
    }
  }
`;

export { MainContainer };
