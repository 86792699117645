import React, { useEffect, useRef, useState } from "react";
import { Container, EnableNotification } from "./styles";
import { Button, Form, Input, Switch } from "antd";
import Swal from "sweetalert2";
import { InstanceWithAuth } from "../../../../../../App";
import Spinner from "../../../../../../common/Spinner";

const Dashboard = ({
  form,
  CompnayAdmin,
  companyData,
  auth,
  companyID,
  handleFormItemChange,
  currentDashBoardLofigEmial,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [password, setPassword] = useState("");
  const [isUpdatePassWord, setIsUpdatePassWord] = useState(false);
  const [enableSaveBtn, setEnableBtn] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [strength, setStrength] = useState(0);
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [enableAddEmail, setEnableAddEmail] = useState(false);
  const dashboardEmailInputRef = useRef(null);

  //

  const onFinish = (values) => {
    console.log("Received values:", values);
  };

  const onFinishFailed = (values) => {
    console.log(values, "values with failed");
  };

  const handleSwitchChange = (checked) => {
    form.setFieldsValue({
      switchField: checked,
    });
    setEnableAddEmail(checked);
  };

  const handlePasswordOnchange = (value) => {
    setShowSaveBtn(true);
    setEnableBtn(true);
    setPassword(value);
    checkPasswordStrength(value);
    setIsUpdatePassWord(true);
  };

  const updatePassword = async () => {
    let formData = new FormData();
    formData.append("password", password);
    try {
      setIsLoading(true);
      const response = await InstanceWithAuth.post(
        `companies/${companyID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      }
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    }
  };

  const checkPasswordStrength = (value) => {
    let points = 0;
    if (value.length >= 8) {
      points += 20;
    }
    if (/[A-Z]/.test(value)) {
      points += 20;
    }
    if (/[a-z]/.test(value)) {
      points += 20;
    }
    if (/\d/.test(value)) {
      points += 20;
    }
    if (/[\W_]/.test(value)) {
      points += 20;
    }

    setStrength(points);
  };

  useEffect(() => {
    if (companyData) {
      form.setFieldsValue({
        switchField: companyData?.live_email_notifications,
        loginEmail: CompnayAdmin?.email,
        DashboardLoginEmail: companyData?.data?.dashboard_login_email,
        emailNotificationCheck:
          companyData?.data?.general_notifications &&
          companyData?.data?.general_notifications !== ""
            ? JSON.parse(companyData?.data?.general_notifications)
            : false,
        emailForNotification:
          companyData?.data?.email_for_general_notifications,
      });
      companyData?.data?.general_notifications &&
        setEnableAddEmail(JSON.parse(companyData?.data?.general_notifications));
    }
  }, [companyData, form, CompnayAdmin]);

  const validatePassword = (rule, value, callback) => {
    if (value && value.length < 8) {
      callback("Password must be at least 8 characters long");
    } else if (value && value.length > 8 && strength < 100) {
      callback(
        "Please include at least one number, one special character, and one capital letter in your password"
      );
    } else {
      callback();
    }
  };

  const handleUpdateEmail = async () => {
    let formData = new FormData();
    formData.append("dashboard_login_email", primaryEmail);
    try {
      setIsLoading1(true);
      const response = await InstanceWithAuth.post(
        `companies/${companyID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        setIsLoading1(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      }
    } catch (error) {
      setIsLoading1(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    }
  };

  const SetDashboardLoginEmail = () => {
    if (
      dashboardEmailInputRef &&
      currentDashBoardLofigEmial?.current?.input?.value
    ) {
      form.setFieldsValue({
        emailForNotification:
          currentDashBoardLofigEmial.current.input.value ||
          companyData?.data?.dashboard_login_email,
      });
    }
  };
  console.log(companyData, "companyData?.data?.CompnayName");
  return (
    <Container>
      <Form
        className="form-main add-user-form"
        requiredMark={false}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="false"
        onValuesChange={handleFormItemChange}
      >
        <div className="title">Dashboard</div>
        <div className="add-user-form" requiredMark={false}>
          <Form.Item
            label="Compnay Name"
            name="CompnayName"
            className="input-label"
          >
            <Input
              type="text"
              className="email-input email-main"
              placeholder="Company Name"
              autoComplete="off"
              defaultValue={companyData?.data?.name}
            />
          </Form.Item>
          <Form.Item
            label="Dashboard's Login Email"
            name="DashboardLoginEmail"
            className="input-label"
          >
            <div className="email-main">
              <Input
                type="email"
                className="email-input"
                placeholder="Email"
                ref={currentDashBoardLofigEmial}
                onChange={(e) => setPrimaryEmail(e.target.value)}
                defaultValue={companyData?.data?.dashboard_login_email}
                autoComplete="off"
              />

              <div className="email-btns">
                <Button className="btn" onClick={() => handleUpdateEmail()}>
                  {isLoading1 ? <Spinner /> : "Change Email"}
                </Button>
              </div>
            </div>
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            className="input-label"
            rules={[{ validator: validatePassword }]}
          >
            <div className="password-main">
              <Input.Password
                type="password"
                className="password-input"
                placeholder="**********"
                onChange={(e) => handlePasswordOnchange(e.target.value)}
                autoComplete="off"
              />

              <div className="password-btns">
                <Button className="btn" onClick={updatePassword}>
                  {isLoading ? <Spinner /> : "Reset Password"}
                </Button>
              </div>
            </div>
          </Form.Item>
          <EnableNotification>
            <span className="label">Email Notifications</span>
            <Form.Item
              name="emailNotificationCheck"
              valuePropName="checked"
              style={{ marginTop: "25px" }}
            >
              <Switch className="switch" onChange={handleSwitchChange} />
            </Form.Item>
          </EnableNotification>
          {enableAddEmail && (
            <div
              className="dashboard-email-main"
              // style={{ visibility: enableAddEmail ? 'visible' : 'hidden' }}
            >
              <Form.Item
                label="Email for notifications"
                name="emailForNotification"
                className="input-label"
              >
                <Input
                  className="dashboard-email-input"
                  placeholder="gonzales@gmail.com"
                  disabled={!enableAddEmail}
                  ref={dashboardEmailInputRef}
                />
              </Form.Item>
              <div className="dashboard-email-btns">
                <Button className="btn" onClick={SetDashboardLoginEmail}>
                  Use Dashboard Email
                </Button>
              </div>
            </div>
          )}
        </div>
      </Form>
    </Container>
  );
};

export default Dashboard;
