// src/app/store.js
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { combineReducers } from 'redux'
import authSlice from '../redux/auth/authSlice'
import appSlice from '../redux/app/appSlice'
import callCenterSlice from '../redux/callCenter/callCenterSlice'

const persistConfig = {
  key: 'root',
  storage,
}
const reducers = combineReducers({
  auth: authSlice,
  app: appSlice,
  callCenter: callCenterSlice
})
const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
})

const persistor = persistStore(store)
export { persistor, store }
