import React, { useEffect, useState } from "react";
import { Form, Modal, Select, Button, Input } from "antd";
import { RxCross2 } from "react-icons/rx";
import { Container, MainConatiner } from "./styles";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const CreateSpace = ({ isCreateSpaceModelOpen, handleCancel, handleOk }) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { auth } = useSelector((state) => state);

  // const handleSave = async () => {
  //   form
  //     .validateFields()
  //     .then(async (values) => {
  //       setIsSaving(true);
  //       try {
  //         const response = await InstanceWithAuth.post(
  //           `support/tickets/email-to-ticket/${selectedEmailId}`,
  //           {
  //             support_pipeline_id: values.pipeline,
  //             support_pipeline_stage_id: values.stage,
  //             description: values.description,
  //             priority: values.priority,
  //           },
  //           {
  //             headers: {
  //               "Content-Type": "multipart/form-data",
  //               Authorization: `Bearer ${auth?.userObj?.api_token}`,
  //             },
  //           }
  //         );

  //         if (response?.status === 201) {
  //           form.resetFields();
  //           handleOk();
  //           Swal.fire({
  //             icon: "success",
  //             title: "Success!",
  //             text: response?.data?.message,
  //             customClass: {
  //               confirmButton: "custom-swal-button",
  //             },
  //           });
  //         }
  //       } catch (error) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error!",
  //           text: error?.response?.data?.message,
  //           customClass: {
  //             confirmButton: "custom-swal-button",
  //           },
  //         });
  //       } finally {
  //         setIsSaving(false); // Re-enable the button and reset the title
  //       }
  //     })
  //     .catch((info) => {
  //       console.log("Validate Failed:", info);
  //     });
  // };

  // useEffect(() => {
  //   if (selectedPipeline) {
  //     form.setFieldsValue({ pipeline: selectedPipeline.id });
  //   }
  // }, [selectedPipeline]);

  return (
    <Modal
      closeIcon={<RxCross2 />}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      open={isCreateSpaceModelOpen}
      onCancel={handleCancel}
      mask={true}
      width={630}
      className="custom-model"
    >
      <Form className="main-form" form={form} requiredMark={false}>
        <MainConatiner>
          <Container>
            <div className="title-main">
              <span className="title">Create a space</span>
            </div>

            <div className="main-div">
              {/* <span>Hello</span> */}
              <div className="form-container">
                <div className="welcome-message">
                  <Form.Item
                    label="Space Name"
                    name="description"
                    className="form-items"
                  >
                    <Input className="input" placeholder="Space name here..." />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Container>

          <div className="button-div">
            <button
              className="close-button"
              type="button"
              onClick={handleCancel}
              disabled={isSaving}
            >
              Close
            </button>
            <button
              type="button"
              // onClick={handleSave}
              disabled={isSaving}
              className={isSaving ? "saving" : ""}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </MainConatiner>
      </Form>
    </Modal>
  );
};

export default CreateSpace;
