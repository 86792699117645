import React, { useEffect, useRef, useState } from "react";
import "./styles";
import { MainConatiner, Container, ProductContainer } from "./styles";
import { Form, Input } from "antd";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import Swal from "sweetalert2";
import Spinner from "../../../../common/Spinner";
import useGetData from "../../../../hooks/useGetData";

const PhoneNumberSettings = ({}) => {
  const [dataSending, setDataSending] = useState(false);
  const { auth } = useSelector((state) => state);
  const formRef = useRef(null);
  const [form] = Form.useForm();

  const { data: productSettingsData, error } =
    useGetData(`services/2/settings`);

  useEffect(() => {
    if (productSettingsData) {
      form.setFieldsValue({
        incoming_minutes_per_month:
          productSettingsData?.data?.incoming_minutes_per_month,
        outgoing_minutes_per_month:
          productSettingsData?.data?.outgoing_minutes_per_month,
        additional_incoming_minute_charges:
          productSettingsData?.data?.additional_incoming_minute_charges,
        additional_outgoing_minute_charges:
          productSettingsData?.data?.additional_outgoing_minute_charges,
      });
    }
  }, [productSettingsData]);

  const onFinish = async (values) => {
    const formData = new URLSearchParams();
    formData.append(
      "incoming_minutes_per_month",
      values?.incoming_minutes_per_month
    );
    formData.append(
      "outgoing_minutes_per_month",
      values?.outgoing_minutes_per_month
    );
    formData.append(
      "additional_incoming_minute_charges",
      values?.additional_incoming_minute_charges
    );
    formData.append(
      "additional_outgoing_minute_charges",
      values?.additional_outgoing_minute_charges
    );

    try {
      setDataSending(true);
      const response = await InstanceWithAuth.put(
        "services/2/settings",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        setDataSending(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      }
    } catch (error) {
      setDataSending(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    }
  };

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <MainConatiner>
      <Container>
        <div className="title-main">
          <p className="title">Mailbox Settings</p>
        </div>
        <ProductContainer>
          <Form
            form={form}
            requiredMark={false}
            className="form"
            onFinish={onFinish}
            ref={formRef}
          >
            <Form.Item
              label="Incoming Minutes per Month"
              name="incoming_minutes_per_month"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Please enter incoming Minutes per Month!",
                },
              ]}
            >
              <Input
                className="username-input"
                placeholder="Incoming Minutes"
              />
            </Form.Item>

            <Form.Item
              label="Outgoing Minutes per Month"
              name="outgoing_minutes_per_month"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Please enter outgoing Minutes per Month!",
                },
              ]}
            >
              <Input
                className="username-input"
                placeholder="Outgoing Minutes"
              />
            </Form.Item>

            <Form.Item
              label="Additional Incoming Minute Charges"
              name="additional_incoming_minute_charges"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Please enter additional Incoming Minute Charges!",
                },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>

            <Form.Item
              label="Additional Outgoing Minute Charges"
              name="additional_outgoing_minute_charges"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Please enter additional Outgoing Minute Charges!",
                },
              ]}
            >
              <Input className="username-input" placeholder="$0.00" />
            </Form.Item>
          </Form>
        </ProductContainer>
        <div className="button-div">
          <button type="button" onClick={handleSubmit}>
            {dataSending ? <Spinner /> : "Save"}
          </button>
        </div>
      </Container>
    </MainConatiner>
  );
};

export default PhoneNumberSettings;
