import React, { useState } from 'react'
import { Container, MainConatiner, GiveCreditContainer } from './styles'
import { Input, Modal, Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import ReactTostify from '../../../../common/ReactTostify'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

const GetDeductCredits = ({
  isModalOpenCreditModel,
  handleCancelCreditModel,
  handleOkCreditModel,
  currentCreditd,
  setCurrentCredits,
}) => {
  const [form] = Form.useForm()
  const [giveCreditClick, setGiverCreditClick] = useState(false)
  const [deductCreditClick, setDeductCreditClick] = useState(false)

  const [giveCreditAmount, setGiveCreditAmount] = useState('')
  const [giveCreditMemo, setGiveCreditMemo] = useState('')
  const [deductCreditAmount, setDeductCreditAmount] = useState('')
  const [deductCreditMemo, setDeductCreditMemo] = useState('')

  const [isLoadingGive, setIsLoadingGive] = useState(false)
  const [isLoadingDeduct, setIsLoadingDeduct] = useState(false)
  const { app, auth } = useSelector((state) => state)

  const { companyID } = useParams()

  const handleGivecreditsClicked = () => {
    setGiverCreditClick(true)
  }

  const handleDeductCreditsClicked = () => {
    setDeductCreditClick(true)
  }

  const goBack = (option) => {
    switch (option) {
      case 'give':
        setGiverCreditClick(false)
        break
      case 'deduct':
        setDeductCreditClick(false)
        break
      default:
        break
    }
  }

  const handleGiveCredit = async () => {
    setIsLoadingGive(true)

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${auth?.userObj?.api_token}`)

    const raw = JSON.stringify({
      service_credit: {
        amount: giveCreditAmount,
        memo: giveCreditMemo,
      },
    })

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }
    try {
      const response = await fetch(
        `https://crm-stage.starthubcenters.com/api/companies/${companyID}/add-service-credit`,
        requestOptions
      )
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const result = await response.json()
      setGiveCreditAmount('')
      setGiveCreditMemo('')
      handleCancelCreditModel()
      setGiverCreditClick(false)
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Service credit issued successfully',
        customClass: {
          confirmButton: 'custom-swal-button',
        },
      })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to issue service credit',
        customClass: {
          confirmButton: 'custom-swal-button',
        },
      })
    } finally {
      setIsLoadingGive(false)
    }
  }

  const handleDeductCredit = async () => {
    setIsLoadingDeduct(true)

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Bearer ${auth?.userObj?.api_token}`)

    const raw = JSON.stringify({
      deduction: {
        amount: deductCreditAmount,
        memo: deductCreditMemo,
      },
    })

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }
    try {
      const response = await fetch(
        `https://crm-stage.starthubcenters.com/api/companies/${companyID}/deduct-service-credit`,
        requestOptions
      )
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const result = await response.json()
      setDeductCreditAmount('')
      setDeductCreditMemo('')
      handleCancelCreditModel()
      setDeductCreditClick(false)
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Service credit deducted successfully',
        customClass: {
          confirmButton: 'custom-swal-button',
        },
      })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to deduct service credit',
        customClass: {
          confirmButton: 'custom-swal-button',
        },
      })
    } finally {
      setIsLoadingDeduct(false)
    }
  }

  return (
    <Modal
      closeIcon={true}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      open={isModalOpenCreditModel}
      onCancel={handleCancelCreditModel}
      onOk={handleOkCreditModel}
      mask={true}
      width={630}
      className="custom-model"
    >
      <MainConatiner>
        <Container>
          <div className="title-main">
            <p className="title">
              {giveCreditClick
                ? 'Give Credit'
                : deductCreditClick
                ? 'Deduct Credit'
                : 'Give or Deduct Service Credit'}
            </p>
          </div>
        </Container>
        {giveCreditClick ? (
          <GiveCreditContainer>
            <p className="warning-message">
              This is a service credit that will not apply to one specific
              invoice and will instead apply to the entire subscription.
            </p>
            <Form form={form}>
              <div className="form-main">
                <div className="form">
                  <div name="giveAmount" className="form-items">
                    <span className="label">
                      Credit Amount to Give (in USD)*
                    </span>
                    <Input
                      className="input"
                      placeholder="$"
                      value={giveCreditAmount}
                      onChange={(e) => setGiveCreditAmount(e.target.value)}
                    />
                  </div>
                  <div label="Memo" name="giveMemo" className="form-items">
                    <span className="label">Memo</span>
                    <TextArea
                      placeholder="Type your message here..."
                      className="textarea"
                      value={giveCreditMemo}
                      onChange={(e) => setGiveCreditMemo(e.target.value)}
                    />
                  </div>
                  <div className="credit-details">
                    <div className="empty"></div>
                    <div className="right">
                      <div className="detail-row">
                        <span className="label">Current Credit:</span>
                        <span className="amount">
                          $
                          {currentCreditd?.service_credits?.balance_in_cents /
                            100}
                        </span>
                      </div>
                      <div className="detail-row">
                        <span className="label">Give Credit:</span>
                        <span className="amount">${giveCreditAmount || 0}</span>
                      </div>
                      <div className="detail-row">
                        <span className="label">New Credit Balance:</span>
                        <span className="amount">
                          $
                          {currentCreditd?.service_credits?.balance_in_cents /
                            100 +
                            parseFloat(giveCreditAmount || 0)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            <div className="button-div">
              <button onClick={() => goBack('give')} className="cancel-btn">
                Back
              </button>

              <button
                type="button"
                onClick={handleGiveCredit}
                disabled={isLoadingGive || !giveCreditAmount}
              >
                {isLoadingGive ? 'Issuing...' : 'Issue Service Credit'}
              </button>
            </div>
          </GiveCreditContainer>
        ) : deductCreditClick ? (
          <GiveCreditContainer>
            <p className="warning-message">
              This is a service credit deduction that will not apply to one
              specific invoice and will instead apply to the entire
              subscription.
            </p>
            <Form form={form}>
              <div className="form-main">
                <div className="form">
                  <div name="deductAmount" className="form-items">
                    <span className="label">
                      Credit Amount to Deduct (in USD)*
                    </span>

                    <Input
                      className="input"
                      placeholder="$"
                      value={deductCreditAmount}
                      onChange={(e) => setDeductCreditAmount(e.target.value)}
                    />
                  </div>
                  <div name="deductMemo" className="form-items">
                    <span className="label">Memo</span>
                    <TextArea
                      placeholder="The description of why the service deduction is being created"
                      maxLength={3}
                      className="textarea"
                      value={deductCreditMemo}
                      onChange={(e) => setDeductCreditMemo(e.target.value)}
                    />
                  </div>
                  <div className="credit-details">
                    <div className="empty"></div>
                    <div className="right">
                      <div className="detail-row">
                        <span className="label">Current Credit:</span>
                        <span className="amount">
                          $
                          {currentCreditd?.service_credits?.balance_in_cents /
                            100}
                        </span>
                      </div>
                      <div className="detail-row">
                        <span className="label">Deduct Credit:</span>
                        <span className="amount">
                          ${deductCreditAmount || 0}
                        </span>
                      </div>
                      <div className="detail-row">
                        <span className="label">New Credit Balance:</span>
                        <span className="amount">
                          $
                          {currentCreditd?.service_credits?.balance_in_cents /
                            100 -
                            parseFloat(deductCreditAmount || 0)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            <div className="button-div">
              <button onClick={() => goBack('deduct')} className="cancel-btn">
                Back
              </button>

              <button
                type="button"
                onClick={handleDeductCredit}
                disabled={isLoadingDeduct || !deductCreditAmount}
              >
                {isLoadingDeduct
                  ? 'Deducting...'
                  : 'Issue Service Credit Deduction'}
              </button>
            </div>
          </GiveCreditContainer>
        ) : (
          <>
            <div className="form-container">
              <div className="form-main">
                <div className="form">
                  <p className="title-label">Select Service Credit Type</p>
                  <div className="btns-wrapper">
                    <div
                      onClick={handleGivecreditsClicked}
                      className="btn-wrapper"
                    >
                      <span className="title">Give Credits</span>
                      <span className="sub-title">
                        Issue a service credit for this subscription
                      </span>
                    </div>
                    <div
                      onClick={handleDeductCreditsClicked}
                      className="btn-wrapper"
                    >
                      <span className="title">Deduct Credits</span>
                      <span className="sub-title">
                        Remove credits from this subscription
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </MainConatiner>
    </Modal>
  )
}

export default GetDeductCredits
