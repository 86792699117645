import React, { useState, useRef } from "react";
import { MainContainer } from "./styles";
import EmptyChat from "./emptyChat/EmptyChat";
import ChatInput from "./chatInput/ChatInput";
import AddMembersModel from "./addMembersModel/AddMembersModel";

const ChatSection = () => {
  const [isModelOpen, setIsPipelineModelOpen] = useState(false);

  const handleOk = async () => {
    setIsPipelineModelOpen(false);
  };

  const handleCancel = () => {
    setIsPipelineModelOpen(false);
  };

  const showModal = () => {
    setIsPipelineModelOpen(true);
  };

  return (
    <MainContainer>
      <div className="main-div">
        <EmptyChat showModal={showModal} />

        <div className="chat-input">
          <ChatInput />
        </div>
      </div>
      <AddMembersModel
        isModelOpen={isModelOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
      />
    </MainContainer>
  );
};

export default ChatSection;
