import React, { useState, useEffect } from 'react'
import {
  CancelButton,
  ButtonContainer,
  Status,
  BulletPoint,
  Text,
  Subtitle,
  Section,
  Title,
  Container,
} from './styles'
import { Modal } from 'antd'
import './styles'
import { Form, Input, Radio, Space } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useSelector } from 'react-redux'
import { InstanceWithAuth } from '../../../../App'
import axios from 'axios'
import ReactTostify from '../../../../common/ReactTostify'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'
import Spinner from '../../../../common/Spinner'
const AssumePlanModal = ({
  isModalOpenAssumePlan,
  handleCancel,
  handleOk,
  reasonCodes,
  cancellationDate,
  refetchCompnayData,
  setResumePlan,
  auth,
  companyID,
  companyData,
  resumePlan,
}) => {
  function formatDate(inputDate) {
    const date = new Date(inputDate)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours() % 12 || 12).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const amPm = date.getHours() >= 12 ? 'PM' : 'AM'
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}${amPm}`
    return formattedDate
  }
  // const convertDate = (dateString) => {
  //   const formattedDate = moment
  //     .tz(dateString, 'YYYY-MM-DD HH:mm:ss', 'America/Chicago')
  //     .format('MM/DD/YYYY hh:mmA z')
  //   return formattedDate
  // }
  const handleResume = async () => {
    try {
      setResumePlan(true)
      const formdata = new FormData()
      formdata.append('resumption_charge', 'immediate')
      const response = await InstanceWithAuth.post(
        `companies/${companyID}/resume-subscription`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response?.status === 200) {
        handleCancel()
        setResumePlan(false)
        refetchCompnayData()
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response?.data?.message,
          customClass: {
            confirmButton: 'custom-swal-button',
          },
        })
      }
    } catch (error) {
      setResumePlan(false)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: 'custom-swal-button',
        },
      })
    }
  }

  return (
    <Modal
      closeIcon={true}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      open={isModalOpenAssumePlan}
      onCancel={handleCancel}
      onOk={handleOk}
      mask={true}
      width={630}
      className="custom-model"
    >
      <Container>
        <Title>Resume Subscription</Title>
        <Section>
          <Subtitle>What has already happened?</Subtitle>
          <Text>
            <BulletPoint>
              The subscription was placed <Status onHold>On Hold</Status> on
              09/18/2024 04:59PM CDT
            </BulletPoint>
          </Text>
        </Section>
        <Section>
          <Subtitle>What’s going to happen?</Subtitle>
          <Text>
            <BulletPoint>
              The subscription state will be set to{' '}
              <Status active>Active</Status>
            </BulletPoint>
            <BulletPoint>
              The subscription will renew on 10/18/2024 03:50PM CDT
            </BulletPoint>
            <BulletPoint>
              Please review your changes above, as there is no "Undo" button.
              Once you are ready to proceed with these changes, click "Resume
              Subscription".
            </BulletPoint>
          </Text>
        </Section>
        <ButtonContainer>
          <button
            disabled={resumePlan}
            className="resume-btn"
            onClick={handleResume}
          >
            {resumePlan ? <Spinner></Spinner> : 'Resume Subscription'}
          </button>
          <CancelButton onClick={handleCancel}>Cancel</CancelButton>
        </ButtonContainer>
      </Container>
    </Modal>
  )
}

export default AssumePlanModal
