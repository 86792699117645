import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  width: 100%;

  .members-title {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.7px;
    margin: 0px;
  }

  .members-number {
    color: var(--Gray-100, #556373);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .search-container {
    margin-top: 15px;
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    .search-div {
      height: 41px;
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 0 15px;
      border: 1px solid #e3eced;
      border-radius: 4px;
      input {
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
      }
    }

    .add-btn {
      display: flex;
      align-items: center;
      height: 41px;
      border-radius: 4px;
      padding: 0 20px;
      gap: 15px;
      color: white !important;
      cursor: pointer;
      background: var(--Blue-100, #4cbbcb);
    }
  }

  .table-container {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;

    .table-header {
      background-color: #f9fafb;
      border-botttom: 1px solid #e3eced;
    }

    .table-header-row {
      display: flex;
      justify-content: space-between;
      padding: 7px 15px;
      border-bottom: 1px solid #e3eced;
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 9px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
    }

    .table-row {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      border-bottom: 1px solid #e3eced;
    }

    .table-row:last-child {
      border-bottom: none;
    }

    .table-cell {
      width: 25%;
      padding: 5px;
      text-align: left;
      font-family: Outfit, sans-serif;
      font-size: 14px;
      color: #556373;
    }

    .header-cell {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 9px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    .edit-btn,
    .remove-btn {
      padding: 5px 10px;
      margin-right: 10px;
      background-color: #e3eced;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    .remove-btn {
      background-color: #ff6b6b;
      color: #fff;
    }

    .edit-btn:hover,
    .remove-btn:hover {
      opacity: 0.8;
    }
  }
`;

export { MainContainer };
