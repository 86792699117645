import React, { useEffect, useState } from "react";
import { Modal, Tooltip } from "antd";
import { Container } from "./styles";
import {
  MdCall,
  MdCallEnd,
  MdKeyboardVoice,
  MdOutlineWifiCalling3,
} from "react-icons/md";
import Flag from "../../../../components/addCallComponents/callFrom/tempIcons/flag.svg";
import GetLogo from "../../../getlogo/getlogo";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "../../../../services/callCenterServices";
import IncomingCall from "../../../../assets/incoming-call.gif";

const CraeteTicket = ({
  callCenter = {},
  isPipelineModelOpen,
  handleCancel,
  handleOk,
}) => {
  const navigate = useNavigate();
  const [phoneNumber, setphoneNumber] = useState("(704) 555-0127");

  useEffect(() => {
    {
      callCenter?.myReservedTasks &&
        callCenter?.myReservedTasks.length > 0 &&
        callCenter?.myReservedTasks.map((item) => {
          let attributes = JSON.parse(item.attributes);
          let call_sid = callCenter?.incomingCall?.parameters?.CallSid;
          setphoneNumber(attributes?.caller);
        });
    }
  }, []);

  const acceptCall = () => {
    handleOk();
    navigate(
      `/live-answering/in-call/${callCenter?.incomingCall?.parameters?.CallSid}`
    );
  };
  return (
    <Modal
      closeIcon={null}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      open={isPipelineModelOpen}
      maskClosable={false}
      width={500}
      className="custom-model"
    >
      <Container>
        <div className="caller-details">
          <div>
            <div>
              <img
                src={IncomingCall}
                alt="Calling animation"
                width={60}
                height={60}
              />
            </div>

            <div>
              {/* <span className="label">Incoming Call from</span> */}
              <div className="number-container">
                <img src={Flag} className="flag" alt="country flag" />{" "}
                <span className="number">{formatPhoneNumber(phoneNumber)}</span>
              </div>
            </div>
          </div>

          <div className="btns">
            <Tooltip title="Accept">
              <button className="call-btn" onClick={acceptCall}>
                <MdCall size={35} />
              </button>
            </Tooltip>

            <Tooltip title="Reject">
              <button onClick={handleCancel} className="btn-red">
                <MdCallEnd size={35} />
              </button>
            </Tooltip>

            {/* <Tooltip title="Voice Mail">
              <button className="btn-style">
                <MdKeyboardVoice size={35} />
              </button>
            </Tooltip> */}
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default CraeteTicket;
