import styled from "styled-components";

const ChatContainer = styled.div`
  padding: 0px;
  max-height: 100%;
  overflow-y: auto;
  position: relative;
  flex: 1;

  .activity {
    text-align: center;
    margin: 10px 0;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--Gray-100, #556373);
  }

  .date-separator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    .separator {
      height: 1px;
      background: #e3eced;
      width: 100%;
    }
    .date {
      text-align: center;
      width: 220px;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: var(--Gray-100, #556373);
    }
  }

  /* Initially hide the scrollbar */
  &::-webkit-scrollbar {
    width: 0px; /* Effectively hides scrollbar in Chrome/Safari */
  }

  /* Show scrollbar when scrolling */
  &:hover::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4cbbcb;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #389ea7;
  }

  scrollbar-width: none;

  /* Show scrollbar when hovering over the container */
  ${
    "" /* &:hover {
    scrollbar-width: thin;
    scrollbar-color: rgb(85, 99, 115) #f1f1f1;
  } */
  }
`;

export default ChatContainer;
