import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  authToken: null,
  socketConnection:null,
  incomingCall:null,
  myReservedTasks:null,
  showCallPopup:false,
  inCall:false,
  lastCallStatus: null,
  currentCompany: null
};

export const callCenterSlice = createSlice({
  name: "CallCenterAuth",
  initialState,
  reducers: {
    handleCallCenterLogin: (state, action) => {
      state.authToken = action.payload; 
      //state.operatorLabels = action.payload?.settings; 
    },   
    setSocketConnection: (state, action) => {
      state.socketConnection = action.payload; 
    },
    setIncomingCall: (state, action) => {
      state.incomingCall = action.payload; 
    },
    setShowCallPopup: (state, action) => {
      state.showCallPopup = action.payload; 
    },
    setlastCallStatus: (state, action) => {
      state.lastCallStatus = action.payload; 
    },
    clearIncomingCall: (state, action) => {
      state.incomingCall = null; 
    },
    setInCall: (state, action) => {
      state.inCall = action.payload; 
    },
    setcurrentCompany: (state, action) => {
      state.currentCompany = action.payload; 
    },
    setMyReservedTasks: (state, action) => {
      state.myReservedTasks = action.payload; 
    },
    handleCallCenterLogout: (state, action) => {
      state.authToken = null; 
      state.operatorLabels = null   
      state.socketConnection = null;  
    },
  },
});

export const { handleCallCenterLogin,handleCallCenterLogout,setSocketConnection,setIncomingCall,clearIncomingCall,setMyReservedTasks,setShowCallPopup,setInCall,setlastCallStatus,setcurrentCompany} = callCenterSlice.actions;

export default callCenterSlice.reducer;
