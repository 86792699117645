import styled from 'styled-components'

const Container = styled.div`
  border-radius: 5px;
  width: 100%;
  font-family: Outfit;
  font-weight: 400;
`

const Title = styled.h2`
  margin-bottom: 10px;
  color: #333;
  font-weight: 400;
`

const SubText = styled.p`
  margin-bottom: 20px;
  color: #666;
  font-weight: 400;
`

const OptionContainer = styled.div`
  margin-bottom: 20px;
  font-weight: 400;
`

const Option = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  input[type='radio']#start {
    accent-color: #4cbbcb !important;
  }
  input[type='radio']#resume {
    accent-color: #4cbbcb !important;
  }
`

const Label = styled.label`
  margin-left: 10px;
  font-weight: 400;
  color: #333;
  cursor: pointer;
`

const Info = styled.div`
  margin-left: 20px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
`

const Details = styled.div`
  margin-top: 10px;
  ul {
    padding-left: 20px;
    color: #666;
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .label-check-box {
    margin-left: 10px;
    font-weight: 400;
    color: #333;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  .active-btn {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none;
    cursor: pointer;
  }
`

// const ReactivateButton = styled.button`

// `

const GoBack = styled.a`
  margin-left: 20px;
  text-decoration: none;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Blue-100, #fff);
  color: var(--White-100, #fff);
  color: var(--Dark-Gray-100, #2b3746);
  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: 1px solid #d0dee1;
  cursor: pointer;
`

export {
  Container,
  GoBack,
  ButtonContainer,
  CheckboxContainer,
  Details,
  Info,
  Label,
  Option,
  OptionContainer,
  SubText,
  Title,
}
