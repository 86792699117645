import React, { useState, useRef } from "react";
import { MainContainer } from "./styles";
import { IoSearch } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";

const AddedSpaceMembers = () => {
  return (
    <MainContainer>
      <span className="members-title">Members</span>
      <span className="members-number">2 members</span>
      <div className="search-container">
        <div className="search-div">
          <IoSearch />
          <input placeholder="Search members" />
        </div>
        <div className="add-btn">
          <FaPlus color="white" />
          <span>Add</span>
        </div>
      </div>

      {/* Here is the table */}
      <div className="table-container">
        <div className="table-header">
          <div className="table-header-row">
            <div className="table-cell header-cell">Name</div>
            <div className="table-cell header-cell">Email</div>
            <div className="table-cell header-cell">Role</div>
            <div className="table-cell header-cell">Actions</div>
          </div>
        </div>

        <div className="table-body">
          <div className="table-row">
            <div className="table-cell">John Doe</div>
            <div className="table-cell">john@example.com</div>
            <div className="table-cell">Admin</div>
            <div className="table-cell">
              <button className="remove-btn">
                <MdOutlineDelete />
              </button>
            </div>
          </div>
          <div className="table-row">
            <div className="table-cell">Jane Smith</div>
            <div className="table-cell">jane@example.com</div>
            <div className="table-cell">Member</div>
            <div className="table-cell">
              <button className="remove-btn">
                <MdOutlineDelete />
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default AddedSpaceMembers;
