import React, { useEffect, useState } from "react";
import { Collapse, Form, Input, Select } from "antd";
import { Container, ContentContainer } from "./styles";
import useGetData from "../../../../../hooks/useGetData";
import TextArea from "antd/es/input/TextArea";
import { InstanceWithAuth } from "../../../../../App";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const { Option } = Select;
const { Panel } = Collapse;

const AboutThisTicket = ({
  ticket,
  selectedEmailId,
  userID,
  email,
  refetchData,
}) => {
  const [collopasedKey, setCollopasedKey] = useState(false);
  const [isPipelineModelOpen, setIsPipelineModelOpen] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [stages, setStages] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [initialContacts, setInitialContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const { auth, app } = useSelector((state) => state);
  const [isSaving, setIsSaving] = useState(false);
  const { data: pipelines, error: pipelinesError } = useGetData(
    `support/tickets/pipelines?status=1`
  );

  const [form] = Form.useForm();
  // Prefill form values based on ticket data
  useEffect(() => {
    if (ticket) {
      const selectedPipeline = pipelines?.data?.find(
        (p) => p.id === ticket?.support_pipeline_id
      );
      setSelectedPipeline(selectedPipeline);
      setStages(selectedPipeline?.stages || []);

      // Find the employee from the employees list using user_id
      const selectedEmployeeObj = employees.find(
        (emp) => emp.id === ticket?.user_id
      );
      setSelectedEmployee(selectedEmployeeObj?.id); // Set employee id as selected

      // Set initial values for the form
      form.setFieldsValue({
        ticketName: ticket?.name,
        pipeline: ticket?.support_pipeline_id,
        stage: ticket?.support_pipeline_stage_id,
        priority: ticket?.priority,
        status: ticket?.status,
        description: ticket?.description,
        associate_contact: ticket?.contact_id,
        associate_company: ticket?.company_id,
        associate_employee: selectedEmployeeObj?.id,
      });

      const contactId = ticket?.contact_id;
      setSelectedContact(contactId);
      fetchCompanies(contactId);

      setSelectedCompany(ticket?.company_id);
      setSelectedEmployee(ticket?.user_id);
    }
  }, [pipelines, email, form]);

  // Fetch contacts and pre-select the associated one
  useEffect(() => {
    async function fetchContacts() {
      try {
        const response = await InstanceWithAuth.get(
          `contacts?query=&per_page=10`,
          {
            headers: {
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        );
        const fetchedContacts = response?.data?.data || [];

        const associatedContact = fetchedContacts?.find(
          (contact) => contact.id === ticket?.contact_id
        );

        setContacts(fetchedContacts);
        setSelectedContact(associatedContact?.id || null);

        if (
          associatedContact &&
          !fetchedContacts.find((c) => c.id === associatedContact.id)
        ) {
          setInitialContacts([associatedContact, ...fetchedContacts]);
        } else {
          setInitialContacts(fetchedContacts);
        }
      } catch (error) {
        console.error("Failed to fetch contacts", error);
      }
    }

    fetchContacts();
  }, [email]);

  const fetchCompanies = async (contactId) => {
    try {
      const response = await InstanceWithAuth.get(
        `companies?contact_id=${contactId}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      const fetchedCompanies = response?.data?.data || [];

      setCompanies(fetchedCompanies);

      // Pre-select the company associated with the ticket
      const associatedCompany = fetchedCompanies?.find(
        (company) => company.id === ticket?.company_id
      );

      setSelectedCompany(associatedCompany?.id || null);
    } catch (error) {
      console.error("Failed to fetch companies", error);
    }
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await InstanceWithAuth.get(`/users-home`, {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        });
        const allEmployees = response.data.roles.reduce(
          (acc, role) => [...acc, ...role.users],
          []
        );
        setEmployees(allEmployees);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  const handleContactChange = (contactId) => {
    setSelectedContact(contactId);
    fetchCompanies(contactId);
    form.setFieldsValue({ associate_company: null });
  };

  const handleEmployeeChange = (employeeId) => {
    setSelectedEmployee(employeeId);
    form.setFieldsValue({ associate_employee: employeeId });
  };

  const handlePipelineChange = (pipelineId) => {
    const pipeline = pipelines?.data?.find((p) => p.id === pipelineId);
    setSelectedPipeline(pipeline);
    setStages(pipeline?.stages || []);

    // Reset the stage when pipeline changes
    form.setFieldsValue({ stage: null });
  };

  const handleOk = () => {
    setIsPipelineModelOpen(false);
  };

  const handleCancel = () => {
    setIsPipelineModelOpen(false);
  };

  const showModal = () => {
    setIsPipelineModelOpen(true);
  };

  const handleContactSearch = async (value) => {
    try {
      const response = await InstanceWithAuth.get(
        `contacts?query=${value}&per_page=5`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      setContacts(response?.data?.data || []);
    } catch (error) {
      console.error("Failed to search contacts", error);
    }
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    const values = form.getFieldsValue();
    const data = {
      // contact_id: values.associate_contact,
      // company_id: values.associate_company,
      // support_pipeline_id: values.pipeline,
      // support_pipeline_stage_id: values.stage,
      // user_id: values.associate_employee,
      name: values.ticketName,
      description: values.description,
      priority: values.priority,
      // status: values.status,
    };

    const encodedData = new URLSearchParams(data).toString();

    try {
      const response = await InstanceWithAuth.put(
        `support/tickets/${ticket?.id}`,
        encodedData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        setIsSaving(false);
        refetchData();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
        handleCancel();
      }
    } catch (error) {
      setIsSaving(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    }
  };

  return (
    <Container open={collopasedKey}>
      <ContentContainer>
        {ticket ? (
          <>
            <Form
              className="form-main"
              requiredMark={false}
              form={form}
              initialValues={{
                ticketName: ticket?.name,
                pipeline: ticket?.support_pipeline_id,
                stage: ticket?.support_pipeline_stage_id,
                priority: ticket?.priority,
                status: ticket?.status,
                description: ticket?.description,
              }}
            >
              <Form.Item
                label="Ticket name"
                name="ticketName"
                className="form-item"
              >
                <Input className="location" placeholder="Ticket name" />
              </Form.Item>

              <div className="welcome-message">
                <Form.Item
                  label="Ticket Description"
                  name="description"
                  className="form-items"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a description!",
                    },
                  ]}
                >
                  <TextArea
                    className="input"
                    placeholder="Ticket description here..."
                  />
                </Form.Item>
              </div>

              {/* <Form.Item label="Pipeline" name="pipeline" className="form-item">
                <Select
                  className="location"
                  placeholder="Select pipeline"
                  onChange={handlePipelineChange}
                >
                  {pipelines?.data?.map((pipeline) => (
                    <Option key={pipeline.id} value={pipeline.id}>
                      {pipeline.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Stage" name="stage" className="form-item">
                <Select
                  className="location"
                  placeholder="Select stage"
                  disabled={!stages.length}
                >
                  {stages.map((stage) => (
                    <Option key={stage.id} value={stage.id}>
                      {stage.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item> */}

              <Form.Item label="Priority" name="priority" className="form-item">
                <Select className="location" placeholder="Select priority">
                  <Option value="Low">Low</Option>
                  <Option value="Medium">Medium</Option>
                  <Option value="High">High</Option>
                  <Option value="Very High">Very High</Option>
                </Select>
              </Form.Item>

              {/* <Form.Item label="Status" name="status" className="form-item">
                <Select className="location" placeholder="Select status">
                  <Option value="Open">Open</Option>
                  <Option value="Closed">Closed</Option>
                  <Option value="In progress">In progress</Option>
                  <Option value="Resolved">Resolved</Option>
                  <Option value="Soft Close">Soft Close</Option>
                  <Option value="Await Reply">Await Reply</Option>
                </Select>
              </Form.Item> */}

              {/* <Form.Item
                label="Associate Contact"
                name="associate_contact"
                className="form-item"
              >
                <Select
                  showSearch
                  className="location"
                  placeholder="Select contact"
                  onSearch={handleContactSearch}
                  filterOption={false}
                  value={selectedContact} // Pre-selected contact
                  onChange={handleContactChange} // Handle contact selection change
                  defaultActiveFirstOption={false}
                  notFoundContent={null}
                >
                  {initialContacts.map((contact) => (
                    <Option key={contact.id} value={contact.id}>
                      {contact.first_name} {contact.last_name} ({contact.email})
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Associate Company"
                name="associate_company"
                className="form-item"
              >
                <Select
                  className="location"
                  placeholder="Select company"
                  value={selectedCompany}
                  onChange={setSelectedCompany}
                  optionLabelProp="label"
                >
                  {companies.map((company) => (
                    <Option
                      key={company.id}
                      value={company.id}
                      label={company.name}
                    >
                      {company.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Associate Employee"
                name="associate_employee"
                className="form-item"
              >
                <Select
                  className="location"
                  placeholder="Select employee"
                  value={selectedEmployee}
                  onChange={(employeeId) => {
                    setSelectedEmployee(employeeId);
                    form.setFieldsValue({ associate_employee: employeeId });
                  }}
                  optionLabelProp="label"
                >
                  {employees.map((employee) => (
                    <Option
                      key={employee.id}
                      value={employee.id}
                      label={`${employee.first_name} ${employee.last_name}`}
                    >
                      {employee.first_name} {employee.last_name} (
                      {employee.email})
                    </Option>
                  ))}
                </Select>
              </Form.Item> */}

              <div className="button-div">
                {/* <button
                  className="close-button"
                  type="button"
                >
                  Close
                </button> */}
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={isSaving}
                  className={isSaving ? "saving" : ""}
                >
                  {isSaving ? "Saving..." : "Save"}
                </button>
              </div>
            </Form>
          </>
        ) : (
          <></>
        )}
      </ContentContainer>
    </Container>
  );
};

export default AboutThisTicket;
