import styled from "styled-components";

const MessageBodyContainer = styled.div`
  background-color: white;
  width: 100%;
  margin: 0px !important;
  height: 100%;
  padding: 0px;
  display: flex;
  padding-bottom: 80px;
  flex-direction: column;
  
  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #e3eced;
    font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    padding: 8px;
    margin-top: -0px !important;
    ${"" /* z-index: 1001 !important; */}
  }

  .ql-editor {
    border: none;
  }

  .ql-snow * {
    border: none;
  }

  .quill-editor {
    height: 100%;
  }
  .quill-editor .ql-container {
    border: none;
  }

  .quill-editor .ql-toolbar {
    margin: 0;
  }

  .text-area-wrapper {
    position: relative;
    height: auto;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-bottom: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .main-div {
    padding: 5px 20px;
    display: flex;
    min-width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e3eced;

    .left-div {
      display: flex;
      gap: 10px;
      align-items: center;
      width: 100%;
      .ant-select-selection-placeholder {
        ${"" /* padding-left: 5px !important; */}
      }

      .ant-select-selection-placeholder,
      .ant-select-selection-search-input {
        padding: 0 !important;
        line-height: 1.5;
        height: auto;
      }

      .ant-select-selector {
        padding: 4px 0px 0px 0px;
      }

      .name {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: var(--Blue-100, #4cbbcb);
        cursor: pointer;
      }

      .input {
        border: none;
        outline: none;
      }

      .back-icon {
        font-size: 18px;
        cursor: pointer;
        color: #2b3746;
      }

      .name-div {
        display: flex;
        align-items: center;
        background-color: #dbf1f5;
        border-radius: 8px;
        border: 1px solid var(--Blue-100, #4cbbcb);
        background: var(--Blue-20, rgba(76, 187, 203, 0.2));
        box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
        color: var(--Blue-100, #4cbbcb);
        padding: 1px 10px;
        gap: 10px;

        .cross-icon {
          cursor: pointer;
        }

        .name {
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .right-div {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-left: 10px;

      .name {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: var(--Blue-100, #4cbbcb);
        cursor: pointer;
      }
    }
  }

  .three-dots-icons {
    font-size: 12px;
    color: gray;
    cursor: pointer;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 5px;
  background-color: #f7f9fa;
  border-top: 1px solid #e3eced;
  height: 45px;
  position: absolute;
  bottom: 0;
  left: 50;
  right: 50;
  width: 348px;
  margin: 0;

  .custom-toolbar {
    padding: 0;
    display: flex;
    gap: 10px;
    button {
      background-color: white;
      border: 1px solid #e3eced;
      border-radius: 4px;
      cursor: pointer;
      ${
        "" /* width: 40px;
      height: 30px; */
      }
    }
  }

  .send-button {
    margin-left: auto;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none;
    cursor: pointer;
    padding: 6px 15px;
  }
`;

const Button = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4.3px 5.5px;
  background-color: white;
  border: 1px solid #e3eced;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;

  svg {
    font-size: 14px;
    color: #2b3746;
  }
`;

const AttachmentList = styled.div`
  display: flex;
  ${"" /* flex-wrap: wrap; */}
  gap: 5px;
  margin: 0px 10px 14px 10px;
  ${"" /* background: green; */}
`;

const AttachmentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-right: 35px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f7f7f7;
  flex-basis: 100%; // Set to take up full width
  position: relative;
  color: var(--Gray-80, rgba(85, 99, 115, 0.8));
  text-align: center;
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 140px;
`;

const RemoveIcon = styled.div`
  margin-left: 10px;
  cursor: pointer;
  font-size: 15px;
  position: absolute;
  top: 5;
  bottom: 5;
  right: 4px;
`;

export {
  MessageBodyContainer,
  ButtonsWrapper,
  Button,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
};
