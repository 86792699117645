import axios from "axios";
import axiosCallcenter from "./axiosCallcenter";
import axiosGeneral from "./axiosGeneral";
import axiosFileupload from "./axiosFileupload";

export const formatPhoneNumber = (phoneNumber) => {    
  const cleanNumber = phoneNumber.replace(/^(\+91|0)|\D/g, "");
  if (cleanNumber.length === 10) {
    return `(${cleanNumber.slice(0, 3)}) ${cleanNumber.slice(3, 6)}-${cleanNumber.slice(6)}`;
  }  
  return phoneNumber;
};
export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

export const handleCCLogin = (email, password) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosGeneral.post(`/auth/login`, { email, password });
    resolve(rsp);
  });
};

export const addEmpLabels = (data) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.post(`/settings/CallOperatorLabel`, data);
    resolve(rsp);
  });
};

export const getEmpLabels = (id) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.post(`/settings/MyLabels`, { user_id: id });
    resolve(rsp);
  });
};

export const getCompanyDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.get(`/settings/getCompanyDetails/${id}`);
    resolve(rsp);
  });
};

export const getTotalLogList = (id) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.get(`/settings/getCallLogsLength`);
    resolve(rsp);
  });
};


export const updateCallDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.post(`/settings/updateCall`, data);
    resolve(rsp);
  });
};
 
export const searchNumber = (data) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.post(`/processClaim/SearchNumber`, data);
    resolve(rsp);
  });
};

export const claimNumber = (data) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.post(`/processClaim/ClaimNumber`, data);
    resolve(rsp);
  });
};

export const getCompanyNumbers = (id) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.get(`/processClaim/getCompanyPhoneNumbers/${id}`);
    resolve(rsp);
  });
};

export const saveForwordOPtions= (data) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.post(`/settings/ForwordOptions`,data);
    resolve(rsp);
  });
};

export const getNumberSettingsBySid= (sid) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.get(`/processClaim/getNumberSettings/${sid}`);
    resolve(rsp);
  });
};

export const saveCompanySettings= (data) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.post(`/settings/SaveLiveAnsweringSettings`,data);
    resolve(rsp);
  });
};

export const getCompanyLiveAnsweringSettingsByPlan= (company_id,plan_id) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.get(`/settings/GetLiveAnsweringSettings/${company_id}/${plan_id}`);
    resolve(rsp);
  });
};
export const GetCompanyLiveAnsweringSettingsByPhoneSid= (company_id,phone_sid) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.get(`/settings/GetCompanyLiveAnsweringSettings/${company_id}/${phone_sid}`);
    resolve(rsp);
  });
};

export const ForwordCall= (callSid,forwardTo) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.post(`/settings/ForwordCall`,{callSid,forwardTo});
    resolve(rsp);
  });
};

export const UploadFile= (formData) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosFileupload.post(`/settings/uploadFile`,formData);
    resolve(rsp);
  });
};

export const GetConferenceDetails= (callSid) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.get(`/webhook/handleCall/GetConferrence/${callSid}`);
    resolve(rsp);
  });
};

export const handleHoldCaller= (callSid,type) => {
  console.log(callSid,type)
  return new Promise(async (resolve, reject) => {
    if(type){
      let rsp = await axiosCallcenter.get(`/webhook/handleCall/handleHoldCaller/${callSid}`);
      resolve(rsp);
    }else{
      let rsp = await axiosCallcenter.get(`/webhook/handleCall/handleUnHoldCaller/${callSid}`);
      resolve(rsp);
    }
  });
};

export const EndCall= (callSid,forwardTo) => {
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.get(`/settings/EndCall/${callSid}`);
    resolve(rsp);
  });
};



export const getCallLogs = (type) => {
  let log_type = "Received";
  if(type == 1) log_type = "Received";
  if(type == 2) log_type = "Missed";
  if(type == 3) log_type = "Voicemail";
  return new Promise(async (resolve, reject) => {
    let rsp = await axiosCallcenter.post(`/settings/getCallLogs`, { log_type });
    resolve(rsp);
  });
};

export const initializeTwilio = async (emp_ext) => {
  var Twilio = window.Twilio;
  return new Promise(async (resolve, reject) => {
    const response = await fetch(`${process.env.REACT_APP_CALL_CENTER_API_BASE_URL}/webhook/handleCall/getToken`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ identity: emp_ext }),
      }
    );
    const data = await response.json();
    const token = data.token;

    // Initialize Twilio Device with the token
    let device = new Twilio.Device(token, {
      // Optional config options
      debug: true,
      sound: {
        incoming: true,  // Enable incoming call ringtone
        outgoing: true,  // Enable outgoing call ringtone
        disconnect: true // Enable disconnect sound
      }
    });

    resolve(device);
  });
};
