import React, { useState } from "react";
import { PageContainer, TabsContainer } from "./styles";
import CommonHaeder from "../../../components/header/commonHeader/CommonHaeder";
import ProductSettingsSubHeader from "../../../components/settings/productSettings/productSettingsSubHeader/LiveAnsweringSubHeader";
import ProductSettingsTabs from "../../../components/settings/productSettings/productSettingsTabs/ProductSettingTabs";

const ProductSettings = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [settingType, setSettingType] = useState("Mailbox");
  const [eventType, setEventType] = useState("");

  return (
    <>
      <CommonHaeder
        firstBcItem={"Settings"}
        firstBcIcon={"setting"}
        secondBcItem={"Product Settings"}
        secondBcIcon="setting"
      ></CommonHaeder>
      <ProductSettingsSubHeader title="Product Settings"></ProductSettingsSubHeader>
      <TabsContainer>
        <ProductSettingsTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setSettingType={setSettingType}
          setEventType={setEventType}
        ></ProductSettingsTabs>
      </TabsContainer>
    </>
  );
};

export default ProductSettings;
