import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  ${'' /* .left {
    display: flex;
    flex-direction: column;
    background: white;
    width: 200px !important;
    padding: 10px 5px;

    .item-div {
      display: flex;
      flex-direction: row;
      gap: 15px;
      padding: 5px 7px 7px 7px;
      align-items: center;
      border-radius: 5px;
      background: rgba(76, 187, 203, 0.2);
      width: 100%;
      span {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.49px;
      }
    }

    .item-div:hover {
      background: rgba(76, 187, 203, 0.2);
    }
    .tab-div {
      width: 100%;
      padding: 2px 15px;
      height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .line {
        width: 3px;
        background: var(--Blue-100, #4cbbcb);
        height: 40px;
      }
      .text {
        padding: 20px;
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: var(--Gray-100, #556373);
      }
    }
  } */}
  .right {
    width: 100%;
  }
`;

export { MainContainer };
