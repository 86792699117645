import styled from 'styled-components'

const BannerContainer = styled.div`
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  height: 50px;
  justify-content: center;
  background-color: #fafbfc;
  border-bottom: 1px solid #e3eced;
  border-bottom: none;
  position: fixed;
  width: max-content;
  top: 42px;
  z-index: 5;
  width: -webkit-fill-available;
  .main-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    .save-btn {
      display: flex;
      height: 26px;
      padding: 12px 16px;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: #4cbaca;
      border: none;
      color: var(--White-100, #fff);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
    }
    .user-detail {
      display: flex;
      min-width: 448px;
      align-items: center;
      justify-content: center;
      gap: 24px;
      height: 36px;
      flex-shrink: 0;
      width: fit-content;

      .user {
        width: 130px;
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        gap: 12px;
        width: fit-content;

        .user-img {
          width: 36px;
          height: 36px;
          border-radius: 36px;
          background-color: black;
        }

        .user-name {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.49px;
          /* height: 25px; */
        }
        .contact-name {
          color: var(--Gray-100, #556373) !important;
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: -0.49px;
          border-left: 1px solid rgba(208, 222, 225, 0.4);
          padding: 4px 0px 4px 10px;
          cursor: pointer;
        }
        .contact-name:hover {
          color: #4cbaca;
        }
      }

      .btns {
        width: 140px;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: space-between;
        margin-right: 0px;

        .in-active {
          display: flex;
          align-items: center;
          gap: 8px;
          /* width: 87px; */
          padding: 6px 12px;
          margin-left: 20px;
          gap: 5px;
          border-radius: 4px;
          border: 1px solid #ff7b7b;
          background: #ffbaba;
          width: max-content;
          .text {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
          }

          svg {
            width: 4px;
            height: 4px;
            fill-opacity: unset;
            circle {
              fill-opacity: unset;
              stroke: #ff7b7b;
            }
          }

          .btn-icon {
            width: 9px;
            height: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .btn-icon svg {
            width: 100%;
            height: 100%;
          }
        }
        .active {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 6px 12px;
          margin-left: 20px;
          gap: 5px;
          border-radius: 4px;
          width: max-content;
          border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
          background: rgba(76, 203, 96, 0.2);
          .text {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
          }
          .btn-icon {
            width: 9px;
            height: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .btn-icon svg {
            width: 100%;
            height: 100%;
          }
        }
        .manager-btn {
          display: flex;
          width: 50px;
          height: 26px;
          padding: 9px;
          /* margin-top: 5px; */
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-left: none;
          /* border: none; */
          border-right: none;
          border-top: none;
          border-bottom: none;
          background: transparent;
          .btn-icon {
            svg {
              width: 12px;
              height: 12px;
            }
          }

          .btn-text {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            width: max-content;
            /* width: auto; */
          }
        }
        .manager-btn-offline {
          /* min-width: 92px; */
          display: flex;
          width: 50px;
          height: 26px;
          padding: 9px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-left: 1px solid rgba(208, 222, 225, 0.4);
          /* border: none; */
          border-right: none;
          border-top: none;
          border-bottom: none;
          background: transparent;
          .btn-icon {
            svg {
              width: 12px;
              height: 12px;
              fill: #ff7b7b;
              fill-opacity: unset;
              circle {
                fill: #ff7b7b;
                fill-opacity: unset;
                stroke: #ff7b7b;
              }
            }
          }

          .btn-text {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            width: max-content;
            /* width: auto; */
          }
        }

        .impression-btn {
          display: flex;
          width: 50px;
          height: 26px;
          padding: 9px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-left: 1px solid rgba(208, 222, 225, 0.4);
          border-right: 1px solid rgba(208, 222, 225, 0.4);
          border-top: none;
          border-bottom: none;
          background: transparent;
          cursor: pointer;

          .btn-icon {
            width: 12px;
            height: 12px;
          }

          .btn-text {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
          }
        }
      }
      .sub-options {
        margin-left: 0px;
      }
    }

    .search {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 4px 7px;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      background: #fff;
    }
  }
  .in-active {
    display: flex;
    align-items: center;
    gap: 8px;
    /* width: 87px; */
    padding: 6px 12px;
    margin-left: 20px;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #ff7b7b;
    background: #ffbaba;
    width: max-content;
    .text {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
    }

    svg {
      width: 4px;
      height: 4px;
      fill-opacity: unset;
      circle {
        fill-opacity: unset;
        stroke: #ff7b7b;
      }
    }

    .btn-icon {
      width: 9px;
      height: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-icon svg {
      width: 100%;
      height: 100%;
    }
  }
  .active {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    margin-left: 20px;
    gap: 10px;
    border-radius: 4px;
    width: max-content;
    border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
    background: rgba(76, 203, 96, 0.2);
    .text {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
    }
    .btn-icon {
      width: 9px;
      height: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-icon svg {
      width: 100%;
      height: 100%;
    }
  }
`

export { BannerContainer }
