import styled from 'styled-components'

const MainConatiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  .manager-btn {
    display: flex;
    height: 26px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
    background: transparent;
    padding: 0;
    .btn-icon {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .btn-text {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      width: max-content;
    }
  }
  .manager-btn-offline {
    display: flex;
    height: 26px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-left: 1px solid rgba(208, 222, 225, 0.4);
    border-right: none;
    border-top: none;
    border-bottom: none;
    background: transparent;
    .btn-icon {
      svg {
        width: 12px;
        height: 12px;
        fill: #ff7b7b;
        fill-opacity: unset;
        circle {
          fill: #ff7b7b;
          fill-opacity: unset;
          stroke: #ff7b7b;
        }
      }
    }

    .btn-text {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      width: max-content;
    }
  }
  .in-active {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    gap: 5px;
    border-radius: 4px;
    border: 1px solid #ff7b7b;
    background: #ffbaba;
    width: max-content;
    .text {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    svg {
      width: 4px;
      height: 4px;
      fill-opacity: unset;
      circle {
        fill-opacity: unset;
        stroke: #ff7b7b;
      }
    }

    .btn-icon {
      width: 9px;
      height: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-icon svg {
      width: 100%;
      height: 100%;
    }
  }
  .on-hold {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    gap: 5px;
    border-radius: 4px;
    border: 1px solid #f1b970;
    background: #f7ead2;
    width: max-content;
    .text {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    svg {
      width: 4px;
      height: 4px;
      fill-opacity: unset;
      circle {
        fill-opacity: unset;
        stroke: #ffdc9c;
      }
    }

    .btn-icon {
      width: 9px;
      height: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-icon svg {
      width: 100%;
      height: 100%;
    }
  }
  .active {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    gap: 5px;
    border-radius: 4px;
    width: max-content;
    border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
    background: rgba(76, 203, 96, 0.2);
    .text {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    .btn-icon {
      width: 9px;
      height: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-icon svg {
      width: 100%;
      height: 100%;
    }
  }
`
export { MainConatiner }
