import styled from "styled-components";

const AvatarContainer = styled.div`
  height: 30px;

  .avatarContainer {
    height: 25px;
    width: 25px;
    background: var(--Blue-100, #4cbbcb);
    border-radius: 20px;
    align-items: center;
    justify-content: center;

    .image-style {
      height: 25px;
      width: 25px;
      border-radius: 20px;
      overflow: hidden;
    }

    .user-initials {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #4cbbcb;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
    }

    .placeholder {
      color: white;
      font-size: 14px;
      text-align: center;
    }
  }
`;

export default AvatarContainer;
