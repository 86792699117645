import styled from 'styled-components'

const MainContainer = styled.div`
  /* display: flex;
  align-items: center;
  gap: 5.333px; */
  border-left: 1px solid var(--Soft-Blue-100, #eff5f6);
  /* width: 183px; */
  .custom-dropdown {
    position: relative;
    padding: 8px 26px 8px 16px;
    max-width: 230px;
  }

  .dropdown-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    justify-content: space-evenly;
    .user-name {
      color: var(--Gray-60, rgba(85, 99, 115, 0.6));
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 160% */
      max-width: 110px;
      width: 100%;
      height: 16px;
    }
    .user-avatar {
      .image-avatar {
        width: 24px;
        height: 24px;
        /* border-radius: 24px; */
        .avatar {
          width: 100%;
          height: 100%;
          border-radius: 24px;
        }
      }
    }
  }

  .dropdown-header:hover {
    /* background-color: #f0f0f0; */
  }

  .dropdown-list {
    position: absolute;
    top: 70%;
    left: 0;
    z-index: 9;
    width: 100%;
    list-style: none;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 0px 0px 12px 12px;
    border-right: 1px solid #cde8ed;
    border-bottom: 1px solid #cde8ed;
    border-left: 1px solid #cde8ed;
    background: var(--White-100, #fff);
    box-shadow: 0px 5px 10px 0px rgba(33, 85, 93, 0.06);
    li {
      display: flex;
      padding: 4px 8px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      color: var(--Gray-100, #556373);
      font-family: Outfit;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .dropdown-list li {
    padding: 8px;
    cursor: pointer;
  }

  .dropdown-list li svg path:hover {
    fill: #4cbbcb;
  }
  .dropdown-list li svg {
    width: 12px;
    height: 12px;
  }
  .dropdown-list li:hover {
    border-radius: 4px;
    background-color: #dbf1f5;
  }
`

export { MainContainer }
