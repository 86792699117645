import React, { useEffect, useState } from "react";
import {
  Container,
  HiddenCheckboxInput,
  StyledCheckbox,
  StyledCheckboxContainer,
} from "./styles";
import "./style.css";
import { Form, Input, Select } from "antd";

const IvrSettings = ({ phoneNumberSettings, id }) => {
  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const [value3, setValue3] = useState(null);
  

  const options = [    
    {
      label: "Keypad Number 0",
      value: "0",
    },
    {
      label: "Keypad Number 1",
      value: "1",
    },
    {
      label: "Keypad Number 2",
      value: "2",
    },
    {
      label: "Keypad Number 3",
      value: "3",
    },
    {
      label: "Keypad Number 4",
      value: "4",
    },
    {
      label: "Keypad Number 5",
      value: "5",
    },
    {
      label: "Keypad Number 6",
      value: "6",
    },
    {
      label: "Keypad Number 7",
      value: "7",
    },
    {
      label: "Keypad Number 8",
      value: "8",
    },
    {
      label: "Keypad Number 9",
      value: "9",
    },
  ];

  useEffect(() => {
    if(phoneNumberSettings?.receptionist_receive_call){
        setValue1(phoneNumberSettings?.receptionist_receive_call)
    }
    if(phoneNumberSettings?.forword_to_my_number){
        setValue2(phoneNumberSettings?.forword_to_my_number)
    }
    if(phoneNumberSettings?.forword_to_defined_number){
        setValue3(phoneNumberSettings?.forword_to_defined_number)
    }
  }, [phoneNumberSettings])
  

  return (
    <Container id={id} className="scroll-section">
      <p className="title">IVR Settings</p>
      <div className="form-container">
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Receptionist receive the call"
              name="receptionist_receive_call"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="Select Number"
                className="location"
                value={value1}
                style={{
                  width: "100% !important",
                }}
                onChange={setValue1}
                options={options.filter(item => item.value != value2 && item.value != value3)}
              />
            </Form.Item>
            
          </div>
          
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="IVR message"
              name="receptionist_receive_call_message"
              className="form-items"
            >
              <Input              
                placeholder="Type a custom message"
                className="location"                
                
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Forword to my number"
              name="forword_to_my_number"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="Select Number"
                className="location"
                value={value2}
                style={{
                  width: "100% !important",
                }}
                onChange={setValue2}
                options={options.filter(item => item.value != value1 && item.value != value3)}
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="IVR message"
              name="forword_to_my_number_message"
              className="form-items"
            >
              <Input              
                placeholder="Type a custom message"
                className="location"                
                
              />
            </Form.Item>
          </div>
        </div>

        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Forword to defined number"
              name="forword_to_defined_number"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="Select Number"
                className="location"
                value={value3}
                style={{
                  width: "100% !important",
                }}
                onChange={setValue3}
                options={options.filter(item => item.value != value1 && item.value != value2)}
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Defined Number"
              name="forword_defined_number"
              className="form-items"
            >
              <Input              
                placeholder="Type a number"
                className="location"                
                
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="IVR message"
              name="forword_to_defined_number_message"
              className="form-items"
            >
              <Input              
                placeholder="Type a custom message"
                className="location"                
                
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default IvrSettings;
