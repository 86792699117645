import React, { Fragment, Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Redirect,
  Navigate,
} from "react-router-dom";
import { Layout, theme } from "antd";
import { useSelector } from "react-redux";
import SideBar from "./sidebar/SideBar";
import AppRoutes from "../routes/AppRoutes";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { handleLogoutUser } from "../redux/auth/authSlice";
import PageLoader from "../common/PageLoader";
import Login from "../pages/auth/Login";
import ContractedChats from "../components/support/contractedChats/ContractedChatss";
import { io } from "socket.io-client";
import { clearIncomingCall, setInCall, setIncomingCall, setMyReservedTasks, setShowCallPopup, setSocketConnection, setlastCallStatus } from "../redux/callCenter/callCenterSlice";
import { EndCall, initializeTwilio } from "../services/callCenterServices";
import CraeteTicket from "../components/liveAnswering/liveAnsweringSubHeader/craeteTicket/CraeteTicket";
const { Content } = Layout;

const Layouts = () => {
  const { auth, app, callCenter } = useSelector((state) => state);
  const [hasContractedChats, setHasContractedChats] = useState(false);
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if(callCenter?.authToken?.settings){
  //       makeConnections()
  //   }
  // }, [])
  useEffect(() => {
    makeConnections()
  }, [callCenter?.authToken?.settings])

  const makeConnections = ()=>{
    if(callCenter && callCenter?.authToken && Object.keys(callCenter?.authToken?.settings).length > 0 ){
      const newSocket = io(`${process.env.REACT_APP_CALL_CENTER_API_ROOT_URL}`);
      setSocket(newSocket);        
      // Initialize twilio
      setupTwilio(callCenter?.authToken?.settings?.emp_ext)
    }    
  }  

  useEffect(() => {
    if (socket === null) return;
    socket.on("tasks", (data) => {
      console.log("tasks===", data);
      dispatch(setMyReservedTasks(data.myReservedTasks));
      //setmyReservedTasks(data.myReservedTasks);
    });

  }, [socket]);

  useEffect(() => {
    if (socket === null || callCenter?.incomingCall === null) return;
    socket.emit("getTasks")

    socket.on("taskCancelled", ({ data }) => {
      console.log("TASK Cancelled ==", data);
      let attributes = JSON.parse(data.TaskAttributes);
      let call_sid = callCenter?.incomingCall?.parameters?.CallSid;
      let cancelledCallSID = attributes?.worker_call_sid || attributes?.call_sid;
      console.log(`call_sid: ${call_sid} and cancelledCallSID: ${cancelledCallSID}`);
      if (call_sid && cancelledCallSID && call_sid == cancelledCallSID) {
        dispatch(setlastCallStatus(data))
        dispatch(clearIncomingCall())    
        dispatch(setShowCallPopup(false))   
        dispatch(setInCall(false)) 
      }
    });

    socket.on("taskCompleted", ({ data }) => {
      console.log("TASK Completed ==", data);
      let attributes = JSON.parse(data.TaskAttributes);
      let call_sid = callCenter?.incomingCall?.parameters?.CallSid;
      let cancelledCallSID = attributes?.worker_call_sid || attributes?.call_sid;
      console.log(`call_sid: ${call_sid} and cancelledCallSID: ${cancelledCallSID}`);
      if (call_sid && cancelledCallSID && call_sid == cancelledCallSID) {
        dispatch(setlastCallStatus(data))
        dispatch(clearIncomingCall())    
        dispatch(setShowCallPopup(false))   
        dispatch(setInCall(false)) 
      }
    });

  }, [socket,callCenter?.incomingCall]);

  const setupTwilio = async(emp_ext) => {
    let device = await initializeTwilio(emp_ext);
    device.on("incoming", handleIncomingCall);   
  }
  const handleIncomingCall = (call) => {
    console.log("==NEW CALL==",call) 
    dispatch(setlastCallStatus(null))  
    dispatch(setIncomingCall(call))
    dispatch(setShowCallPopup(true))
  };




  

  useEffect(() => {
    const checkLocalStorageForEmailIds = () => {
      const emailIdsArray =
        JSON.parse(localStorage.getItem("emailIdsArray")) || [];
      if (emailIdsArray.length > 0) {
        setHasContractedChats(true);
      } else {
        setHasContractedChats(false);
      }
    };

    // Check initially on component mount
    checkLocalStorageForEmailIds();

    // Add event listener to handle the custom "localStorageChange" event
    const handleLocalStorageChange = () => {
      checkLocalStorageForEmailIds();
    };

    window.addEventListener("localStorageChange", handleLocalStorageChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener(
        "localStorageChange",
        handleLocalStorageChange
      );
    };
  }, []);

  
  const {} = theme.useToken();
  const isTokenExpired = (token) => {
    // if (!token) return // No token, nothing to do
    // const decodedToken = jwtDecode(token)
    // const expirationTime = decodedToken?.exp * 1000
    // if (expirationTime < Date?.now()) {
    //   dispatch(handleLogoutUser())
    // }
  };

  useEffect(() => {
    if (isTokenExpired(auth?.userObj?.api_token)) {
      dispatch(handleLogoutUser());
      localStorage.removeItem("emailIdsArray");
      localStorage.removeItem("id");
      localStorage.removeItem("folder");
    }
  }, []);

  const handleReceiveCall = (req, res) => {
    callCenter?.incomingCall.accept();
    dispatch(setShowCallPopup(false))
    dispatch(setInCall(true))
  }
  const handleRejectCall = (req, res) => {

    if (callCenter && callCenter?.myReservedTasks.length > 0) {  
      let attributes = JSON.parse(callCenter?.myReservedTasks[0].attributes); 
      EndCall(attributes.call_sid).then(res=>{
        callCenter?.incomingCall.reject();
        dispatch(setShowCallPopup(false))
        dispatch(setInCall(false))
      })
    }
  }

  return (
    <Router>
      {/* CALL POPUP */}
       {callCenter?.incomingCall && callCenter?.myReservedTasks?.length > 0 && <CraeteTicket
        callCenter={callCenter}
        isPipelineModelOpen={callCenter?.showCallPopup}
        handleCancel={handleRejectCall}
        handleOk={handleReceiveCall}
      />}
       {/* CALL POPUP END*/}

      <Layout style={{ minHeight: "100vh", backgroundColor: "white" }}>
        {auth?.isLogin && app?.currentPage != "login" ? (
          <SideBar collapsed={app?.sidebarCollapsed}></SideBar>
        ) : (
          ""
        )}
        <Layout
          style={{
            marginLeft: !app?.sidebarCollapsed && auth?.isLogin ? "272px" : "",
          }}
        >
          <Content style={{ backgroundColor: "#fff" }}>
            <Suspense>
              <Routes>
                {AppRoutes?.map((route) => {
                  if (route?.isProtected && auth?.isLogin) {
                    return (
                      <Route
                        key={route?.id}
                        path={route?.pathName}
                        Component={route?.component}
                      />
                    );
                  } else if (!route?.isProtected) {
                    return (
                      <Route
                        key={route?.id}
                        path={route?.pathName}
                        Component={route?.component}
                      />
                    );
                  }
                })}
                {!auth?.isLogin && (
                  <Route path="*" element={<Navigate to="/login" />} />
                )}
              </Routes>
            </Suspense>
          </Content>
        </Layout>

        {hasContractedChats && auth?.isLogin && app?.currentPage != "login" && (
          <div
            style={{
              width: "auto",
              maxHeight: "500px",
              height: "auto",
              position: "fixed",
              zIndex: "1000",
              bottom: 0,
              right: 0,
              background: "transparent",
            }}
          >
            <ContractedChats />
          </div>
        )}
      </Layout>
    </Router>
  );
};
export default Layouts;
