import React, { useState } from "react";
import {
  FirstColumnContainer,
  IconLabelContainer,
  IconLabel,
  IconButtonGroup,
  IconBackground,
} from "./styles";
import GetLogo from "../../getlogo/getlogo";
import ColumnHeader from "./statusAndPipline/StatusAndPipline";
import { Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import StatusAndPipline from "./statusAndPipline/StatusAndPipline";
import AllTabs from "./AllTabs/AllTabs";
import {
  FaPen,
  FaEnvelope,
  FaPhone,
  FaLaptop,
  FaCalendar,
  FaEllipsisH,
} from "react-icons/fa";
import moment from "moment";

const FirstColumn = ({ data, ticket, refetchData }) => {
  const [selectedItem, setSelectedItem] = useState("1st Action");

  const iconButtons = [
    { icon: FaPen, label: "Note" },
    { icon: FaEnvelope, label: "Email" },
  ];

  const formatTime = (time) => {
    const now = moment();
    const updatedAt = moment(time);

    if (now.diff(updatedAt, "minutes") < 60) {
      return `${now.diff(updatedAt, "minutes")} minutes`;
    } else if (now.diff(updatedAt, "hours") < 24) {
      return `${now.diff(updatedAt, "hours")} hours`;
    } else if (now.diff(updatedAt, "days") === 1) {
      return "Yesterday";
    } else {
      return updatedAt.format("MMM D");
    }
  };

  return (
    <FirstColumnContainer>
      {/* <div className="header">
        <span className="header-title">Ticket</span>
      </div> */}
      <span className="company-name">{ticket?.name}</span>
      {ticket?.closed_at == null ? (
        <span className="opened-time">
          Open: {formatTime(ticket?.created_at)}
        </span>
      ) : (
        <span className="opened-time">
          Closed: {formatTime(ticket?.closed_at)}
        </span>
      )}
      <StatusAndPipline refetchData={refetchData} pipelines={data.pipelines} ticket={ticket} />

      <AllTabs ticket={ticket} refetchData={refetchData} />
    </FirstColumnContainer>
  );
};

export default FirstColumn;
