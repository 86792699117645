import styled from "styled-components";

const Wrapper = styled.div`
  .custom-tooltip-container {
    position: relative;
  }

  .custom-tooltip {
    position: absolute;
    border-radius: 4px;
    background: var(--Dark-Gray-100, #2b3746);
    color: #fff;
    padding: 3px 11px; /* Adjust as needed */
    left: 50%; /* Adjust as needed */
    transform: translateX(-50%);
    z-index: 9999;
    font-size: 14px;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    font-family: Outfit;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* width: 63px; */
    height: 19px;
    color: #fbfcfc;
    /* text-transform: uppercase; */
  }

  .custom-tooltip-container:hover .custom-tooltip {
    display: block;
  }
`;
export { Wrapper };
