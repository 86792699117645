import styled from "styled-components";

const ThirdColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-right: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
  position: relative;
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background: white;
  padding-top: 20px;
  

  &::-webkit-scrollbar {
    display: none;
  }
  span {
    display: block;
  }
`;

export default ThirdColumnContainer;
