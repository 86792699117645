import React, { useState } from "react";
import {
  Container,
  HiddenCheckboxInput,
  StyledCheckbox,
  StyledCheckboxContainer,
} from "./styles";
import "./style.css";
import { Form, Select } from "antd";

const DefaultEndDate = ({ id }) => {
  const [value, setValue] = useState([]);

  const options = [
    {
      label: "Live answering Plan #1",
      value: 1,
    },
    {
      label: "Live answering Plan #2",
      value: 2,
    },
    {
      label: "Live answering Plan #3",
      value: 3,
    },
  ];

  return (
    <Container id={id} className="scroll-section">
      <p className="title">Live Answering Plan</p>
      <div className="form-container">
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Select Plan"
              name="live_answering_plan"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="Live Answering plan #1"
                className="location"
                value={value}
                style={{
                  width: "100% !important",
                }}
                onChange={setValue}
                options={options}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DefaultEndDate;
