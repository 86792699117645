import styled from "styled-components";

const Container = styled.div`
  height: calc(100% - 47px);

  .caller-details {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    padding: 0 15px;
    gap: 10px;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 20px;
    padding-top: 50px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }

    .label {
      font-family: Outfit;
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 700;
      color: #2b3746;
    }

    .caller-img {
      height: 189px !important;
      width: 189px !important;
      radius: 8px !important;
    }

    .number-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 12px;
      margin-top: 20px;
      img {
        height: 18px;
        width: 24px;
      }
      .number {
        font-family: Outfit;
        font-size: 32px;
        line-height: 40.32px;
        font-weight: 500;
        color: #2b3746;
      }
    }

    .auto-reply {
      width: 387px;
      border-radius: 8px;
      border: 1px solid #d0dee1;
      padding: 20px;
      ${"" /* gap: 3px; */}
      cursor: pointer;

      .title {
        font-family: Outfit;
        font-size: 14px;
        line-height: 16.8px;
        font-weight: 700;
        color: #2b3746;
        text-align: left !important;
        width: 100%;
        padding: 0;
        margin: 0;
      }
      .body {
        font-family: Outfit;
        font-size: 14px;
        line-height: 16.8px;
        font-weight: 400;
        color: #556373;
        text-align: left !important;
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }

    .btns {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 11px;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      .call-btn {
        width: 80.5px !important;
        height: 60.5px;
        border-radius: 8px;
        background: #4ccb60;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border: none !important;
        cursor: pointer;
      }
      .btn-style {
        width: 80.5px !important;
        height: 60.5px;
        border-radius: 8px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #dde8ea;
        cursor: pointer;
        color: #2b3746;
      }
      .btn-red {
        width: 80.5px !important;
        height: 60.5px;
        border-radius: 8px;
        background: #ff5733;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #dde8ea;
        cursor: pointer;
        color: white;
      }

      .dropdown {
        position: absolute;
        bottom: 70px;
        right: 0;
        background-color: white;
        border: 1px solid #dde8ea;
        border-radius: 8px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        width: 150px;
        z-index: 1;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        li {
          padding: 10px;
          cursor: pointer;
          font-family: Outfit;
          font-size: 14px;
          color: #2b3746;
        }

        li:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }
`;

export { Container };
