import React from 'react'
import { SubHeaderConatiner, BannerContainer } from './styles'
import NameAvatar from '../../../common/NameAvatar'
import GetLogo from '../../../components/getlogo/getlogo'
import SubscriptionOptionsDropdown from '../../../components/componyFlowComponents/subscriptionOptionsDropdown/SubscriptionOptionsDropdown'
import { Link, useNavigate } from 'react-router-dom'
import CustomTooltip from '../../../components/allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip'
import { Skeleton } from 'antd'
import StatusLabelButton from '../../../components/statusLabelButton/StatusLabelButton'
export default function CompanieFlowSubHeader({
  companyData,
  companyDataLoading,
  refetchCompnayData,
}) {
  const navigate = useNavigate()

  const handleContactNameClick = () => {
    if (companyData?.contact?.id) {
      navigate(`/users/${companyData.contact.id}`)
    }
  }

  const redirectToUrl = (dashboard_login_email) => {
    const baseUrl = 'https://clone.starthubcenters.com'
    const email = decodeURIComponent(dashboard_login_email)
    const params = `autologin=true&email=${email}`
    window.open(`${baseUrl}?${params}`, '_blank')
  }

  return (
    <BannerContainer>
      <div className="main-wrapper">
        <div className="user-detail">
          <div className="user">
            {companyDataLoading ? (
              <Skeleton.Avatar active size={36} shape="circle" />
            ) : companyData?.avatar ? (
              <img src={companyData?.avatar} alt="" className="user-img" />
            ) : (
              <NameAvatar
                userName={companyData?.name}
                val={18}
                fontSize={11}
              ></NameAvatar>
            )}
            <p className="user-name">
              {companyDataLoading ? (
                <Skeleton.Input active size={23} block="false" />
              ) : (
                companyData?.name
              )}
            </p>
            <p className="contact-name" onClick={handleContactNameClick}>
              {companyData?.contact?.name}
            </p>
          </div>
          <div className="btns">
            {companyData && (
              <>
                <StatusLabelButton Data={companyData}></StatusLabelButton>
                <CustomTooltip text={'Impersonate client'}>
                  <button
                    onClick={() =>
                      redirectToUrl(companyData?.dashboard_login_email)
                    }
                    className="impression-btn"
                  >
                    <span className="btn-icon">{GetLogo('eye')}</span>
                  </button>
                </CustomTooltip>
              </>
            )}
          </div>
          <div className="sub-options">
            {companyData && (
              <SubscriptionOptionsDropdown
                companyData={companyData}
                refetchCompnayData={refetchCompnayData}
              />
            )}
          </div>
        </div>
        {/* <div>
          <button className="save-btn">Save</button>
        </div> */}
      </div>
    </BannerContainer>
  )
}
