import { styled } from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--White-100, #fff);
  min-height: calc(100vh - 99px);
`

const LoginForm = styled.div`
  display: flex;
  padding: 40px 140px 40px 156px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 56px;
  flex: 1 0 0;
  align-self: stretch;

  .ant-form-item-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    label {
      color: var(--UI-Lighter, #d8dbe3);
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
      padding: 0;
    }
  }
  .titile {
    color: #2b3746;
    font-size: 48px;
    font-family: 'Outfit';
    font-weight: 600;
    line-height: 56px;
    word-wrap: break-word;
    letter-spacing: -0.68px;
    width: 432px;
    height: 112px;
  }
  .forget-title {
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px; /* 116.667% */
    letter-spacing: -1.68px;
    width: 432px;
    height: 168px;
  }
  .input-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    .forget-formItem {
      width: 320px;
      height: 59px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 0;
      position: relative;
      .forget-form-label {
        color: #d8dbe3 !important;
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
      .forget-input {
        width: 320px;
        height: 41px;
        padding: 12px;
        justify-content: space-between;
        outline: none;
        background-color: #fff !important;
        border-radius: 4px;
        border: 1px solid var(--UI-Lighter, #d8dbe3);
        color: #2b3746;
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
      .forget-input::placeholder {
        color: #2b3746;
      }
    }
    .forget-password {
      :hover {
        color: #4cbbcb;
      }
      width: 320px;
      height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .forget-label {
        color: var(--UI-Light, #7e838f);
        text-align: center;
        font-family: 'Outfit';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        line-height: 14.4px;
      }
    }
    .loginForm {
      width: 320px;
      height: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      .formItem {
        width: 320px;
        height: 59px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 0;
        position: relative;

        .form-label {
          color: #d8dbe3 !important;
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }

        .user-input {
          width: 320px;
          height: 41px;
          padding: 12px;
          justify-content: space-between;
          outline: none;
          background-color: #fff !important;
          border-radius: 4px;
          border: 1px solid var(--UI-Lighter, #d8dbe3);
          color: #2b3746;
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
        .user-input::placeholder {
          color: #2b3746;
        }

        .eye {
          position: absolute;
          right: 10px;
          top: 30px;
          svg {
            fill: #d8dbe3;
            width: 16px;
            height: 16px;
          }
        }
      }
      .reset-password {
        width: 320px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        .reset-label {
          color: var(--UI-Light, #7e838f);
          text-align: center;
          font-family: 'Outfit';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          cursor: pointer;
          line-height: 14.4px;
        }
      }
    }

    .login-btn {
      display: flex;
      width: 320px;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 4px;
      background: var(--Blue-100, #4cbbcb);
      border: none;
      outline: none;
      cursor: pointer;
      .login-label {
        color: var(--White-100, #fff);
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.32px;
      }
    }
  }
`

const ImgConatiner = styled.div`
  .forget-img-wrapper {
    display: flex;
    padding: 8px 140px;
    align-items: center;
    justify-content: center;

    .forget-img {
      width: 321.5px;
      height: 321.5px;
      object-fit: cover;
    }
  }
  .img-div {
    display: flex;
    padding: 8px 140px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    img {
      display: flex;
      width: 349.872px;
      height: 349.872px;
      justify-content: center;
      align-items: center;
    }
  }
`
const Arrow = styled.div``

const LoginFormContainer = styled.div`
  display: flex;
  padding: 40px 140px 40px 156px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 56px;
  flex: 1 0 0;
  align-self: stretch;

  .title {
    color: #2b3746;
    font-size: 48px;
    font-family: 'Outfit';
    font-weight: 600;
    line-height: 56px;
    word-wrap: break-word;
    letter-spacing: -0.68px;
    width: 432px;
    height: 112px;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    width: 320px;
    height: 268px;

    .inputs {
      display: flex;
      width: 320px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      height: 180px;

      .ant-form-item {
        height: 59px;
        margin-bottom: 0;
      }
      .ant-form-item .ant-form-item-label > label::after {
        content: '';
      }
      .ant-form-item-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        width: 320px;
        height: 59px;
        margin-bottom: 0;
        align-self: stretch;
        label {
          color: var(--UI-Lighter, #d8dbe3);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
          height: unset;
        }
      }

      .form-item {
        .user-input {
          width: 320px;
          height: 41px;
          padding: 12px;
          justify-content: space-between;
          outline: none;
          background-color: #fff !important;
          border-radius: 4px;
          border: 1px solid var(--UI-Lighter, #d8dbe3);
          color: #2b3746;
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          box-shadow: none;
          line-height: 120%;
        }
        .ant-input {
          color: #2b3746;
        }
        .ant-form-item-explain-error {
          font-size: 12px;
          font-family: Outfit;
        }
        .user-input::placeholder {
          color: #2b3746;
        }
        .custom-input-password input::placeholder {
          color: #2b3746;
        }

        input {
          color: #2b3746;
          font-weight: 400;
        }
        .eye {
          position: absolute;
          right: 10px;
          top: 13px;
          svg {
            fill: #d8dbe3;
            width: 16px;
            height: 16px;
          }
        }
      }

      .forget-password {
        height: 14px;
        width: 100%;
        display: flex;
        justify-content: center;
        P {
          color: var(--UI-Light, #7e838f);
          text-align: center;
          font-family: Outfit;
          font-size: 12px;
          align-self: stretch;
          font-style: normal;
          font-weight: 400;
          line-height: 0; /* 14.4px */
          cursor: pointer;
          width: fit-content;
        }
        p:hover {
          color: #4cbbcb;
        }
      }
    }

    .login-btn {
      display: flex;
      width: 320px;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 4px;
      background: var(--Blue-100, #4cbbcb);
      border: none;
      outline: none;
      cursor: pointer;
      .login-label {
        color: var(--White-100, #fff);
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.32px;
      }
    }
  }
`

export { Container, LoginForm, ImgConatiner, LoginFormContainer }
