import React from 'react'
import useGetData from '../../../hooks/useGetData'

function TicketBoardCards() {
  const { data: ticketBoardData, refetchData } = useGetData('support/tickets')
  const data = {
    lanes: [
      {
        id: 'QUALIFICATION',
        title: 'Qualification',
        label: '20/70',
        style: {
          background: 'rgba(241, 244, 245, 0.8)',
          fontSize: '14px',
          color: '#2b3746',
          fontFamily: 'Outfit',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: 'normal',
          letterSpacing: '-0.49px',
        },
        cards: [
          {
            id: 'Milk',
            title: 'Buy milk',
            label: '15 mins',
            description: '2 Gallons of milk at the Deli store',
          },
          {
            id: 'Plan2',
            title: 'Dispose Garbage',
            label: '10 mins',
            description: 'Sort out recyclable and waste as needed',
          },
          {
            id: 'Plan3',
            title: 'Write Blog',
            label: '30 mins',
            description: 'Can AI make memes?',
          },
          {
            id: 'Plan4',
            title: 'Pay Rent',
            label: '5 mins',
            description: 'Transfer to bank account',
          },
        ],
      },
      {
        id: 'NEEDS ANALYSIS',
        title: 'Needs Analysis',
        label: '10/20',
        style: {
          background: 'rgba(241, 244, 245, 0.8)',
          fontSize: '14px',
          color: '#2b3746',
          fontFamily: 'Outfit',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: 'normal',
          letterSpacing: '-0.49px',
        },
        cards: [
          {
            id: 'Wip1',
            title: 'Clean House',
            label: '30 mins',
            description:
              'Soap wash and polish floor, polish windows and doors.',
          },
        ],
      },
      {
        id: 'PROPOSAL',
        title: 'Proposal',
        label: '0/0',
        style: {
          width: '280px',
        },
        cards: [],
      },
      {
        id: 'NEGOTIATION',
        title: 'Negotiation',
        label: '2/5',
        style: {
          background: 'rgba(241, 244, 245, 0.8)',
          fontSize: '14px',
          color: '#2b3746',
          fontFamily: 'Outfit',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: 'normal',
          letterSpacing: '-0.49px',
        },
        cards: [
          {
            id: 'Completed1',
            title: 'Practice Meditation',
            label: '15 mins',
            description: 'Use Headspace app',
          },
          {
            id: 'Completed2',
            title: 'Maintain Daily Journal',
            label: '15 mins',
            description: 'Use Spreadsheet for now',
          },
        ],
      },
      {
        id: 'CLOSEDWON',
        title: 'Closed Won',
        label: '1/1',
        style: {
          background: 'rgba(241, 244, 245, 0.8)',
          fontSize: '14px',
          color: '#2b3746',
          fontFamily: 'Outfit',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: 'normal',
          letterSpacing: '-0.49px',
        },
        cards: [
          {
            id: 'Repeat1',
            title: 'Morning Jog',
            label: '30 mins',
            description: 'Track using Fitbit',
          },
        ],
      },
    ],
  }
  const mappedData = {
    lanes: ticketBoardData?.data?.map((stage) => ({
      id: stage.id.toString(),
      title: stage.title,
      label: `${stage.cards.length}/${stage.cards.length}`,
      style: {
        background: 'rgba(241, 244, 245, 0.8)',
        fontSize: '14px',
        color: '#2b3746',
        fontFamily: 'Outfit',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        letterSpacing: '-0.49px',
      },
      cards: stage.cards.map((card) => ({
        id: card.id.toString(),
        title: card.title,
        label: card.label,
        description: card.description,
      })),
    })),
  }
  return data
}

export default TicketBoardCards
