import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import './styles'
import { Form, Input, Radio, Space } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useSelector } from 'react-redux'
import { InstanceWithAuth } from '../../../../App'
import axios from 'axios'
import ReactTostify from '../../../../common/ReactTostify'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import useGetData from '../../../../hooks/useGetData'
import {
  Container,
  GoBack,
  ButtonContainer,
  CheckboxContainer,
  Details,
  Info,
  Label,
  Option,
  OptionContainer,
  SubText,
  Title,
} from './styles'

const ReActivePlanModal = ({
  isModalOpenReactive,
  handleCancelReactive,
  handleOk,
  reasonCodes,
  cancellationDate,
  refetchCompnayData,
  setReactiveingPlan,
  reactiveingPlan,
  companyData,
}) => {
  const [form] = Form.useForm()
  const [selectedReason, setSelectedReason] = useState(null)
  const { app, auth } = useSelector((state) => state)
  const [loading, setLoading] = useState(false)
  const { companyID } = useParams()
  const [selectedOption, setSelectedOption] = useState('resume')
  const [useServiceCredits, setUseServiceCredits] = useState(true)
  const { data, refetchData, error } = useGetData(
    `chargify/subscriptions/${companyData?.chargify_subscription_id}`
  )
  console.log(data, 'data...dasdsa')
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value)
  }

  const handleServiceCreditsChange = () => {
    setUseServiceCredits(!useServiceCredits)
  }

  useEffect(() => {
    if (reasonCodes.length > 0) {
      setSelectedReason(reasonCodes[0].reason_code.code)
    }
  }, [reasonCodes])

  const onChange = (e) => {
    console.log('radio checked', e.target.value)
    setSelectedReason(e.target.value)
  }

  const handleReactive = async () => {
    try {
      setReactiveingPlan(true)
      const formdata = new FormData()
      formdata.append('reactive_type', selectedOption)
      formdata.append(
        'use_service_credits',
        selectedOption == 'resume' ? useServiceCredits : ''
      )
      const response = await InstanceWithAuth.post(
        `companies/${companyID}/reactive-subscription`,

        formdata,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response?.status === 200) {
        handleCancelReactive()
        // setReactiveingPlan(false)
        refetchCompnayData()
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response?.data?.message,
          customClass: {
            confirmButton: 'custom-swal-button',
          },
        })
      }
    } catch (error) {
      setReactiveingPlan(false)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: 'custom-swal-button',
        },
      })
    }
  }

  function formatDate(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate)

    // Extract date components
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    // Extract time components
    const hours = String(date.getHours() % 12 || 12).padStart(2, '0') // Convert to 12-hour format
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const amPm = date.getHours() >= 12 ? 'PM' : 'AM'

    // Form the formatted date string
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}${amPm}`

    return formattedDate
  }

  return (
    <Modal
      closeIcon={true}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      open={isModalOpenReactive}
      onCancel={handleCancelReactive}
      onOk={handleOk}
      mask={true}
      width={630}
      className="custom-model-for-deactivation"
    >
      <Container>
        <Title>Reactivation Options</Title>
        <SubText>Select how you wish to handle this reactivation.</SubText>
        <OptionContainer>
          <Option
            onClick={() => handleOptionChange({ target: { value: 'start' } })}
          >
            <input
              type="radio"
              value="start"
              id="start"
              checked={selectedOption === 'start'}
              onChange={handleOptionChange}
            />
            <Label>Start New Billing Period</Label>
          </Option>
          {selectedOption === 'start' && (
            <Info>
              <p>
                Pay Today: ${data?.new_billing_amount} for {data?.product_name}
              </p>
              <p>Next Billing: 10/18/2024</p>
              <Details>
                <ul>
                  <li>This existing balance will be cleared.</li>
                  <li>The subscription will be immediately activated.</li>
                  <li>
                    The customer will be charged $78.90 for (Houston, TX)
                    Virtual Office + Access.
                  </li>
                  <li>The customer's billing date will be reset to today.</li>
                  <li>Service Credits and Prepayments will be applied.</li>
                  <li>
                    Currently, no email is sent to your customer. (
                    <a href="#">Send us feedback</a> if this is an option you
                    would like to see in Chargify.)
                  </li>
                  <li>
                    Any pending or scheduled cancellation will be cleared.
                  </li>
                  <li>
                    If the payment fails, this subscription will be canceled, no
                    matter its original state.
                  </li>
                  <li>
                    <strong>
                      There is no "undo" at this time, so please be sure before
                      you press the "Reactivate Subscription" button.
                    </strong>
                  </li>
                </ul>
              </Details>
            </Info>
          )}

          <Option
            onClick={() => handleOptionChange({ target: { value: 'resume' } })}
          >
            <input
              type="radio"
              value="resume"
              id="resume"
              checked={selectedOption === 'resume'}
              onChange={handleOptionChange}
            />
            <Label>Resume Billing Period</Label>
          </Option>
          {selectedOption === 'resume' && (
            <Info>
              <p>Pay Today: $0.00</p>
              <p>Next Billing: {data?.resume_billing_date}</p>
              <Details>
                <ul>
                  <li>The subscription will be immediately activated.</li>
                  <li>
                    Pending cancellation and scheduled cancellation are going to
                    be resolved.
                  </li>
                  <li>
                    If the payment fails, this subscription will be canceled.
                  </li>
                  <li>The customer will be charged $0.00 today.</li>
                  <li>
                    The customer's next billing date will be restored to
                    {data?.resume_billing_date}.
                  </li>
                </ul>
              </Details>
              <CheckboxContainer>
                <div
                  className="icon-and-check"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className="custom-input"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      type="checkbox"
                      id={`check-inv`}
                      checked={useServiceCredits}
                      onChange={handleServiceCreditsChange}
                    />
                    <label
                      className="label-check-box"
                      for={`check-inv`}
                    ></label>
                  </div>
                  <Label>Use Service Credits and Prepayments</Label>
                </div>
              </CheckboxContainer>
            </Info>
          )}
        </OptionContainer>

        <ButtonContainer>
          <button
            className="active-btn"
            onClick={handleReactive}
            disabled={reactiveingPlan ? true : false}
          >
            {reactiveingPlan
              ? 'Reactivating Subscription'
              : 'Reactivate Subscription'}
          </button>
          <GoBack onClick={handleCancelReactive}>Cancel</GoBack>
        </ButtonContainer>
      </Container>
    </Modal>
  )
}

export default ReActivePlanModal
