import styled from "styled-components";

const ChatInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .ql-toolbar.ql-snow {
    border: none;
    font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    padding: 8px 0px;
    margin-top: -0px !important;
  }

  .ql-editor {
    border: none;
  }

  .ql-snow * {
    border: none;
  }

  .quill-editor {
    height: 100%;
  }
  .quill-editor .ql-container {
    border: none;
  }

  .quill-editor .ql-toolbar {
    margin: 0;
  }

  .text-area-wrapper {
    height: auto;
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-right: 20px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const AttachmentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 0px 10px 14px 10px;
`;

const AttachmentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-right: 35px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f7f7f7;
  flex-basis: calc(50% - 10px);
  position: relative;
  color: var(--Gray-80, rgba(85, 99, 115, 0.8));
  text-align: center;
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 140px;
`;

const RemoveIcon = styled.div`
  margin-left: 10px;
  ${"" /* color: red; */}
  cursor: pointer;
  font-size: 15px;
  position: absolute;
  top: 5;
  bottom: 5;
  right: 4px;
`;

const Button = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4.3px 5.5px;
  background-color: white;
  border: 1px solid #e3eced;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 7px;

  svg {
    font-size: 14px;
    color: #2b3746;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 0px 20px;
  background-color: #f7f9fa;
  border: 1px solid #e3eced;
  border-radius: 5px;
  height: auto;
  max-height: 200px;
  width: 90%;

  .custom-toolbar {
    padding: 0;
    display: flex;
    gap: 10px;
    button {
      background-color: white;
      border: 1px solid #e3eced;
      border-radius: 4px;
      cursor: pointer;
      ${
        "" /* width: 40px;
      height: 30px; */
      }
    }
  }

  .send-button {
    margin-left: auto;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none;
    cursor: pointer;
    padding: 4px 15px;
    margin-left: 20px;
  }
`;

export {
  ChatInputContainer,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
  Button,
  ButtonsWrapper,
};
