import styled from 'styled-components'

const Conatiner = styled.div`
  height: 486px;
  margin-top: 40px;
  .title-main {
    display: flex;
    width: 200px;
    padding: 11px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 3px;

    .title {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 16px;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
      cursor: pointer;
    }
  }
  .active {
    background: var(--Gray-5, rgba(85, 99, 115, 0.05));
  }
`

export { Conatiner }
