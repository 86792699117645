import { Empty } from 'antd'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 5px;
  .ant-empty-description {
    color: var(--Gray-70, rgba(85, 99, 115, 0.7));
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
`

const DataNotFound = ({ label }) => {
  return (
    <Container className="no-dataFound">
      <Empty description={label}></Empty>
    </Container>
  )
}

export default DataNotFound
