import React, { useState, useRef, useEffect } from 'react'
import { MainContainer } from './styles' // Import CSS for styling
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
const BoardCustomDropDown = ({
  selectedStatusOption,
  setSelectedStatusOption,
  dropDownTitle = 'Selcet Option',
  filterData,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionSelect = (option) => {
    setSelectedStatusOption(option)
    setIsOpen(false)
  }

  return (
    <MainContainer>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="user-name">
            {dropDownTitle == 'Ticket Owner' && (
              <>
                {selectedStatusOption?.first_name
                  ? `${selectedStatusOption?.first_name} ${selectedStatusOption?.last_name}`
                  : selectedStatusOption?.name == 'all'
                  ? dropDownTitle
                  : dropDownTitle}
              </>
            )}
            {dropDownTitle != 'Ticket Owner' && (
              <>
                {selectedStatusOption && selectedStatusOption?.name !== 'all'
                  ? `${selectedStatusOption?.name}`
                  : dropDownTitle}
              </>
            )}
          </div>
          {isOpen ? (
            <MdOutlineKeyboardArrowDown fill="rgb(76, 187, 203)"></MdOutlineKeyboardArrowDown>
          ) : (
            <MdOutlineKeyboardArrowDown fill="rgba(85, 99, 115, 0.6)"></MdOutlineKeyboardArrowDown>
          )}
        </div>
        {isOpen && (
          <ul className="dropdown-list">
            <li onClick={() => handleOptionSelect({ name: 'all', id: '1' })}>
              All
            </li>
            {filterData ? (
              filterData.map((list) =>
                dropDownTitle == 'Ticket Owner' ? (
                  <li onClick={() => handleOptionSelect(list)}>
                    {`${list?.first_name} ${list?.last_name}`}
                  </li>
                ) : (
                  <li onClick={() => handleOptionSelect(list)}>{list?.name}</li>
                )
              )
            ) : (
              <>
                <li onClick={() => handleOptionSelect('Last 6 months')}>
                  Last 6 months
                </li>
              </>
            )}
          </ul>
        )}
      </div>
    </MainContainer>
  )
}

export default BoardCustomDropDown
