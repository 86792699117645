import styled from "styled-components";

const Container = styled.div`
  .account-collapase {
    border: none;
    .account-panel {
      border: none;
      max-width: 339px;
      width: 100%;
      .ant-collapse-header {
        display: flex;
        width: 339px;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        border-radius: 8px;
        background-color: ${(props) =>
          props.open
            ? "var(--Blue-100, #4cbbcb)"
            : "var(--Gray-5, rgba(85, 99, 115, 0.05))"};

        .ant-collapse-header-text {
          color: ${(props) =>
            props.open
              ? "var(--White-100, #fff)"
              : "var(--Dark-Gray-100, #2B3746)"};
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.49px;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: ${(props) =>
                props.open
                  ? "var(--White-100, #fff)"
                  : "var(--Dark-Gray-100, #2B3746)"};
            }
          }
        }
      }
      .ant-collapse-content {
        border: none;
      }
    }
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
    padding-top: 7px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 339px;
  flex-direction: column;
  align-items: center;
  .top-div {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    display: flex;
    flex-direction: row;
    .id {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .right-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      .view-btn {
        display: flex;
        cursor: pointer;
        padding: 6px 12px;
        align-items: center;
        gap: 12px;
        border-radius: 4px;
        border: 1px solid #d0dee1;
        background: #fff;
        margin-top: 0px;

        .btn-text {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
        }
      }
    }
  }

  .form-main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 5px;

    .button-div {
      display: flex;
      justify-content: end;
      margin-top: 9px;
      gap: 15px;
      ${"" /* padding-right: 35px; */}
      .close-button {
        display: flex;
        height: 32px;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
        outline: none;
        border-radius: 3px;
        border: 1px solid #d0dee1;
        background: #fff;
        cursor: pointer;
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
      button {
        display: flex;
        height: 32px;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
        outline: none;
        border: none;
        gap: 8px;
        border-radius: 3px;
        background: var(--Blue-100, #4cbbcb);
        cursor: pointer;
        color: var(--White-100, #fff);
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
    }
  }

  .form-item {
    width: 100% !important;
    color: var(--Dark-Gray-100, #2b3746);
    font-family: Outfit;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.63px;
    margin-top: 10px;

    .ant-form-item-control {
      width: 100%;
    }

    .ant-form-item {
      width: 100% !important; /* Ensure each form item takes full width */
      margin-bottom: 0;
    }

    .ant-form-item-row {
      width: 100%; /* Ensure the form item row takes full width */
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start;
      gap: 4px;
    }

    label {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      height: auto;
    }

    .location {
      align-items: center;
      width: 330px;
      height: 41px;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      height: auto;
      min-height: 42px;
      padding-top: 4px;
      padding-right: 15px !important;

      .ant-select-selector {
        border: none;
        outline: none;
        box-shadow: none;
        .ant-select-selection.ant-select-selection--single {
          background: green;
        }
      }
    }
    .location::placeholder {
      color: var(--Gray-40, rgba(85, 99, 115, 0.4));
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
    }
    .location:focus::placeholder {
      color: transparent;
    }
    .ant-select-selection-item {
      ${
        "" /* color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; */
      }
    }
  }

  .welcome-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    height: 97px;
    margin-top: 10px;
    width: 100%;
    align-self: stretch;
    .form-items {
      margin-bottom: 0;
      height: 64px;
    }

    .input {
      display: flex;
      height: 85px;
      padding: 12px;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid #d0dee1;
      width: 330px;
      background: #fff;
      resize: none;
    }

    .input::placeholder {
      color: var(--Gray-70, rgba(85, 99, 115, 0.7));
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
    }
    .input:focus::placeholder {
      color: transparent;
    }
    .input:focus {
      box-shadow: none;
    }
    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input {
      border-color: unset;
      border: 1px solid #d0dee1;
      box-shadow: none;
    }
    .ant-form-item-explain-error {
      font-size: 10px;
      font-weight: 400;
      font-family: Outfit;
    }
    .ant-form-item .ant-form-item-label > label::after {
      content: "";
    }
    .ant-form-item-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      width: 100%;
      align-self: stretch;

      label {
        height: unset;
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        align-self: stretch;
      }
    }
  }

  span {
    color: var(--Gray-100, #556373);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  button {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none;
    cursor: pointer;
    margin-top: 10px;
  }

  .tickets {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 7px;
    padding: 0;
    margin: 0;

    .ticket {
      border-radius: 8px;
      border: 1px solid #d0dee1;
      background: #fff;
      width: 100%;
      padding: 15px;
      margin: 0;
      .name {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      .des {
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: var(--Gray-100, #556373);
      }
      .make-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        .left {
          display: flex;
          align-items: center;
          gap: 5px;
          .priority-label {
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            color: var(--Gray-100, #556373);
          }
          .priority {
            color: var(--Dark-Gray-100, #2b3746);
            font-family: Outfit;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }

        .right {
          display: flex;
          align-items: center;
          gap: 5px;

          .active {
            display: flex;
            padding: 6px 12px;
            align-items: center;
            gap: 10px;
            /* cursor: pointer; */
            border-radius: 4px;
            border: 1px solid var(--Green-70, rgba(76, 203, 96, 0.7));
            background: rgba(76, 203, 96, 0.2);
            .icon {
              svg {
                width: 8px;
                height: 8px;
              }
            }

            .text {
              color: var(--Dark-Gray-100, #2b3746);
              font-family: Outfit;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 14.4px */
            }
          }
          .in-active {
            display: flex;
            padding: 6px 8px;
            align-items: center;
            gap: 10px;
            /* cursor: pointer; */
            border-radius: 4px;
            border: 1px solid #ff7b7b;
            background: #ffbaba;
            .icon {
              svg {
                width: 8px;
                height: 8px;
                fill: #ff7b7b;
                fill-opacity: unset;
                circle {
                  fill: #ff7b7b;
                  fill-opacity: unset;
                  stroke: #ff7b7b;
                }
              }
            }

            .text {
              color: var(--Dark-Gray-100, #2b3746);
              font-family: Outfit;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 14.4px */
            }
          }
        }
      }
    }
  }
`;

export { Container, ContentContainer };
