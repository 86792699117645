import styled from "styled-components";

const FourthColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100% -50px);

  .header {
    position: absolute;
    top: 39px;
    right: 0;
    width: 500px;
    background: white;
    z-index: 1;
    border-bottom: 1px solid #e3eced;
  }

  span {
    display: block;
  }
`;

export default FourthColumnContainer;
