import React, { useState, useRef, useEffect } from 'react'
import { MainContainer } from './styles' // Import CSS for styling
import { useNavigate, useParams } from 'react-router-dom'
import InitiateCanceliation from '../../modals/subscriptionModels/InitiateCanceliation/InitiateCanceliation'
import GetDeductCredits from '../../modals/subscriptionModels/getDeductCredits/GetDeductCredits'
import PutOnHold from '../../modals/subscriptionModels/putOnHold/PutOnHold'
import { useSelector } from 'react-redux'
import ReactTostify from '../../../common/ReactTostify'
import { InstanceWithAuth } from '../../../App'
import useGetData from '../../../hooks/useGetData'
import Swal from 'sweetalert2'
import Spinner from '../../../common/Spinner'
import ReActivePlanModal from '../../modals/subscriptionModels/reActivePlanModa/ReActivePlanModal'
import AssumePlanModal from '../../modals/subscriptionModels/AssumePlanModal/AssumePlanModal'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
const SubscriptionOptionsDropdown = ({ companyData, refetchCompnayData }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenCreditModel, setIsModalOpenCreditModel] = useState(false)
  const [isModalOpenHold, setIsModalOpenHold] = useState(false)
  const { companyId } = useParams()
  const { companyID } = useParams()
  const [currentCreditd, setCurrentCredits] = useState(null)
  const [reasonCodes, setReasonCodes] = useState([])
  const [cancellationDate, setCancellationDate] = useState(null)
  const [resumePlan, setResumePlan] = useState(false)
  const [craetingInvoice, setCreatingInvoice] = useState(false)
  const [isModalOpenReactive, setIsModalOpenReactive] = useState(false)
  const [isModalOpenAssumePlan, setIsModalOpenAssumePlan] = useState(false)
  const [reactiveingPlan, setReactiveingPlan] = useState(false)

  const { app, auth } = useSelector((state) => state)

  const showModalCreditModel = async () => {
    setIsModalOpenCreditModel(true)
    try {
      const response = await InstanceWithAuth.get(
        `https://crm-stage.starthubcenters.com/api/companies/${companyID}/account-balance`,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      )
      if (response.status == 200 || response.status == 201) {
        setCurrentCredits(response?.data)
      }
    } catch (error) {
      ReactTostify('error', error?.response?.data?.message)
    }
  }
  const handleOkCreditModel = () => {
    setIsModalOpen(false)
  }
  const handleCancelCreditModel = () => {
    setIsModalOpenCreditModel(false)
  }

  const showModal = async () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const showModalHold = () => {
    setIsModalOpenHold(true)
  }
  const handleOkHold = () => {
    setIsModalOpenHold(false)
  }
  const handleCancelHold = () => {
    setIsModalOpenHold(false)
  }
  const handleOpenReactive = () => {
    setIsModalOpenReactive(true)
  }
  const handleCancelReactive = () => {
    setIsModalOpenReactive(false)
  }

  const handleOpenAssume = () => {
    setIsModalOpenAssumePlan(true)
  }
  const handleCancelAssume = () => {
    setIsModalOpenAssumePlan(false)
  }

  const navigate = useNavigate()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionSelect = (option) => {
    setIsOpen(false)
    switch (option) {
      case 'Give/Deduct Service Credits':
        showModalCreditModel()
        break
      case 'Inssue Invoice':
        navigate(`/companie/create-invoice/${companyID}`)
        break
      case 'Put On Hold':
        showModalHold()
        break
      case 'Initiate Canceliation':
        showModal()
        break
      case 'Reactive plan':
        handleOpenReactive()
        break
      case 'Resume modal':
        handleOpenAssume()
        break
      default:
        break
    }
  }

  useEffect(() => {
    const getCancelationCodes = async () => {
      try {
        const response = await InstanceWithAuth.get(
          `https://crm-stage.starthubcenters.com/api/chargify/reason_codes`,
          {
            headers: {
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        )
        if (response.status == 200 || response.status == 201) {
          setReasonCodes(response?.data.data)
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error?.response?.data?.message,
          customClass: {
            confirmButton: 'custom-swal-button',
          },
        })
      }
    }
    getCancelationCodes()
  }, [])

  useEffect(() => {
    const getCancelationDate = async () => {
      try {
        const response = await InstanceWithAuth.get(
          `https://crm-stage.starthubcenters.com/api/chargify/subscriptions/${companyData.chargify_subscription_id}`,
          {
            headers: {
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        )
        if (response.status == 200 || response.status == 201) {
          setCancellationDate(response?.data)
        }
      } catch (error) {}
    }
    getCancelationDate()
  }, [companyData])
  const handleNavigate = async () => {
    try {
      setCreatingInvoice(true)
      const formdata = new FormData()
      const response = await InstanceWithAuth.post(
        `https://crm-stage.starthubcenters.com/api/chargify/draft-invoices/${companyID}`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response?.status === 200) {
        setCreatingInvoice(false)
        navigate(
          `/companie/create-invoice/${companyID}/${response.data.invoice.id}`,
          {
            state: { fromButton: 'create' },
          }
        )
      }
    } catch (error) {
      setCreatingInvoice(false)
    }
  }
  console.log(companyData, 'companyData in dropdown')
  return (
    <MainContainer>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="user-name">Subscription Options</div>
          {isOpen ? (
            <MdOutlineKeyboardArrowDown fill="rgb(76, 187, 203)"></MdOutlineKeyboardArrowDown>
          ) : (
            <MdOutlineKeyboardArrowDown fill="#789ca2"></MdOutlineKeyboardArrowDown>
          )}
        </div>

        {isOpen && (
          <ul className="dropdown-list">
            <li
              onClick={() => handleOptionSelect('Give/Deduct Service Credits')}
            >
              Give/Deduct Service Credits
            </li>
            <li
              onClick={handleNavigate}
              style={{
                justifyContent: craetingInvoice ? 'center' : 'left',
              }}
            >
              {craetingInvoice ? (
                <Spinner width={10.5}></Spinner>
              ) : (
                'Issue Invoice'
              )}
            </li>
            {companyData?.status == 0 &&
            companyData?.status_label == 'On Hold' ? (
              <li onClick={() => handleOptionSelect('Resume modal')}>Resume</li>
            ) : null}
            {companyData?.status == 0 &&
            companyData?.status_label == 'Cancelled' ? (
              <li onClick={() => handleOptionSelect('Reactive plan')}>
                Re Active
              </li>
            ) : null}

            {companyData?.status == 1 &&
            companyData?.status_label == 'Active' ? (
              <li onClick={() => handleOptionSelect('Put On Hold')}>
                Put On Hold
              </li>
            ) : null}

            {companyData?.status == 1 &&
            companyData?.status_lable == 'Active' ? (
              <li onClick={() => handleOptionSelect('Initiate Canceliation')}>
                Initiate Cancellation
              </li>
            ) : null}
            {companyData?.status == 1 &&
            companyData?.status_label == 'Past Due' ? (
              <li onClick={() => handleOptionSelect('Initiate Canceliation')}>
                Initiate Cancellation
              </li>
            ) : null}
            {companyData?.status == 1 &&
            companyData?.status_label == 'Active' ? (
              <li onClick={() => handleOptionSelect('Initiate Canceliation')}>
                Initiate Cancellation
              </li>
            ) : null}
          </ul>
        )}
      </div>

      <InitiateCanceliation
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        reasonCodes={reasonCodes}
        cancellationDate={cancellationDate}
        refetchCompnayData={refetchCompnayData}
      />
      <GetDeductCredits
        isModalOpenCreditModel={isModalOpenCreditModel}
        handleCancelCreditModel={handleCancelCreditModel}
        handleOkCreditModel={handleOkCreditModel}
        currentCreditd={currentCreditd}
        setCurrentCredits={setCurrentCredits}
        refetchCompnayData={refetchCompnayData}
      />
      <PutOnHold
        isModalOpenHold={isModalOpenHold}
        handleCancelHold={handleCancelHold}
        handleOkHold={handleOkHold}
        cancellationDate={cancellationDate}
        refetchCompnayData={refetchCompnayData}
      />
      <ReActivePlanModal
        isModalOpenReactive={isModalOpenReactive}
        handleCancelReactive={handleCancelReactive}
        handleOk={handleOkHold}
        reasonCodes={reasonCodes}
        cancellationDate={cancellationDate}
        refetchCompnayData={refetchCompnayData}
        setReactiveingPlan={setReactiveingPlan}
        reactiveingPlan={reactiveingPlan}
        companyData={companyData}
      />
      <AssumePlanModal
        isModalOpenAssumePlan={isModalOpenAssumePlan}
        handleCancel={handleCancelAssume}
        handleOk={handleOkHold}
        reasonCodes={reasonCodes}
        cancellationDate={cancellationDate}
        refetchCompnayData={refetchCompnayData}
        setResumePlan={setResumePlan}
        auth={auth}
        companyID={companyID}
        companyData={companyData}
        resumePlan={resumePlan}
      />
    </MainContainer>
  )
}

export default SubscriptionOptionsDropdown
