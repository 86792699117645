import styled from "styled-components";

const PageContainer = styled.div`
  margin-top: 100px;
  display: flex;
  /* width: 1373px; */
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  background: var(--White-100, #fff);

  .charts-div {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 24px;
    height: 295px;
    .graph-div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      background: var(--White-100, #fff);
      height: 295px;
    }

    .card-div {
      height: 295px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      background: var(--White-100, #fff);
      background: var(--White-100, #fff);
    }
  }
  .table-div {
    width: 100%;
  }
`;
const TabsContainer = styled.div`
  margin-top: 99px;
`;

export { PageContainer, TabsContainer };
