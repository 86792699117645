import { useState, useEffect } from 'react'
import { InstanceWithAuth } from '../App'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'

const useGetData = (url, initialData = null, showErrorMSg) => {
  const [data, setData] = useState(initialData)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { auth } = useSelector((state) => state)
  const fetchData = async (postData) => {
    try {
      setLoading(true)
      const response = await InstanceWithAuth.get(
        `${process.env.REACT_APP_API_BASE_URL}/${url}`,
        {
          headers:{
            Authorization:`Bearer ${auth?.userObj?.api_token}`, 
          },
        }
      )
      setData(response.data)
    } catch (error) {
      setError(error)
      if (showErrorMSg) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error?.response?.data?.message,
          customClass: {
            confirmButton: 'custom-swal-button',
          },
        })
      }
    } finally {
      setLoading(false)
    }
  }
  const refetchData = () => {
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [url])
  return { data, loading, error, fetchData, refetchData }
}

export default useGetData
