import React, { useState } from 'react'
import { Wrapper } from './styles.js'

const CustomTooltip = ({ text, children, marginTop = '-12px' }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1)
  }
  return (
    <Wrapper>
      <span
        className="custom-tooltip-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
        {showTooltip && (
          <div style={{ top: `${marginTop}` }} className="custom-tooltip">
            {capitalizeFirstLetter(text)}
          </div>
        )}
      </span>
    </Wrapper>
  )
}

export default CustomTooltip
