import styled from 'styled-components'

const FirstColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 290px;
  height: 100%;
  border-right: 1px solid #e3eced;
  padding: 10px 10px;

  .button {
    ${'' /* width: 170px; */}
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: green;
    color: white;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none;
    cursor: pointer;
  }
  .bottom-section {
    display: flex;
    flex-direction: column;
    height: 100%;

    .most-outer-div:hover {
      background: #f3f6f7;
    }
    .most-outer-div {
      background: #f3f6f7;
      width: 100%;
      height: auto;
      min-height: 45px;
      margin-top: 7px;
      border-radius: 8px;
      overflow-y: auto;
      .header-div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        min-height: 45px;
        padding: 0 10px;
        gap: 3px;

        .left {
          display: flex;
          align-items: center;
        }

        .icon {
          cursor: pointer;
          padding: 4px 7px;
          border-radius: 50px;
          align-items: center;
          justify-content: center;
          margin-top: 4px;
        }
        .icon:hover {
          background: var(--Blue-20, rgba(76, 187, 203, 0.2));
          box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
        }
        .header-label {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .list-container {
        padding: 5px 7px;
      }
    }
    .most-outer-div-spaces:hover {
      background: #f3f6f7;
    }
    .most-outer-div-spaces {
      background: #f3f6f7;
      width: 100%;
      height: auto;
      min-height: 45px;
      margin-top: 7px;
      border-radius: 8px;
      overflow-y: auto;
      .header-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 45px;
        padding: 0 10px;
        .left {
          display: flex;
          align-items: center;
          gap: 3px;
        }

        .icon {
          cursor: pointer;
          padding: 4px 7px;
          border-radius: 50px;
          align-items: center;
          justify-content: center;
          margin-top: 4px;
        }
        .icon:hover {
          background: var(--Blue-20, rgba(76, 187, 203, 0.2));
          box-shadow: 0px 0px 10px 0px rgba(86, 191, 207, 0.24);
        }
        .header-label {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .list-container {
        padding: 5px 7px;
      }
    }
  }
`

export { FirstColumnContainer }
