import React from "react";
import ThirdColumnContainer from "./styles";
import UserDetailSidebar from "../../userList/userDetailSidebar/UserDetailSidebar";
import SupportHeader from "../../support/FourthColumn/ticketDetails/supportHeader/SupportHeaderr";

const ThirdColumn = ({ ticket }) => {
  return (
    <ThirdColumnContainer>
      <SupportHeader userID={ticket?.contact_id} fromTicket={true}/>
      <UserDetailSidebar
        open={true}
        canHide={false}
        supportHeader={true}
        userID={ticket?.contact_id}
        // additionalTabs={true}
        // selectedEmailId={4}
      />
    </ThirdColumnContainer>
  );
};

export default ThirdColumn;
