import axios from "axios";

const axiosGeneral= axios.create({
  baseURL: process.env.REACT_APP_CALL_CENTER_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    appVersion: "1.0",
  },
});

export default axiosGeneral;
