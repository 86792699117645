import React, { useEffect, useRef, useState } from "react";
import {
  ButtonsWrapper,
  Button,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
  ChatInputContainer,
} from "./styles";
import { IoReturnUpForward, IoClose } from "react-icons/io5";
import { FiPaperclip, FiSend } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import { Select } from "antd";
import { Quill } from "react-quill";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardArrowDown, MdSend } from "react-icons/md";

Quill.register("modules/emoji", Emoji);

const ChatInput = ({}) => {
  const [value, setValue] = useState("");
  const fileInputRef = useRef(null);

  const handleEditorChange = (content) => {
    setValue(content);
  };

  return (
    <ChatInputContainer>
      {/* Attachment List */}
      {/* <AttachmentList>
        {selectedFiles.map((file, index) => (
          <AttachmentItem key={index}>
            <TruncateText text={file.name} />
            <span className="file-size">
              ({(file.size / 1024).toFixed(1)}kb)
            </span>
            <RemoveIcon onClick={() => handleRemoveFile(index)}>
              <IoClose />
            </RemoveIcon>
          </AttachmentItem>
        ))}
      </AttachmentList> */}

      {/* Buttons */}
      <ButtonsWrapper>
        <div className="text-area-wrapper">
          <ReactQuill
            name="message"
            className="quill-editor"
            theme="snow"
            value={value}
            onChange={handleEditorChange}
            placeholder="Type your message here..."
            modules={{
              toolbar: {
                container: "#quill-toolbar",
                "emoji-toolbar": true,
                "emoji-textarea": true,
                "emoji-shortname": true,
              },
            }}
          />
        </div>
        <div id="quill-toolbar" className="custom-toolbar">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          {/* <button className="ql-list" value="ordered"></button> */}
          {/* <button className="ql-list" value="bullet"></button> */}
          <button className="ql-link"></button>
        </div>

        <Button
        // onClick={handleFileClick}
        >
          <FiPaperclip />
          <input
            ref={fileInputRef}
            type="file"
            // onChange={handleFileChange}
            style={{ display: "none" }}
            multiple
          />
        </Button>

        <Button
          // onClick={handleSend}
          className="send-button"
          // disabled={sendingMail}
        >
          <MdSend  color="white" size={20} />
        </Button>
      </ButtonsWrapper>
    </ChatInputContainer>
  );
};

export default ChatInput;
