import React from 'react'
import { Navbar, StartHubLogo } from './style'
import startHubLogo from '../../assets/starthubLogo.png'

export default function AuthHeader() {
  return (
    <Navbar>
      <StartHubLogo src={startHubLogo} alt="StartHubLogo" />
    </Navbar>
  )
}
