import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  font-family: Outfit;
  font-weight: 400;
`

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
  font-weight: 400;
`

const Section = styled.div`
  margin-bottom: 20px;
  font-weight: 400;
`

const Subtitle = styled.h3`
  margin-bottom: 10px;
  font-size: 16px;
  color: #666;
  font-weight: 400;
`

const Text = styled.div`
  color: #333;
`

const BulletPoint = styled.p`
  margin-left: 20px;
  position: relative;
  padding-left: 10px;
  &::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #333;
  }
`

const Status = styled.span`
  font-weight: 400;
  color: ${({ onHold, active }) =>
    onHold ? '#2b3746' : active ? '#2b3746' : '#333'};
  background-color: ${({ onHold, active }) =>
    onHold ? '#ffbaba' : active ? '#d4edda' : 'transparent'};
  padding: 2px 5px;
  border-radius: 5px;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: end;
  .resume-btn {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    width: 153px;
    border: none;
    cursor: pointer;
  }
`

const CancelButton = styled.a`
  text-decoration: none;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Blue-100, #fff);
  color: var(--White-100, #fff);
  color: var(--Dark-Gray-100, #2b3746);
  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: 1px solid #d0dee1;
  cursor: pointer;
`
export {
  CancelButton,
  ButtonContainer,
  Status,
  BulletPoint,
  Text,
  Subtitle,
  Section,
  Title,
  Container,
}
