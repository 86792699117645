import React, { useEffect, useState } from "react";
import "./styles";
import { Container } from "./styles";
import { Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";

const DetailForm = ({ id , settingsData, handleFileChange}) => {
  const [value, setValue] = useState([]);
  const [voicemailValue, setvoicemailValue] = useState("");

  useEffect(() => {
   if(settingsData?.voicemail_option){
    setvoicemailValue(settingsData?.voicemail_option)
   }
  }, [settingsData])
  

  const options = [
    {
      label: "Forword calls to me",
      value: "forword_to_client",
    },
    {
      label: "Forword to voicemail",
      value: "forword_to_voicemail",
    },
  ];

  const optionsMonths = [
    {
      label: "January",
      value: "january",
    },
    {
      label: "February",
      value: "february",
    },
    {
      label: "March",
      value: "march",
    },
    {
      label: "April",
      value: "april",
    },
    {
      label: "May",
      value: "may",
    },
    {
      label: "June",
      value: "june",
    },
    {
      label: "July",
      value: "july",
    },
    {
      label: "August",
      value: "august",
    },
    {
      label: "September",
      value: "september",
    },
    {
      label: "October",
      value: "october",
    },
    {
      label: "November",
      value: "november",
    },
    {
      label: "December",
      value: "december",
    },
  ];


  const voicemailOptions = [
    {
      label: "Use Starthub’s Generic Voicemail",
      value: "use_default_voicemail_audio",
    },
    {
      label: "⁠Use your own file",
      value: "use_my_own_voicemail_audio",
    },
    {
      label: "⁠Get a professional custom voice Recording",
      value: "use_custom_professional_voicemail_script",
    },
  ];

  return (
    <Container id={id} className="scroll-section">
      <div className="title">General</div>
      <div className="form-container">

        <div className="form-main">
            <div className="form"> 
              <Form.Item
                label="Greeting Company’s Name"
                name="greeting_company_name"
                className="form-items"
              >
                <Input className="input" type="text" placeholder="" />
              </Form.Item>
            </div>
        </div>

        <div className="form-main">
            <div className="form text-area"> 
              <Form.Item
                label="Greeting Message"
                name="greeting_message"
                className="form-items"
              >
                <Input className="input" type="text" placeholder="" />
              </Form.Item>
            </div>
        </div>
        <div className="form-main">
            <div className="form text-area"> 
              <Form.Item
                label="Short Business Description"
                name="short_business_description"
                className="form-items"
              >
                <Input className="input" type="text" placeholder="" />
              </Form.Item>
            </div>
        </div>

        <div className="form-main">
            <div className="form text-area"> 
              <Form.Item
                label="Notification Email"
                name="notification_email"
                className="form-items"
              >
                <Input className="input" type="text" placeholder="" />
              </Form.Item>
            </div>
        </div>

        <div className="form-main">
            <div className="form text-area"> 
              <Form.Item
                label="Virtual Receptionist Special Instruction"
                name="receptionist_special_instruction"
                className="form-items"
              >
                <Input className="input" type="text" placeholder="" />
              </Form.Item>
            </div>
        </div>



        <div className="form-main">
          <div className="form">
            <Form.Item
              label="During weekdays"
              name="during_weekdays"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="During weekdays"
                className="location"
                value={value}
                style={{
                  width: "100% !important",
                }}
                onChange={setValue}
                options={options}
              />
            </Form.Item>
          </div>
        </div>
        <div className="row">                    
          <div className="form-main">
            <div className="form"> 
              <Form.Item
                label="Weekdays Time From"
                name="from_hour"
                className="form-items"
              >
                <Input className="input" type="time" placeholder="" />
              </Form.Item>
            </div>
          </div>
          <div className="form-main">
            <div className="form">            
              <Form.Item
                label="Weekdays Time To"
                name="to_hour"
                className="form-items"
              >
                <Input className="input" type="time" placeholder="" />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="form-main">
          <div className="form">
            <Form.Item
              label="After weekdays timing"
              name="after_weekdays_timing"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="After weekdays timing"
                className="location"
                value={value}
                style={{
                  width: "100% !important",
                }}
                onChange={setValue}
                options={options}
              />
            </Form.Item>
          </div>
        </div>

        <div className="form-main">
          <div className="form">
            <Form.Item
              label="During weekend"
              name="during_weekend"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="During weekend"
                className="location"
                value={value}
                style={{
                  width: "100% !important",
                }}
                onChange={setValue}
                options={options}
              />
            </Form.Item>
          </div>
        </div>

        <div className="form-main">
          <div className="form">
            <Form.Item
              label="On Public holidays"
              name="public_holidays"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="On Public holidays"
                className="location"
                value={value}
                style={{
                  width: "100% !important",
                }}
                onChange={setValue}
                options={options}
              />
            </Form.Item>
          </div>
        </div>

        <div className="row">
          <div className="form-main">
            <div className="form">
              <Form.Item
                label="Private Holiday Month"
                name="month"
                className="form-items"
              >
                <Select
                  showSearch
                  placeholder="Private Holiday"
                  className="location"
                  value={value}
                  style={{
                    width: "100% !important",
                  }}
                  onChange={setValue}
                  options={optionsMonths}
                />
              </Form.Item>
            </div>
          </div>                 
          <div className="form-main">
            <div className="form"> 
              <Form.Item
                label="Private Holiday From date"
                name="from_date"
                className="form-items"
              >
                <Input className="input" type="date" placeholder="" />
              </Form.Item>
            </div>
          </div>
          <div className="form-main">
            <div className="form">            
              <Form.Item
                label="Private Holiday To Date"
                name="to_date"
                className="form-items"
              >
                <Input className="input" type="date" placeholder="" />
              </Form.Item>
            </div>
          </div>
        </div>



        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Voicemail Option"
              name="voicemail_option"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="Voicemail Option"
                className="location"
                value={voicemailValue}
                style={{
                  width: "100% !important",
                }}
                onChange={setvoicemailValue}
                options={voicemailOptions}
              />
            </Form.Item>
          </div>
        </div>     

        { voicemailValue == "use_my_own_voicemail_audio" &&<div>
          {settingsData?.custom_voicemail_file != '' && <audio controls src={`http://localhost:3001/${settingsData?.custom_voicemail_file}`}/> }           
          </div> }
        
        { voicemailValue == "use_my_own_voicemail_audio" &&  <div className="form-main"><div className="form text-area"> 
          <Form.Item
            label="Custom voicemail file"
            name="custom_voicemail_file_input"
            className="form-items"
          >
            
            <div>
            <Input className="input" type="file" accept="audio/*" onChange={handleFileChange} />
            </div>
          </Form.Item>
        </div></div>}


        { voicemailValue == "use_custom_professional_voicemail_script" && <div className="form-main">
            <div className="form text-area"> 
              <Form.Item
                label="Custom voicemail script"
                name="custom_voicemail_script"
                className="form-items"
              >
                <Input className="input" type="text" placeholder="" />
              </Form.Item>
            </div>
        </div>}
        <div style={{margin:"50px"}}>&nbsp;</div>

      </div>
    </Container>
  );
};

export default DetailForm;
