import React, { useState, useEffect } from "react";
import { Form, Modal, Select, Spin, Avatar } from "antd";
import { RxCross2 } from "react-icons/rx";
import { Container, MainConatiner } from "./styles";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../../App";

const { Option } = Select;

const AddMembersModel = ({ isModelOpen, handleCancel, handleOk }) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { auth } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(true);

  const getUserList = async () => {
    try {
      setLoading(true);
      const response = await InstanceWithAuth.get(`users`, {
        headers: {
          Authorization: `Bearer ${auth?.userObj?.api_token}`,
        },
      });
      setUsers(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  const handleSelectChange = (selectedUsers) => {
    console.log("Selected Users:", selectedUsers);
  };

  return (
    <Modal
      closeIcon={<RxCross2 />}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      open={isModelOpen}
      onCancel={handleCancel}
      mask={true}
      width={630}
      className="custom-model"
    >
      <Form className="main-form" form={form} requiredMark={false}>
        <MainConatiner>
          <Container>
            <div className="title-main">
              <span className="title">Add people to "Text Space"</span>
            </div>
            <div className="form-container">
              <div className="form-main">
                <div className="form">
                  <Form.Item
                    name="members"
                    label="Members"
                    className="form-items"
                  >
                    <Select
                      mode="multiple"
                      className="location"
                      showSearch
                      placeholder="Search for people here..."
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.props.children[1]
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={loading}
                      onChange={handleSelectChange}
                      // open={dropdownOpen}
                      // onDropdownVisibleChange={(open) => setDropdownOpen(true)}
                    >
                      {users.map((user) => (
                        <Option key={user.id} value={user.id}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar
                              src={
                                user.avatar || "https://via.placeholder.com/40"
                              }
                              alt={user.first_name}
                              size="small"
                              style={{ marginRight: 8 }}
                            />
                            {user.first_name} {user.last_name}
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Container>

          <div className="button-div">
            <button
              className="close-button"
              type="button"
              onClick={handleCancel}
            >
              Close
            </button>
            <button className="save-button" type="button" disabled={isSaving}>
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </MainConatiner>
      </Form>
    </Modal>
  );
};

export default AddMembersModel;
