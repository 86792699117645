import React, { useEffect, useRef, useState } from "react";
import { Switch, Form, Input, Button } from "antd";
import { Container, Labels, Title1, EnableNotification } from "./styles";

const LiveAnsweringComponent = ({
  form,
  companyData,
  handleFormItemChange,
  currentDashBoardLofigEmial,
}) => {
  const [enableAddEmail, setEnableAddEmail] = useState(false);
  const dashboardEmailInputRef = useRef(null);

  const onFinish = (values) => {
    console.log("Received values:", values);
  };

  const onFinishFailed = (values) => {
    console.log(values, "values with failed");
  };

  const handleSwitchChange = (checked) => {
    form.setFieldsValue({ switchField: checked });
    setEnableAddEmail(checked);
  };
  useEffect(() => {
    if (companyData) {
      form.setFieldsValue({
        emailForCallNotificationCheck:
          companyData?.data?.live_answering_email_notifications &&
          companyData?.data?.live_answering_email_notifications !== ""
            ? JSON.parse(companyData?.data?.live_answering_email_notifications)
            : false,
        emailForCallNotification:
          companyData?.data?.email_for_live_answering_email_notifications,
      });
      companyData?.data?.live_answering_email_notifications &&
        setEnableAddEmail(
          JSON.parse(companyData?.data?.live_answering_email_notifications)
        );
    }
  }, [companyData, form]);

  const SetDashboardLoginEmail = () => {
    if (
      dashboardEmailInputRef &&
      currentDashBoardLofigEmial?.current?.input?.value
    ) {
      form.setFieldsValue({
        emailForCallNotification:
          currentDashBoardLofigEmial.current.input.value ||
          companyData?.data?.dashboard_login_email,
      });
    }
  };

  return (
    <Container>
      <Labels>
        <Title1>Live Answering</Title1>
      </Labels>

      <Form
        className="form"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={handleFormItemChange}
      >
        <EnableNotification>
          <span className="label1">Email Notifications</span>
          <Form.Item
            name="emailForCallNotificationCheck"
            valuePropName="checked"
            style={{ marginTop: "25px" }}
          >
            <Switch className="switch" onChange={handleSwitchChange} />
          </Form.Item>
        </EnableNotification>
        {enableAddEmail && (
          <div
            className="dashboard-email-main"
            // style={{ visibility: enableAddEmail ? 'visible' : 'hidden' }}
          >
            <Form.Item
              label="Email for call notifications"
              name="emailForCallNotification"
              className="input-label"
            >
              <Input
                className="dashboard-email-input"
                placeholder="gonzales@gmail.com"
                disabled={!enableAddEmail}
                ref={dashboardEmailInputRef}
              />
            </Form.Item>
            <div className="dashboard-email-btns">
              <Button className="btn" onClick={SetDashboardLoginEmail}>
                Use Dashboard Email
              </Button>
            </div>
          </div>
        )}
      </Form>
    </Container>
  );
};

export default LiveAnsweringComponent;
