import React, { useState, useEffect, useRef } from 'react'
import './styles'
import { Container, FormContainer, SaveBtnContainer } from './styles'
import { Skeleton, Form, Input, Tooltip } from 'antd'
import { Collapse } from 'antd'
import useGetData from '../../../../../hooks/useGetData'
import FormatDate from '../../../../../common/FormatDate'
import Dashboard from './dashboard/Dashboard'
import CompanyMailbox from './mailbox/CompanyMailbox'
import LiveAnsweringComponent from './liveAnswering/LiveAnsweringComponent'
import Spinner from '../../../../../common/Spinner'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { InstanceWithAuth } from '../../../../../App'
const { Panel } = Collapse

const Account = ({
  userID,
  formUpdatingloading,
  setInitialValues,
  handleFormItemChange,
  companyData,
  CompnayAdmin,
  form,
  auth,
  contactDataLoading,
  currentDashBoardLofigEmial,
}) => {
  const [collopasedKey, setCollopasedKey] = useState(false)
  return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="About" key="1" className="account-panel">
          <FormContainer>
            <Skeleton
              rows={10}
              paragraph={{ rows: 11 }}
              loading={formUpdatingloading || contactDataLoading}
              active
            >
              <Form
                className="form-main"
                requiredMark={false}
                form={form}
                onValuesChange={handleFormItemChange}
              >
                <Dashboard
                  form={form}
                  CompnayAdmin={CompnayAdmin}
                  companyData={companyData}
                  auth={auth}
                  companyID={userID}
                  handleFormItemChange={handleFormItemChange}
                  currentDashBoardLofigEmial={currentDashBoardLofigEmial}
                />

                <CompanyMailbox
                  form={form}
                  companyData={companyData}
                  handleFormItemChange={handleFormItemChange}
                  currentDashBoardLofigEmial={currentDashBoardLofigEmial}
                />

                <LiveAnsweringComponent
                  form={form}
                  companyData={companyData}
                  handleFormItemChange={handleFormItemChange}
                  currentDashBoardLofigEmial={currentDashBoardLofigEmial}
                />
              </Form>
            </Skeleton>
          </FormContainer>
        </Panel>
      </Collapse>
    </Container>
  )
}

export default Account
