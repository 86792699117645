import React, { useState, useRef, useEffect } from 'react'
import { DatePicker } from 'antd'
import { MainContainer } from './styles' // Import CSS for styling
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'

const LocationDateDropDwon = ({
  options,
  onSelect,
  auth,
  setCheckboxes,
  checkboxes,
  selectedOption,
  setSelectedOption,
  setCustomDate,
  customDate,
  setFilterDateType,
  setFilterNumberOfCompaniesType,
  filterDateType,
}) => {
  const [isOpenDropD, setIsDateDropDOpen] = useState(false)
  const dropdownRef = useRef(null)
  const [selectAll, setSelectAll] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDateDropDOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const toggleDropdown = () => {
    setIsDateDropDOpen(!isOpenDropD)
  }

  const handleOptionSelect = (option) => {
    setFilterDateType(option)
    setIsDateDropDOpen(false)
    setCustomDate(false)
    setSelectedOption(option)
    onSelect(option)
  }

  return (
    <MainContainer>
      <div className="custom-dropdown" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          <div className="user-name">
            {filterDateType
              ? filterDateType
              : customDate
              ? 'Select Custom Date'
              : 'Select Date'}
          </div>
          {isOpenDropD ? (
            <MdOutlineKeyboardArrowDown fill="rgb(76, 187, 203)"></MdOutlineKeyboardArrowDown>
          ) : (
            <MdOutlineKeyboardArrowDown></MdOutlineKeyboardArrowDown>
          )}
        </div>
        {isOpenDropD && (
          <ul className="dropdown-list" ref={dropdownRef}>
            <li onClick={() => handleOptionSelect('is')}>Is</li>
            <li onClick={() => handleOptionSelect('between')}>Between</li>
            <li onClick={() => handleOptionSelect('after')}>after</li>
            <li onClick={() => handleOptionSelect('before')}>before</li>
          </ul>
        )}
      </div>
    </MainContainer>
  )
}

export default LocationDateDropDwon
