import React, { useState, useEffect } from "react";
import { Form, Modal, Select } from "antd";
import { RxCross2 } from "react-icons/rx";
import Swal from "sweetalert2";
import { Container, MainConatiner } from "./styles";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../App";

const { Option } = Select;

const PipelineModel = ({
  isPipelineModelOpen,
  handleCancel,
  handleOk,
  pipelines = [],
  selectedPipeline,
  statusOptions,
  setStatusOptions,
  ticket,
}) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { auth } = useSelector((state) => state);

  useEffect(() => {
    if (selectedPipeline) {
      form.setFieldsValue({
        pipeline: selectedPipeline.id,
        status: selectedPipeline.stages[0]?.id || "",
      });
    }
  }, [selectedPipeline, form]);

  const handlePipelineChange = (value) => {
    const pipeline = pipelines.find((p) => p.id === value);
    setStatusOptions(pipeline?.stages);
    form.setFieldsValue({
      pipeline: value,
      status: "",
    });
  };

  const handleStatusChange = (value) => {
    form.setFieldsValue({
      status: value,
    });
  };

  const handleSave = async () => {
    try {
      await form.validateFields();
      setIsSaving(true);

      const values = form.getFieldsValue();
      const response = await InstanceWithAuth.put(
        `support/tickets/${ticket.id}`,
        {
          support_pipeline_id: values.pipeline,
          support_pipeline_stage_id: values.status,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status === 201 || response.status === 200) {
        form.resetFields();
        handleOk();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      closeIcon={<RxCross2 />}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      open={isPipelineModelOpen}
      onCancel={handleCancel}
      mask={true}
      width={630}
      className="custom-model"
    >
      <Form className="main-form" form={form} requiredMark={false}>
        <MainConatiner>
          <Container>
            <div className="title-main">
              <span className="title">Change Pipeline</span>
            </div>
            <div className="form-container">
              <div className="form-main">
                <div className="form">
                  <Form.Item
                    name="pipeline"
                    label="Pipeline"
                    className="form-items"
                    rules={[
                      { required: true, message: "Please select a pipeline!" },
                    ]}
                  >
                    <Select
                      className="location"
                      showSearch
                      placeholder="Select a pipeline"
                      optionFilterProp="children"
                      onChange={handlePipelineChange}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {pipelines.map((pipeline) => (
                        <Option key={pipeline.id} value={pipeline.id}>
                          {pipeline.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="form-main">
                <div className="form">
                  <Form.Item
                    className="form-items"
                    name="status"
                    label="Stage"
                    rules={[
                      { required: true, message: "Please select a status!" },
                    ]}
                  >
                    <Select
                      className="location"
                      showSearch
                      placeholder="Select a status"
                      onChange={handleStatusChange}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {statusOptions?.map((status) => (
                        <Option key={status.id} value={status.id}>
                          {status.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Container>

          <div className="button-div">
            <button
              className="close-button"
              type="button"
              onClick={handleCancel}
            >
              Close
            </button>
            <button
              className="save-button"
              type="button"
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </MainConatiner>
      </Form>
    </Modal>
  );
};

export default PipelineModel;
