import React, { useState, useEffect, useRef } from "react";
import { Modal } from "antd";
import { PiFileAudioThin } from "react-icons/pi";
import { AiOutlineDelete } from "react-icons/ai";
import { Container, ProductContainer, MainConatiner } from "./styles";
import { FaMusic, FaPlay } from "react-icons/fa";
import { TbPlayerStopFilled } from "react-icons/tb";

const LiveAnsweringoiceMailSettingsModel = ({
  isModalOpen,
  handleCancel,
  handleSaveFile, // New handler to save file and title
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [voiceMailTitle, setVoiceMailTitle] = useState("");
  const [audioSrc, setAudioSrc] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const audioRef = useRef(null);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      const handleLoadedMetadata = () => {
        setDuration(audioElement.duration);
      };

      const handleTimeUpdate = () => {
        setCurrentTime(audioElement.currentTime);
      };

      audioElement.addEventListener("loadedmetadata", handleLoadedMetadata);
      audioElement.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        audioElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
        audioElement.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [audioSrc]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("audio/")) {
      setSelectedFile(file);
      const fileURL = URL.createObjectURL(file);
      setAudioSrc(fileURL);
      setIsPlaying(false);
      setCurrentTime(0);
    } else {
      alert("Please select a valid audio file.");
    }
  };

  const handleDivClick = () => {
    document.getElementById("file-input").click();
  };

  const toggleAudioPlayback = () => {
    const audioElement = audioRef.current;
    if (!audioElement) return;

    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleDeleteAudio = () => {
    setSelectedFile(null);
    setAudioSrc(null);
    setIsPlaying(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setCurrentTime(0);
  };

  const handleTitleChange = (event) => {
    setVoiceMailTitle(event.target.value);
  };

  const handleSave = () => {
    if (selectedFile && voiceMailTitle) {
      const fileObj = {
        file: selectedFile,
        title: voiceMailTitle,
      };
      handleSaveFile(fileObj); 

      setSelectedFile(null);
      setVoiceMailTitle("");
      setAudioSrc(null);
      setIsPlaying(false);
      setCurrentTime(0);
    } else {
      // alert("Please provide a title and select a file.");
    }
  };

  const handleProgressBarClick = (event) => {
    const audioElement = audioRef.current;
    if (!audioElement) return;

    const progressBar = event.currentTarget;
    const totalWidth = progressBar.clientWidth;
    const clickX = event.clientX - progressBar.getBoundingClientRect().left;
    const newTime = (clickX / totalWidth) * duration;

    audioElement.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const progressPercentage = duration ? (currentTime / duration) * 100 : 0;

  return (
    <Modal
      closeIcon={true}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      open={isModalOpen}
      onCancel={handleCancel}
      mask={true}
      width={630}
      className="custom-model"
    >
      <MainConatiner>
        <Container>
          <div className="title-main">
            <p className="title">Add Voicemail</p>
          </div>

          <ProductContainer>
            <div className="product-items">
              <span className="label">Title</span>
              <input
                className="input"
                placeholder="Enter title here..."
                value={voiceMailTitle}
                onChange={handleTitleChange}
              />
            </div>

            {!audioSrc && (
              <div className="file-selector">
                <div onClick={handleDivClick}>
                  <PiFileAudioThin color="#4cbbcb" size={50} />
                  <span className="label">Upload Audio file</span>
                </div>
              </div>
            )}

            <input
              id="file-input"
              type="file"
              accept="audio/*"
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />

            {audioSrc && (
              <div className="audio-file-display">
                <div className="audio-icon">
                  <FaMusic color="#4cbbcb" size={15} />
                </div>
                <div className="audio-details">
                  <span className="audio-filename">{selectedFile?.name}</span>
                </div>
                <div className="audio-controls">
                  {isPlaying ? (
                    <TbPlayerStopFilled
                      color="#55637366"
                      size={20}
                      onClick={toggleAudioPlayback}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <FaPlay
                      color="#55637366"
                      size={16}
                      onClick={toggleAudioPlayback}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  <AiOutlineDelete
                    color="#55637366"
                    size={20}
                    onClick={handleDeleteAudio}
                    style={{ cursor: "pointer" }}
                  />
                </div>

                <div
                  className="progress-bar"
                  onClick={handleProgressBarClick}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className="progress"
                    style={{
                      width:
                        progressPercentage > 0
                          ? `${progressPercentage}%`
                          : "1px",
                      backgroundColor: "#4cbbcb",
                      height: "5px",
                    }}
                  ></div>
                </div>
              </div>
            )}
          </ProductContainer>
        </Container>

        <div className="button-div">
          <button className="cancel" type="button" onClick={handleCancel}>
            Close
          </button>
          <button type="button" onClick={handleSave}>
            Save
          </button>
        </div>
      </MainConatiner>

      {audioSrc && <audio ref={audioRef} src={audioSrc} />}
    </Modal>
  );
};

export default LiveAnsweringoiceMailSettingsModel;
