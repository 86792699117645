import styled from "styled-components";

const Container = styled.div`
  .account-collapase {
    border: none;
    .account-panel {
      border: none;
      max-width: 339px;
      width: 100%;
      .ant-collapse-header {
        display: flex;
        width: 339px;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        border-radius: 8px;
        background-color: ${(props) =>
          props.open
            ? "var(--Blue-100, #4cbbcb)"
            : "var(--Gray-5, rgba(85, 99, 115, 0.05))"};

        .ant-collapse-header-text {
          color: ${(props) =>
            props.open
              ? "var(--White-100, #fff)"
              : "var(--Dark-Gray-100, #2B3746)"};
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.49px;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: ${(props) =>
                props.open
                  ? "var(--White-100, #fff)"
                  : "var(--Dark-Gray-100, #2B3746)"};
            }
          }
        }
      }
      .ant-collapse-content {
        border: none;
      }
    }
  }

  .form-collapased {
    border: none;
    .panel {
      border: none;
      .ant-collapse-header {
        display: flex;
        width: 339px;
        padding-right: 16px;
        padding-left: 0px;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        background-color: white;
        border-bottom: 1px dotted var(--Gray-20, rgba(85, 99, 115, 0.2));
        border-radius: unset;
        .ant-collapse-header-text {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.49px;
        }
        .ant-collapse-expand-icon {
          span {
            svg {
              /* width: 16px;
              height: 16px; */
              flex-shrink: 0;
              fill: black;
            }
          }
        }
      }
    }
  }

  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
`;

const SaveBtnContainer = styled.div`
  width: 100%;
  height: 74px;
  display: flex;
  padding: 24px 40px;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
  .save-btn {
    display: flex;
    padding: 6px 12px;
    align-items: center;
    cursor: pointer;
    min-width: 99px;
    height: 41px;
    text-align: center;
    justify-content: center;
    gap: 12px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none; /* 14.4px */ /* 14.4px */
  }
`;

const FormContainer = styled.div`
  .form {
    gap: 10px !important;
  }
  .form-main {
    display: flex;
    padding: 10px 0px 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    .email {
      .ant-form-item-control-input-content {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        .email-input {
          display: flex;
          padding: 12px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          width: 212px;
          border-radius: 4px;
          border: 1px solid var(--Gray-20, rgba(85, 99, 115, 0.2));
          height: 41px;
        }
        .btn {
          display: flex;
          padding: 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          border-radius: 4px;
          background: var(--Blue-20, rgba(76, 187, 203, 0.2));
          border: none;
          .btn-text {
            color: var(--Dark-Gray-100, #2b3746);
            text-align: center;
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
      }
    }

    .custom-id-main {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      align-self: stretch;

      .label {
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        height: auto;
        letter-spacing: 0.1px;
        /* line-height: 120%;  */
      }
      .custom-id {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--Gray-20, rgba(85, 99, 115, 0.2));

        .title {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
        }

        .btn {
          display: flex;
          width: 57px;
          cursor: pointer;
          padding: 7px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          outline: none;
          border: none;
          border-radius: 4px;
          background: var(--Blue-20, rgba(76, 187, 203, 0.2));
          .btn-text {
            color: var(--Dark-Gray-100, #2b3746);
            text-align: center;
            font-family: Outfit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
          }
        }
      }
    }

    .label {
      align-self: stretch;
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.63px;
    }

    .ant-form-item {
      height: 61px;
      margin-bottom: unset;
    }

    .ant-form-item-row {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start;
      gap: 4px;

      align-self: stretch;
      margin-bottom: 0;

      label {
        color: var(--Gray-70, rgba(85, 99, 115, 0.7));
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        height: auto;
        line-height: 14.4px;
      }

      .username-input {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        width: 339px;
        border-radius: 4px;
        border: 1px solid var(--Gray-20, rgba(85, 99, 115, 0.2));
        height: 41px;
      }

      .ant-form-item-explain-error {
        font-size: 10px;
      }
      .ant-select-selection-item:active {
        background-color: transparent;
        box-shadow: none;
      }
      .username-input::placeholder,
      .email-input::placeholder {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }
      .username-input:focus::placeholder,
      .email-input:focus::placeholder {
        color: transparent;
      }
      .username-input:focus,
      .email-input:focus {
        box-shadow: none;
      }

      label::after {
        content: "";
      }
    }
  }
`;

export { Container, FormContainer, SaveBtnContainer };
