import React, { useState } from "react";
import { useSelector } from "react-redux";
import { HeaderWrapper, BreadcrumbContainer, AvatarContainer } from "./styles";
import { Breadcrumb, Dropdown, Space, Skeleton } from "antd";
import GetLogo from "../../getlogo/getlogo";
import NameAvatar from "../../../common/NameAvatar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import UserFlowSubHeader from "../userFlowSubHeader/UserFlowSubHeader";
import { handleLogoutUser } from "../../../redux/auth/authSlice";
import { handleCallCenterLogout } from "../../../redux/callCenter/callCenterSlice";
import ProfileDropDown from "../../../components/customDropDown/ProfileDropDown";
import { DownOutlined, SmileOutlined, RightOutlined } from "@ant-design/icons";

function CommonHaeder({
  firstBcItem,
  secondBcItem,
  thirdBcItem,
  fourthBcItem,
  firstBcIcon = "userUnFilled",
  secondBcIcon = "user",
  thirdBcIcon = "locationBc",
  fourthBcIcon = "",
  firtBcUrl = "#",
  secondBcUrl = "#",
  isLoading = false,
}) {
  const { app, auth } = useSelector((state) => state);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = [
    { label: "Overview" },
    { label: "Reports" },
    { label: "FAQ" },
    { label: "Employee Manual" },
    { label: "HR & Accounting" },
    { label: "Logout" },
  ];

  const handleSelect = (option) => {
    setSelectedOption(option);
    if (option?.label == "Logout") {
      localStorage.removeItem("emailIdsArray");
      localStorage.removeItem("id");
      localStorage.removeItem("folder");
      dispatch(handleLogoutUser());
      dispatch(handleCallCenterLogout());
      navigate("/");
    }
    if (option?.label == "FAQ") {
      window.open(
        "https://help.starthubcenters.com/knowledge/internal-use",
        "_blank"
      );
    }
  };

  let companyData = "";
  return (
    <HeaderWrapper isCollopased={app?.sidebarCollapsed}>
      <BreadcrumbContainer>
        <Breadcrumb>
          {firstBcItem && (
            <Breadcrumb.Item href={firtBcUrl}>
              {GetLogo(firstBcIcon)}
              <span>{firstBcItem}</span>
            </Breadcrumb.Item>
          )}
          {isLoading ? (
            <Breadcrumb.Item>
              <Skeleton.Input active size={23} block="false" />
            </Breadcrumb.Item>
          ) : secondBcItem ? (
            <Breadcrumb.Item href={secondBcUrl}>
              {GetLogo(secondBcIcon)}
              <span>{secondBcItem}</span>
            </Breadcrumb.Item>
          ) : null}
          {thirdBcItem && (
            <Breadcrumb.Item href="#">
              {GetLogo(thirdBcIcon)}
              <span>{thirdBcItem}</span>
            </Breadcrumb.Item>
          )}
          {fourthBcItem && (
            <Breadcrumb.Item href="#">
              {GetLogo(fourthBcIcon)}
              <span>{fourthBcItem}</span>
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </BreadcrumbContainer>

      <ProfileDropDown options={options} onSelect={handleSelect} auth={auth} />
    </HeaderWrapper>
  );
}

export default CommonHaeder;
