import { styled } from 'styled-components'

const Navbar = styled.div`
  height: 99px;
  border-bottom: 1px solid #556373;
  padding: 40px 140px 24px 140px;
  gap: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
  background: var(--White-100, #fff);
`

const StartHubLogo = styled.img`
  width: 100px;
  height: 35px;
  object-fit: contain;
`
const ImgConatiner = styled.div`
  .forget-img-wrapper {
    display: flex;
    padding: 8px 140px;
    align-items: center;
    justify-content: center;

    .forget-img {
      width: 321.5px;
      height: 321.5px;
      object-fit: cover;
    }
  }
  .img-div {
    display: flex;
    padding: 8px 140px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    img {
      display: flex;
      width: 349.872px;
      height: 349.872px;
      justify-content: center;
      align-items: center;
    }
  }
`

export { Navbar, StartHubLogo }
