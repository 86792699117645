import React, { useState } from "react";
import { Container } from "./styles";
import { Form, Input, Select, Switch } from "antd";
import "./styles";

const UserVisibility = ({ id }) => {
  const [value, setValue] = useState([]);

  const options = [
    {
      label: "Automatically Forward calls to my forwarding number",
      value: "automatically_forward_all_calls",
    },
    {
      label: "Take the call and forward it to the forwarding number",
      value: "rev_call_then_forword",
    },
    {
      label: "Answer the call and only take messages",
      value: "rcv_call_take_message",
    },
    {
      label: "Answer with a personalized greeting, attempt a soft transfer, and if unavailable, take a message",
      value: "rcv_call_greeting_soft_transfer_take_message",
    },
  ];

  return (
    <Container id={id} className="scroll-section">
      <p className="title">Forwarding Option</p>
      <div className="form-container">
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Select Option"
              name="forword_type"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="Select Option"
                className="location"
                value={value}
                style={{
                  width: "100% !important",
                }}
                onChange={setValue}
                options={options}
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Forwarding Phone Number"
              name="foword_number"
              className="form-items"
            >
              <Input className="input" placeholder="(704) 555-0127" />
            </Form.Item>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default UserVisibility;
