import React, { useEffect, useRef, useState } from "react";
import {
  ComposerWrapper,
  Button1,
  Header,
  Body,
  HeaderTitle,
  MessageBodyContainer,
  ButtonsWrapper,
  Button,
  AttachmentList,
  AttachmentItem,
  RemoveIcon,
} from "./styles";
import { IoReturnUpForward, IoClose } from "react-icons/io5";
import Draggable from "react-draggable";
import "react-quill/dist/quill.snow.css";
import { FaExpandAlt } from "react-icons/fa";
import { ImShrink2 } from "react-icons/im";
import { FiPaperclip } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import { Input, Select } from "antd";
import { Quill } from "react-quill";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import { CrossIcon } from "../../../../common/customPill/styles";
import { useSelector } from "react-redux";
import { InstanceWithAuth } from "../../../../App";
import useGetData from "../../../../hooks/useGetData";
import Swal from "sweetalert2";

Quill.register("modules/emoji", Emoji);

const { Option } = Select;

const EmailComposer = ({
  visible,
  onClose,
  selectedEmailId,
  refetchEmails,
}) => {
  const [emailBody, setEmailBody] = useState("");
  const [fullScreen, setFullScreen] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [value, setValue] = useState("");
  const [toRecipients, setToRecipients] = useState([]);
  const [ccRecipients, setCcRecipients] = useState([]);
  const [bccRecipients, setBccRecipients] = useState([]);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [fetchedEmails, setFetchedEmails] = useState([]);
  const [sendingMail, setSendingMail] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { auth } = useSelector((state) => state);
  const {
    data: email,
    error,
    loading,
    refetchData,
  } = useGetData(`support/emails/${selectedEmailId}`);
  const handleFileSelect = (files) => {
    setSelectedFiles(files);
  };

  const handleInputChange = (name, value) => {
    if (name === "message") setMessage(value);
    if (name === "subject") setSubject(value);
  };

  const handleSend = async () => {
    setSendingMail(true);
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("message_body", message);

    toRecipients.forEach((recipient, index) =>
      formData.append(`to[${index}]`, recipient)
    );
    ccRecipients.forEach((recipient, index) =>
      formData.append(`cc[${index}]`, recipient)
    );
    bccRecipients.forEach((recipient, index) =>
      formData.append(`bcc[${index}]`, recipient)
    );

    Array.from(selectedFiles).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name);
    });

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/compose`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        refetchEmails();

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
        // Reset fields
        setToRecipients([]);
        setCcRecipients([]);
        setBccRecipients([]);
        setSubject("");
        setMessage("");
        setSelectedFiles([]);
        setShowCc(false);
        setShowBcc(false);

        // Close the composer
        onClose();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    } finally {
      setSendingMail(false);
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    handleFileSelect(files);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  const handleEditorChange = (content) => {
    setMessage(content);
    handleInputChange("message", content);
  };

  const handleKeyPress = (event, type) => {
    const emailRegex = /\S+@\S+\.\S+/;
    const inputValue = event.target.value.trim();

    if (event.key === "Enter" && emailRegex.test(inputValue)) {
      if (type === "to" && !toRecipients.includes(inputValue)) {
        setToRecipients([...toRecipients, inputValue]);
      } else if (type === "cc" && !ccRecipients.includes(inputValue)) {
        setCcRecipients([...ccRecipients, inputValue]);
      } else if (type === "bcc" && !bccRecipients.includes(inputValue)) {
        setBccRecipients([...bccRecipients, inputValue]);
      }

      setTimeout(() => {
        event.target.value = "";
      }, 100);
    }
  };

  const handleSelectChange = (value, type) => {
    switch (type) {
      case "to":
        setToRecipients(value);
        break;
      case "cc":
        setCcRecipients(value);
        break;
      case "bcc":
        setBccRecipients(value);
        break;
      default:
        break;
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleSearch = async (searchTerm) => {
    if (!searchTerm) return;

    const formData = new FormData();
    formData.append("search", searchTerm);

    try {
      const response = await InstanceWithAuth.post(
        `support/emails/suggestion_emails`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );

      if (response?.data?.status === "success") {
        setFetchedEmails(response.data.emails.filter(Boolean));
      }
    } catch (error) {
      console.error("Search error:", error);
    }
  };

  const mergeEmails = (fetchedEmails) => {
    const mergedEmails = [
      ...new Set([email?.data?.sender_email, ...fetchedEmails]), // Ensure unique emails
    ].filter(Boolean);
    return mergedEmails;
  };

  const TruncateText = ({ text }) => {
    const maxLength = 20;
    let truncatedText = text.substring(0, maxLength);
    if (truncatedText.length < text.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  if (!visible) return null;

  const toggleFullScreen = () => {
    setFullScreen((prev) => !prev);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: "1000",
        overflow: "hidden",
        bottom: 0,
        right: 0,
        // backgroundColor: 'red',
      }}
    >
      {email && (
        <ComposerWrapper fullScreen={fullScreen}>
          <Header className="draggable-handle">
            <HeaderTitle>Email</HeaderTitle>
            <div className="make-row">
              <Button1 onClick={toggleFullScreen}>
                {fullScreen ? <ImShrink2 /> : <FaExpandAlt />}
              </Button1>
              <Button1 onClick={onClose}>
                <CrossIcon />
              </Button1>
            </div>
          </Header>
          <Body>
            {/* <div className="notification">
            <strong>You're currently composing a new email.</strong> To reply to
            an existing thread, find the email in the timeline and click "reply"
            there instead.
          </div> */}
            <MessageBodyContainer>
              {/* To Recipients Field */}
              <div className="main-div">
                <div className="left-div">
                  <span className="name">To</span>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Enter or choose recipients"
                    value={toRecipients}
                    onChange={(value) => handleSelectChange(value, "to")}
                    onSearch={handleSearch}
                    tokenSeparators={[",", ";", " "]}
                  >
                    {mergeEmails(fetchedEmails).map((recipient) => (
                      <Option key={recipient} value={recipient}>
                        {recipient}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="right-div">
                  <span className="name" onClick={() => setShowCc(!showCc)}>
                    Cc
                  </span>
                  <span className="name" onClick={() => setShowBcc(!showBcc)}>
                    Bcc
                  </span>
                </div>
              </div>

              {/* Cc Recipients Field */}
              {showCc && (
                <div className="main-div">
                  <div className="left-div">
                    <span className="name">Cc</span>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Enter or choose Cc recipients"
                      value={ccRecipients}
                      onChange={(value) => handleSelectChange(value, "cc")}
                      onSearch={handleSearch}
                      tokenSeparators={[",", ";", " "]}
                    >
                      {mergeEmails(fetchedEmails).map((recipient) => (
                        <Option key={recipient} value={recipient}>
                          {recipient}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}

              {/* Bcc Recipients Field */}
              {showBcc && (
                <div className="main-div">
                  <div className="left-div">
                    <span className="name">Bcc</span>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Enter or choose Bcc recipients"
                      value={bccRecipients}
                      onChange={(value) => handleSelectChange(value, "bcc")}
                      onSearch={handleSearch}
                      tokenSeparators={[",", ";", " "]}
                    >
                      {mergeEmails(fetchedEmails).map((recipient) => (
                        <Option key={recipient} value={recipient}>
                          {recipient}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}

              <div className="main-div">
                <div className="left-div">
                  <span className="name">Subject</span>
                  <Input
                    mode="multiple"
                    allowClear
                    value={subject}
                    type="text"
                    style={{ width: "100%" }}
                    placeholder="Enter subject"
                    className="input"
                    onChange={(e) =>
                      handleInputChange("subject", e.target.value)
                    }
                  ></Input>
                </div>
              </div>

              {/* Quill Editor */}
              <div className="text-area-wrapper">
                <ReactQuill
                  name="message"
                  className="quill-editor"
                  theme="snow"
                  value={message}
                  onChange={handleEditorChange}
                  placeholder="Type your message here..."
                  modules={{
                    toolbar: {
                      container: "#quill-toolbarid",
                      "emoji-toolbar": true,
                      "emoji-textarea": true,
                      "emoji-shortname": true,
                    },
                  }}
                />
              </div>

              {/* Attachment List */}
              <AttachmentList>
                {selectedFiles.map((file, index) => (
                  <AttachmentItem key={index}>
                    <TruncateText text={file.name} />
                    <span className="file-size">
                      ({(file.size / 1024).toFixed(1)}kb)
                    </span>
                    <RemoveIcon onClick={() => handleRemoveFile(index)}>
                      <IoClose />
                    </RemoveIcon>
                  </AttachmentItem>
                ))}
              </AttachmentList>

              {/* Buttons */}
              <ButtonsWrapper>
                <div id="quill-toolbarid" className="custom-toolbar">
                  <button className="ql-bold"></button>
                  <button className="ql-italic"></button>
                  <button className="ql-underline"></button>
                  <button className="ql-list" value="ordered"></button>
                  <button className="ql-list" value="bullet"></button>
                  <button className="ql-link"></button>
                </div>

                <Button onClick={handleFileClick}>
                  <FiPaperclip />
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    multiple
                  />
                </Button>
                <Button
                  disabled={sendingMail}
                  className="send-button"
                  onClick={handleSend}
                >
                  {sendingMail ? "Sending..." : "Send"}
                </Button>
              </ButtonsWrapper>
            </MessageBodyContainer>
          </Body>
        </ComposerWrapper>
      )}
    </div>
  );
};

export default EmailComposer;
