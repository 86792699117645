// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  padding: 0;
  margin: 0;
  font-family: "Outfit", sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:where(.css-dev-only-do-not-override-11xg00t).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  border-color: var(--Blue-30, rgba(76, 187, 203));
  color: var(--Blue-30, rgba(76, 187, 203));
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,UAAU;EACV,SAAS;EACT,iCAAiC;EACjC,sBAAsB;EACtB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;;;EAGE,gDAAgD;EAChD,yCAAyC;AAC3C","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500&display=swap\");\r\n\r\nbody {\r\n  padding: 0;\r\n  margin: 0;\r\n  font-family: \"Outfit\", sans-serif;\r\n  box-sizing: border-box;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\r\n    monospace;\r\n}\r\n\r\n:where(.css-dev-only-do-not-override-11xg00t).ant-btn-default:not(\r\n    :disabled\r\n  ):not(.ant-btn-disabled):hover {\r\n  border-color: var(--Blue-30, rgba(76, 187, 203));\r\n  color: var(--Blue-30, rgba(76, 187, 203));\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
