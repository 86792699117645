import React, { useEffect, useState } from 'react'
import { Collapse, Skeleton } from 'antd'
import {
  DrowpdowmsContainer,
  Container,
  ContentContainer,
  InvoiceCard,
} from './styles'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Space, Menu } from 'antd'
import GetLogo from '../../../../getlogo/getlogo'
import useGetData from '../../../../../hooks/useGetData'
import avatar from '../../../../../assets/company.png'
import DataNotFound from '../../../../../common/DataNotFound'
import { useNavigate } from 'react-router-dom'
import InvoiceCompanyDropDown from '../../../../invoiceFiltersDropDown/invoiceCompanyDropDown/InvoiceCompanyDropDown'
import InvoiceDateFilters from '../../../../invoiceFiltersDropDown/invoiceDateFilters/InvoiceDateFilters'
import './styles'

const { Panel } = Collapse
export default function Invoices({ userID }) {
  const [collopasedKey, setCollopasedKey] = useState(false)
  const [timeSearchParam, setTimeSearchParam] = useState('Last 6 months')
  const [selectedCompnayId, setSelectedCompnayId] = useState()
  const [companySearchParam, setCompanySearchParam] = useState('All Companies')
  const navigate = useNavigate()

  function getBackDate(years = 0, months = 0) {
    const currentDate = new Date()
    const backDate = new Date(
      currentDate.getFullYear() - years,
      currentDate.getMonth() - months,
      currentDate.getDate()
    )

    const formattedDate = `${backDate.getFullYear()}-${(backDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${backDate.getDate().toString().padStart(2, '0')}`

    return formattedDate
  }
  function getCurrentDateFormatted() {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const day = currentDate.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}`
  }
  const backData = getBackDate(
    timeSearchParam == 'Last 1 Year'
      ? 1
      : timeSearchParam == 'Last 2 Years'
      ? 2
      : 0,
    timeSearchParam == 'Last 6 months' ? 6 : 0
  )
  let endData = getCurrentDateFormatted()
  const { refetchData, data, loading, error } = useGetData(
    `invoices?company_id=${
      selectedCompnayId ? selectedCompnayId : ''
    }&contact_id=${userID}&start_date=${backData}&end_date=${endData}`
  )
  const {
    data: CompaniesData,
    loading: companiesDataLoading,
    error: companiesError,
  } = useGetData(`companies?contact_id=${userID}`)
  console.log(data?.data, 'mailbox in sidebar')
  const handleDownload = (publicUrl) => {
    window.open(publicUrl, '_blank')
  }

  const handleSelectCompnay = (company) => {
    setSelectedCompnayId(company?.id)
    setCompanySearchParam(company?.name)
  }

  useEffect(() => {
    refetchData()
  }, [timeSearchParam, selectedCompnayId])

  const firstinvoice = data?.data?.[0]
  return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        <Panel header="Invoices" key="1" className="account-panel">
          <ContentContainer>
            <DrowpdowmsContainer>
              <InvoiceCompanyDropDown
                companiesData={CompaniesData?.data}
                setSelectedCompnayId={setSelectedCompnayId}
                setCompanySearchParam={setCompanySearchParam}
                companySearchParam={companySearchParam}
              ></InvoiceCompanyDropDown>
              <InvoiceDateFilters
                selectedStatusOption={timeSearchParam}
                setSelectedStatusOption={setTimeSearchParam}
              ></InvoiceDateFilters>
            </DrowpdowmsContainer>
            {loading ? (
              <Skeleton active />
            ) : data?.data?.length == 0 ? (
              <DataNotFound label={'No Invoices Found'}></DataNotFound>
            ) : (
              firstinvoice && (
                <InvoiceCard>
                  <div className="content icons-and-amount">
                    <div className="avatar">
                      <img src={avatar} alt="" className="img" />
                      <p className="company-name">
                        {firstinvoice?.company?.name}
                      </p>
                    </div>
                    <span className="amount">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button
                          className={
                            firstinvoice?.status == 'paid'
                              ? 'active'
                              : firstinvoice?.status == 'open'
                              ? 'active open'
                              : firstinvoice?.status == 'past pue'
                              ? 'active pastdue'
                              : firstinvoice?.status == 'voided'
                              ? 'active voided'
                              : 'active'
                          }
                        >
                          <span className="text">{firstinvoice?.status}</span>
                        </button>
                      </div>
                    </span>
                  </div>
                  <div className="icons-and-amount avatar">
                    <span className="company-id">{`ID${firstinvoice?.number}`}</span>
                    <span className="amount">
                      <span>
                        {firstinvoice?.status == 'paid'
                          ? firstinvoice?.paid_date
                          : firstinvoice?.issue_date}
                      </span>
                    </span>
                  </div>
                  <div className="icons-and-amount last div">
                    <div
                      className="invoice-amount"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {`$${firstinvoice?.total_amount}`}
                    </div>
                    <div className="icons">
                      {firstinvoice?.status != 'draft' ? (
                        <>
                          <span className="icon">{GetLogo('mailbox')}</span>
                          <span
                            className="icon"
                            onClick={() =>
                              handleDownload(firstinvoice?.firstinvoice_pdf)
                            }
                          >
                            {GetLogo('download')}
                          </span>
                        </>
                      ) : firstinvoice?.status == 'draft' &&
                        firstinvoice?.id != 0 ? (
                        <>
                          <span
                            style={{ cursor: 'pointer' }}
                            className="icon"
                            onClick={() =>
                              navigate(
                                `/companie/create-invoice/${firstinvoice?.company_id}/${firstinvoice?.id}`
                              )
                            }
                          >
                            {GetLogo('edit')}
                          </span>

                          <span className="icon"></span>
                        </>
                      ) : (
                        <>
                          <span className="icon"></span>
                          <span className="icon"></span>
                        </>
                      )}
                    </div>
                  </div>
                </InvoiceCard>
              )
            )}

            {data?.data?.length > 1 && (
              <Collapse className="form-collapased">
                <Panel header="View All" key="1-1" className="panel">
                  {data?.data?.slice(1).map((invoice) => (
                    <InvoiceCard>
                      <div className="content icons-and-amount">
                        <div className="avatar">
                          <img src={avatar} alt="" className="img" />
                          <p className="company-name">
                            {invoice?.company?.name}
                          </p>
                        </div>
                        <span className="amount">
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <button
                              className={
                                invoice?.status == 'paid'
                                  ? 'active'
                                  : invoice?.status == 'open'
                                  ? 'active open'
                                  : invoice?.status == 'past due'
                                  ? 'active pastdue'
                                  : invoice?.status == 'voided'
                                  ? 'active voided'
                                  : 'active'
                              }
                            >
                              <span className="text">{invoice?.status}</span>
                            </button>
                          </div>
                        </span>
                      </div>
                      <div className="icons-and-amount avatar">
                        <span className="company-id">{`ID${invoice?.number}`}</span>
                        <span className="amount">
                          <span>
                            {invoice?.status == 'paid'
                              ? invoice?.paid_date
                              : invoice?.issue_date}
                          </span>
                        </span>
                      </div>
                      <div className="icons-and-amount last div">
                        <div
                          className="invoice-amount"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {`$${invoice?.total_amount}`}
                        </div>
                        <div className="icons">
                          {invoice?.status != 'draft' ? (
                            <>
                              <span className="icon">{GetLogo('mailbox')}</span>
                              <span
                                className="icon"
                                onClick={() =>
                                  handleDownload(invoice?.invoice_pdf)
                                }
                              >
                                {GetLogo('download')}
                              </span>
                            </>
                          ) : invoice?.status == 'draft' && invoice?.id != 0 ? (
                            <>
                              <span
                                style={{ cursor: 'pointer' }}
                                className="icon"
                                onClick={() =>
                                  navigate(
                                    `/companie/create-invoice/${invoice?.company_id}/${invoice?.id}`
                                  )
                                }
                              >
                                {GetLogo('edit')}
                              </span>

                              <span className="icon"></span>
                            </>
                          ) : (
                            <>
                              <span className="icon"></span>
                              <span className="icon"></span>
                            </>
                          )}
                        </div>
                      </div>
                    </InvoiceCard>
                  ))}
                </Panel>
              </Collapse>
            )}
          </ContentContainer>
        </Panel>
      </Collapse>
    </Container>
  )
}
