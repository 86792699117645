import styled from "styled-components";

const Container = styled.div`
  ${"" /* position: relative; */}
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  ${"" /* height: 60px !important; */}
  padding: 16px 20px;
  gap: 10px;
  height: 60px;

  .button {
    display: flex;
    padding: 6px 12px;
    ${"" /* max-height: 10px; */}
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Blue-100, #4cbbcb);
    color: var(--White-100, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border: none;
    cursor: pointer;
  }
`;

const Button = styled.button`
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #d0dee1;
  color: var(--Gray-100, #556373);
  padding: 2.5px 5px;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 50px;
  right: 20px;
  background-color: white;
  border: 1px solid #d0dee1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 160px;
  z-index: 1;
  color: var(--Gray-100, #556373);
  border-radius: 4px;
`;

const MenuOption = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  color: var(--Gray-100, #556373);
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const IconWrapper = styled.div`
  margin-right: 8px; /* Space between icon and text */
  font-size: 18px;
`;

const Loader = styled.div`
  padding: 10px;
  text-align: center;
`;

const ReopenIconWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const ReopenIconWrapperr = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export {
  Container,
  Dropdown,
  Loader,
  Button,
  MenuOption,
  IconWrapper,
  ReopenIconWrapper,
  ReopenIconWrapperr,
};
