import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Form } from "antd";
import {
  Banner,
  ComponentsContainer,
  ProductPlansEnableAndContactContainer,
  PlanFormsContainer,
  MainContainer,
  FixedContainer,
} from "./styels";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useGetData from "../../../../hooks/useGetData";
import { InstanceWithAuth } from "../../../../App";
import ReactTostify from "../../../../common/ReactTostify";
import CommonHaeder from "../../../../components/header/commonHeader/CommonHaeder";
import CompanieFlowSubHeader from "../../subHeader/CompanieFlowSubHeader";
import StatusLabelButton from "../../../../components/statusLabelButton/StatusLabelButton";
import PlansSidebar from "../../../../components/componyFlowComponents/liveAnswering/liveAnsweringDetails/plansSidebar/PlansSidebar";
import DetailForm from "../../../../components/componyFlowComponents/liveAnswering/liveAnsweringDetails/detailForm/DetailForm";
import UserVisibility from "../../../../components/componyFlowComponents/liveAnswering/liveAnsweringDetails/userVisibility/UserVisibility";
import DefaultEndDate from "../../../../components/componyFlowComponents/liveAnswering/liveAnsweringDetails/defaultEndDate/DefaultEndDate";
import IncludedAddOns from "../../../../components/componyFlowComponents/liveAnswering/liveAnsweringDetails/includedAddOns/IncludedAddOns";
import VoiceMailSettings from "../../../../components/componyFlowComponents/liveAnswering/liveAnsweringDetails/voiceMailSettings/VoiceMailSettings";
import { UploadFile, getCompanyLiveAnsweringSettingsByPlan, saveCompanySettings } from "../../../../services/callCenterServices";

const LiveAnsweringDetails = () => {
  const [active, setActive] = useState(1);
  const [activeSection, setActiveSection] = useState(1);
  const observer = useRef(null);
  const { app, auth } = useSelector((state) => state);
  const [selectedServices, setSelectedServices] = useState([]);
  const [oneTimeProduct, setOneTimeProduct] = useState(false);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [doesNotEnd, setDoesNotEnd] = useState(false);
  const [canChooseEndDate, setCanChooseEndDate] = useState(false);
  const [fixedTerm, setFixedTerm] = useState(false);
  const [trialDays, setTrialDays] = useState();
  const [trialYears, setTrialYears] = useState();
  const [freePlan, setFreePlan] = useState("paid");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [monthlyBilling, setMonthlyBilling] = useState(false);
  const [yearlyBilling, setYearlyBilling] = useState(false);
  const [includeFreeTrial, setIncludeFreeTrial] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [voiceMailTitle, setVoiceMailTitle] = useState(null);
  const [settingsData, setSettingsData] = useState(null);
  const { companyID, planID, phoneNumber } = useParams();
  const [selectedFile, setselectedFile] = useState(null);
  const [uploadedFile, setuploadedFile] = useState(null);
  const {
    data: companyData,
    loading: companyDataLoading,
    error: companyDataErro,
    refetchData: refetchCompnayData,
  } = useGetData(`companies/${companyID}`);

  const { data: locationsData } = useGetData("locations");
  const handleAddAllLocations = () => {
    if (locationsData && locationsData.data) {
      const allLocationIds = locationsData.data.map((item) => item.id);
      form.setFieldsValue({
        location_ids: locationsData.data ? allLocationIds : [],
      });
      setDropdownVisible(false);
    }
  };

  const selectdServicesIds = [];
  selectedServices?.map((service) => {
    selectdServicesIds.push(service?.id);
  });
  const selectdAddOnsIds = [];
  selectedAddOns.map((service) => {
    selectdAddOnsIds.push(service.id);
  });
  const navigate = useNavigate();
  const [form] = Form.useForm();

  function convertToBinary(value) {
    if (typeof value === "boolean") {
      return value ? 1 : 0;
    } else {
      return 0;
    }
  }


  useEffect(() => {
    
    form.setFieldsValue({
      during_weekdays: settingsData?.during_weekdays,
      from_hour : settingsData?.weekdays_timing?.from_hour,
      to_hour : settingsData?.weekdays_timing?.to_hour,
      after_weekdays_timing : settingsData?.after_weekdays_timing,
      during_weekend: settingsData?.during_weekend,
      public_holidays: settingsData?.public_holidays,
      month : settingsData?.private_holidays_range?.month,
      from_date : settingsData?.private_holidays_range?.from_date,
      to_date : settingsData?.private_holidays_range?.to_date,
      greeting_company_name: settingsData?.greeting_company_name,
      greeting_message: settingsData?.greeting_message,
      short_business_description: settingsData?.short_business_description,
      notification_email: settingsData?.notification_email,
      voicemail_option: settingsData?.voicemail_option,
      receptionist_special_instruction: settingsData?.receptionist_special_instruction,            
      custom_voicemail_script: settingsData?.custom_voicemail_script || "",
      custom_voicemail_file: uploadedFile || "",
    });
  }, [form,settingsData]);

  const handleFileChange = (e) => {
    setselectedFile(e.target.files[0]);
  };
  useEffect(() => {
   if(selectedFile){
    handleUpload()
   }
  }, [selectedFile])
  
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    UploadFile(formData).then(res=>{
      setuploadedFile(res?.data?.path || null)
    }).catch(err=> console.log(err))
  }

  useEffect(() => {
   
    getCompanyLiveAnsweringSettingsByPlan(companyID,planID).then(res=>{
      console.log("RES=>>>",res)
      if(res?.data?.data){
        setSettingsData(res.data?.data)        
      }
    }).catch(err=>{
      console.log(err)
    })

  }, [])

  const handleExternalSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setLoading(true);         
          console.log(values);

          let data = {
            company_id: companyID,
            plan_id: planID,
            during_weekdays: values?.during_weekdays,
            weekdays_timing: {
              from_hour : values?.from_hour,
              to_hour : values?.to_hour
            },
            after_weekdays_timing:values?.after_weekdays_timing,
            during_weekend: values?.during_weekend,
            public_holidays: values?.public_holidays,
            private_holidays_range: {		
              month: values?.month, 
              from_date:values?.from_date, 
              to_date: values?.to_date
            },
            greeting_company_name: values?.greeting_company_name,
            greeting_message: values?.greeting_message,
            short_business_description: values?.short_business_description,
            notification_email: values?.notification_email,
            receptionist_special_instruction: values?.receptionist_special_instruction,
            voicemail_option: values?.voicemail_option,
            custom_voicemail_script: values?.custom_voicemail_script || "",
            custom_voicemail_file: uploadedFile || "",
          }


          saveCompanySettings(data).then(res=>{
            ReactTostify("success", "Settings saved successfully!");
          }).catch(err=>{
            ReactTostify("error", "Something went wrong!");
          })
          
          
        } catch (error) {
          ReactTostify("error", error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  const scrollToSection = (id) => {
    setActive(id);
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find(
          (entry) => entry.isIntersecting
        )?.target;
        if (visibleSection) {
          setActiveSection(visibleSection.id);
        }
      },
      {
        rootMargin: "-40% 0px",
        scrollMargin: "-40% 0px",
        delay: 100,
      }
    );

    const sections = document.querySelectorAll(".scroll-section");

    sections.forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, []);

  const handleSubmit = (value) => {};
  const onChangeOneTime = () => {
    setOneTimeProduct(!oneTimeProduct);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "42px",
      }}
    >
      <CommonHaeder
        firstBcItem={"Companies"}
        firstBcIcon="companies"
        secondBcItem={companyData?.data?.name}
        secondBcIcon="companies"
        thirdBcItem={"Phone Numbers"}
        thirdBcIcon="file"
        fourthBcItem={phoneNumber}
        fourthBcIcon="file"
      ></CommonHaeder>
      <MainContainer collapsed={app?.sidebarCollapsed}>
        <div style={{ marginTop: "42px" }}>
          <CompanieFlowSubHeader
            companyData={companyData?.data}
            companyDataLoading={companyDataLoading}
            refetchCompnayData={refetchCompnayData}
          ></CompanieFlowSubHeader>
        </div>
        <Banner>
          <div className="label">Live Answering</div>
          <div className="btns">
            <div className="btns">
              <div>
                <StatusLabelButton
                  Data={companyData?.data?.contact}
                ></StatusLabelButton>
              </div>
              <button className="save-btn" onClick={handleExternalSubmit}>
                <span className="btn-text">Save</span>
              </button>
            </div>
          </div>
        </Banner>

        <PlanFormsContainer>
          <PlansSidebar
            scrollToSection={scrollToSection}
            active={active}
            activeSection={activeSection}
          />
          <FixedContainer className="fixed">
            <Form
              className="main-form"
              onFinish={handleSubmit}
              form={form}
              requiredMark={false}
            >
              <ProductPlansEnableAndContactContainer>
                <ComponentsContainer>
                  <DetailForm
                    id={1}
                    settingsData={settingsData}
                    handleFileChange={handleFileChange}
                    handleAddAllLocations={handleAddAllLocations}
                    dropdownVisible={dropdownVisible}
                    setDropdownVisible={setDropdownVisible}
                  />

                  {/* <UserVisibility id={2} />

                  <DefaultEndDate
                    id={3}
                    oneTimeProduct={oneTimeProduct}
                    setOneTimeProduct={setOneTimeProduct}
                    doesNotEnd={doesNotEnd}
                    setDoesNotEnd={setDoesNotEnd}
                    canChooseEndDate={canChooseEndDate}
                    setCanChooseEndDate={setCanChooseEndDate}
                    fixedTerm={fixedTerm}
                    setFixedTerm={setFixedTerm}
                    setDate={setDate}
                  />

                  <IncludedAddOns
                    id={4}
                    selectedAddOns={selectedAddOns}
                    setSelectedAddOns={setSelectedAddOns}
                    selectedServices={selectedServices}
                  />

                  <VoiceMailSettings
                    id={5}
                    selectedAddOns={selectedAddOns}
                    setSelectedAddOns={setSelectedAddOns}
                    selectedServices={selectedServices}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    voiceMailTitle={voiceMailTitle}
                    setVoiceMailTitle={setVoiceMailTitle}
                  /> */}
                </ComponentsContainer>
              </ProductPlansEnableAndContactContainer>
            </Form>
          </FixedContainer>
        </PlanFormsContainer>
      </MainContainer>
    </div>
  );
};

export default LiveAnsweringDetails;
