import React, { useState, useEffect, useRef } from "react";
import { AiOutlineDelete } from "react-icons/ai"; // Trash icon
import { MdReportProblem } from "react-icons/md"; // Spam icon
import "./styles";
import { Container, AvatarPlaceholder } from "./styles"; // Import new styled component
import { InstanceWithAuth } from "../../../../App";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Popconfirm } from "antd";
import { PiArrowArcRightBold } from "react-icons/pi";
import { LuExternalLink } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const ColumnHeader = ({
  selectedEmailId,
  refetchEmails,
  setSelectedEmailOrChat,
  email,
  showDrawerContactDrawer,
  selectedEmails,
}) => {
  const navigate = useNavigate();

  const firstName = email?.data?.contact?.first_name || "";
  const lastName = email?.data?.contact?.last_name || "";
  const avatar = email?.data?.contact?.avatar;

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : "";
    const lastInitial = lastName ? lastName[0].toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  const TruncateText = ({ text }) => {
    const maxLength = 20;
    let truncatedText = text?.substring(0, maxLength);
    if (truncatedText?.length < text?.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };

  return (
    <Container>
      {selectedEmails?.length <= 0 && (
        <>
          {email?.data && email?.data?.is_unknown_visitor ? (
            <>
              {avatar ? (
                <img src={avatar} alt="Contact Avatar" />
              ) : (
                <AvatarPlaceholder>{getInitials("U", "K")}</AvatarPlaceholder>
              )}
              <div className="details-div">
                <span className="contact-name">Unknown Visitor</span>
                <div className="bottom-line-div">
                  <span
                    onClick={showDrawerContactDrawer}
                    style={{ cursor: "pointer" }}
                    className="company-name"
                  >
                    + Add contact
                  </span>
                  <span className="dot"></span>
                  {email?.data?.closed_at ? (
                    <>
                      <span className="from">Closed on</span>
                      <span className="from">
                        {formatDate(email?.data?.closed_at)}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="from">Opened on</span>
                      <span className="from">
                        {formatDate(email?.data?.created_at)}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {avatar ? (
                <img src={avatar} alt="Contact Avatar" />
              ) : (
                <AvatarPlaceholder>
                  {getInitials(firstName, lastName)}
                </AvatarPlaceholder>
              )}
              <div className="details-div">
                <span className="contact-name">
                  {firstName + " " + lastName}
                </span>
                <div className="bottom-line-div">
                  <span className="from">from</span>
                  <span className="company-name">
                    <TruncateText
                      text={email?.data?.company?.name}
                    ></TruncateText>
                  </span>
                  <span
                    className="link"
                    onClick={() => {
                      if (email?.data?.company?.id) {
                        navigate(`/companies/${email.data.company.id}`);
                      } else {
                        console.error("Company ID is missing");
                      }
                    }}
                  >
                    <LuExternalLink
                      size={14}
                      color="var(--Gray-100, #556373)"
                    />
                  </span>
                  <span className="dot"></span>
                  <span className="from">Closed on</span>
                  <span className="from">Sep 4, 2024</span>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ColumnHeader;
