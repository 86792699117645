import React, { useState } from 'react'
import { Container } from './styles'
import './styles'
import GetLogo from '../../getlogo/getlogo'
import TicketBoardTypeDropDown from '../ticketBoardType/TicketBoardTypeDropDown'
import BoardCustomDropDown from '../boardCustomDropDown/BoardCustomDropDown'
import useGetData from '../../../hooks/useGetData'
import LocationDateDropDwon from '../../loactionFilters/locationDateDropDwon/LocationDateDropDwon'
import { DatePicker } from 'antd'
const TicketCenterFilters = ({
  selectedPipeline,
  setSelectedPipeline,
  selectedOwner,
  setSelectedOwner,
  selectedPriority,
  setSelectedPriority,
  selectedCreatedDate,
  setSelectedCreatedDate,
  app,
  selectedOption,
  setSelectedOption,
  setEndDate,
  setStartDate,
  startDate,
  setFilterDateType,
  filterDateType,
}) => {
  const { data: allUserList, refetchData: refecthUserList } =
    useGetData('users')
  const [startDateToDisabele, setStartDateToDisabele] = useState(null)
  const handleSelect = (option) => {
    setSelectedOption(option)
  }
  const handleStartDateChange = (date, dateString) => {
    const [year, month, day] = dateString.split('-')
    const formattedDate = `${year}-${month}-${day}`
    setStartDateToDisabele(date)
    setStartDate(formattedDate)
  }
  const handleEndDateChange = (date, dateString) => {
    const [year, month, day] = dateString.split('-')
    const formattedDate = `${year}-${month}-${day}`
    setEndDate(formattedDate)
    setSelectedOption()
  }

  const disabledEndDate = (current) => {
    return (
      current &&
      startDateToDisabele &&
      current.valueOf() < startDateToDisabele.valueOf()
    )
  }
  return (
    <Container>
      <div>
        <TicketBoardTypeDropDown
          selectedPipeline={selectedPipeline}
          setSelectedPipeline={setSelectedPipeline}
          app={app}
        ></TicketBoardTypeDropDown>
      </div>

      <div className="filter">
        <BoardCustomDropDown
          selectedStatusOption={selectedOwner}
          setSelectedStatusOption={setSelectedOwner}
          dropDownTitle="Ticket Owner"
          filterData={allUserList?.data}
        ></BoardCustomDropDown>
        <BoardCustomDropDown
          selectedStatusOption={selectedCreatedDate}
          setSelectedStatusOption={setSelectedCreatedDate}
          filterData={[
            {
              name: 'is',
            },
            {
              name: 'between',
            },
            {
              name: 'after',
            },
            {
              name: 'before',
            },
          ]}
          dropDownTitle="Create Date"
        ></BoardCustomDropDown>
        {selectedCreatedDate?.name == 'between' ? (
          <DatePicker
            placeholder="Start Date"
            className="datePicker"
            onChange={handleStartDateChange}
            style={{ height: '27px' }}
          />
        ) : null}
        {selectedCreatedDate != '' && selectedCreatedDate?.name != 'all' ? (
          <DatePicker
            placeholder={selectedCreatedDate == 'between' ? 'End Date' : 'Date'}
            className="datePicker dropdown-header"
            onChange={handleEndDateChange}
            disabledDate={disabledEndDate}
            style={{ height: '27px' }}
          />
        ) : null}
        <BoardCustomDropDown
          selectedStatusOption={selectedPriority}
          setSelectedStatusOption={setSelectedPriority}
          filterData={[
            {
              name: 'Low',
            },
            {
              name: 'Medium',
            },
            {
              name: 'High',
            },
            {
              name: 'Very High',
            },
          ]}
          dropDownTitle="Priority"
        ></BoardCustomDropDown>
      </div>
    </Container>
  )
}

export default TicketCenterFilters
